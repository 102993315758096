<template>
  <div class="fangtaihead wow animate fadeInDown">
    <div class="fancontai">
      <div class="fancontainer">
        <img
          class="balcklogo"
          src="../assets/img/alltitle/balck_logo.png"
          alt=""
          @click.stop="adding('/')"
        />
        <div class="buynavs">
          <div class="buynavsleft">
            <div class="troubleting" @click.stop="adding('/')">
              {{ $t("login.home") }}
            </div>
            <div class="likechanpin troubleting">
              <!-- @mouseout="xialahidden" -->

              <el-dropdown
                :hide-timeout="100"
                :show-timeout="100"
                @visible-change="xialashow"
              >
                <span class="el-dropdown-link gibrtlike">
                  <span class="mustcheee">{{ $t("login.producet") }}</span>
                </span>
                <el-dropdown-menu
                  slot="dropdown"
                  class="outsidewidth"
                  style="position: absolute; top: 65px"
                >
                  <div class="likecuihua">
                    <div class="productbox">
                      <!-- :class="{ showscale: isFixed }" -->
                      <div class="steamovens">
                        <div class="steamovensinner">
                          <div
                            :class="
                              jiayangchange == 1 ? 'hobsrange' : 'hobsrangetwo'
                            "
                            @click="lifeandlive((jiayangchange = 1))"
                          >
                            <img
                              v-if="jiayangchange == 1"
                              src="../assets/img/alltitle/icon01.png"
                              alt=""
                            />
                            <img
                              v-else
                              src="../assets/img/alltitle/icon01red.png"
                              alt=""
                            />
                            <span>{{ $t("login.rangehoods") }}</span>
                          </div>

                          <div
                            :class="
                              jiayangchange == 2 ? 'hobsrange' : 'hobsrangetwo'
                            "
                            @click="lifeandlive((jiayangchange = 2))"
                          >
                            <img
                              v-if="jiayangchange == 2"
                              src="../assets/img/alltitle/icon02.png"
                              alt=""
                            />
                            <img
                              v-else
                              src="../assets/img/alltitle/icon02red.png"
                              alt=""
                            />
                            <span>{{ $t("login.hobs") }}</span>
                          </div>
                          <div
                            :class="
                              jiayangchange == 3 ? 'hobsrange' : 'hobsrangetwo'
                            "
                            @click="lifeandlive((jiayangchange = 3))"
                          >
                            <img
                              v-if="jiayangchange == 3"
                              src="../assets/img/alltitle/icon03.png"
                              alt=""
                            />
                            <img
                              v-else
                              src="../assets/img/alltitle/icon03red.png"
                              alt=""
                            />
                            <span>{{ $t("login.lectricovens") }}</span>
                          </div>
                          <div
                            :class="
                              jiayangchange == 4 ? 'hobsrange' : 'hobsrangetwo'
                            "
                            @click="lifeandlive((jiayangchange = 4))"
                          >
                            <img
                              v-if="jiayangchange == 4"
                              src="../assets/img/alltitle/icon04.png"
                              alt=""
                            />
                            <img
                              v-else
                              src="../assets/img/alltitle/icon04red.png"
                              alt=""
                            />
                            <span>{{ $t("login.steamovens") }}</span>
                          </div>
                          <div
                            :class="
                              jiayangchange == 5 ? 'hobsrange' : 'hobsrangetwo'
                            "
                            @click="lifeandlive((jiayangchange = 5))"
                          >
                            <img
                              v-if="jiayangchange == 5"
                              src="../assets/img/alltitle/icon05.png"
                              alt=""
                            />
                            <img
                              v-else
                              src="../assets/img/alltitle/icon05red.png"
                              alt=""
                            />
                            <span>{{ $t("login.micovens") }}</span>
                          </div>
                          <div
                            :class="
                              jiayangchange == 6 ? 'hobsrange' : 'hobsrangetwo'
                            "
                            @click="lifeandlive((jiayangchange = 6))"
                          >
                            <img
                              v-if="jiayangchange == 6"
                              src="../assets/img/alltitle/icon06.png"
                              alt=""
                            />
                            <img
                              v-else
                              src="../assets/img/alltitle/icon06red.png"
                              alt=""
                            />
                            <span>{{ $t("login.dishwasher") }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="enjoycheck">
                        <div class="enjoycheckinner">
                          <div class="enjoytop">
                            <div
                              v-for="(val, index) in recompro"
                              class="rubbishs"
                              :class="flflag == index ? 'wifeandhusband' : ''"
                              :key="val.id"
                              @click="colorchangered(index, val.id)"
                            >
                              {{ val.name }}
                            </div>
                          </div>
                          <div class="enjoybottom">
                            <div
                              class="familys"
                              v-for="item in optionstwo"
                              :key="item.id"
                            >
                              <img
                                :src="item.picture"
                                alt=""
                                @click="
                                  todetail(
                                    item.code,
                                    item.is_outside,
                                    item.outside_url,
                                    item
                                  )
                                "
                              />

                              <span>{{ item.code }}</span>
                              <div class="fanifamitex">
                                {{ item.tags }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="allsonmproduct"
                          @click.stop="addingallpro('productlist')"
                        >
                          {{ $t("login.allproduct") }}
                        </div>
                      </div>
                    </div>
                  </div>
                </el-dropdown-menu>
              </el-dropdown>
            </div>

            <div class="likechanpin troubleting">
              <el-dropdown
                :hide-timeout="100"
                :show-timeout="100"
                @visible-change="xialashow"
              >
                <span class="el-dropdown-link gibrtlike">
                  <span class="mustcheee">{{ $t("login.serives") }}</span>
                </span>
                <el-dropdown-menu
                  slot="dropdown"
                  class="outsidewidth"
                  style="position: absolute; top: 65px"
                  :hide-on-click="true"
                >
                  <div class="likecuihua myclasses">
                    <div class="productbox">
                      <div class="steamovens face-sea">
                        <div class="steam2ovensinner otherstear">
                          <div class="hobsrangetwo" @click="tourl('words')">
                            <span>{{ $t("login.faq") }}</span>
                          </div>
                          <div
                            class="hobsrangetwo"
                            @click="toarht('otherwebsite', 'customer')"
                          >
                            <span>{{ $t("login.mabuak") }}</span>
                          </div>
                          <div class="hobsrangetwo" @click="tourl('machine')">
                            <span>{{ $t("login.manualdownload") }}</span>
                          </div>
                          <div
                            class="hobsrangetwo"
                            @click="tourl('exhibition')"
                          >
                            <span>{{ $t("login.exhibitionhallquery") }}</span>
                          </div>
                          <div class="hobsrangetwo" @click="TOAHH1('joinin')">
                            <span>{{ $t("login.complaintjoin") }}</span>
                          </div>
                          <!-- <div
                            class="
                              hobsrangetwo
                              wow
                              animate
                              fadeInUp
                              delay-600ms
                            "
                            @click="tourl('feedback')"
                          >
                            <span>{{ $t("login.complaintjoin1") }}</span>
                          </div> -->
                          <!-- <div
                            class="
                              hobsrangetwo
                              wow
                              animate
                              fadeInUp
                              delay-700ms
                            "
                            @click="tourl('cooperationall')"
                          >
                            <span>{{ $t("login.engineeing") }}</span>
                          </div> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </el-dropdown-menu>
              </el-dropdown>
            </div>

            <!-- <div
              class="troubleting"
              @click.stop="addingnew('alltitleandall', jiayangchange)"
            >
              {{ $t("login.news") }}
            </div> -->
            <div class="likechanpin troubleting">
              <el-dropdown
                :hide-timeout="100"
                :show-timeout="100"
                @visible-change="xialashow"
              >
                <span class="el-dropdown-link gibrtlike">
                  <span class="mustcheee" @click="tokfa">{{
                    $t("login.about")
                  }}</span>
                </span>
                <!-- <el-dropdown-menu
                  slot="dropdown"
                  class="outsidewidth"
                  style="position: absolute; top: 65px"
                >
                  <div class="likecuihua myclasses">
                    <div class="productbox">
                      <div class="steamovens face-sea">
                        <div class="steam2ovensinner anthoowehing">
                          <div
                            class="
                              hobsrangetwo
                              wow
                              animate
                              fadeInUp
                              delay-300ms
                            "
                            @click="tourl('informationcopy')"
                          >
                            <span>{{ $t("login.happykitchen") }}</span>
                          </div>

                          <div
                            class="
                              hobsrangetwo
                              wow
                              animate
                              fadeInUp
                              delay-200ms
                            "
                            @click="tokfa('about')"
                          >
                            <span>{{ $t("login.aboutfotial") }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </el-dropdown-menu> -->
              </el-dropdown>
            </div>

            <!-- <div class="troubleting">{{ $t("login.follow") }}</div> -->
          </div>
          <div class="buynavsright">
            <div class="shoppcaring" @click="toshoppings">
              <img src="../assets/img/alltitle/shoppcar.png" alt="" />
              <span>{{ $t("login.producets") }}</span>
              <div :class="frlag ? 'abuour' : 'watsfiling'">
                <div class="shonw1" @click="todoor">
                  {{ $t("login.Stobere") }}
                </div>
                <div class="shonw2" @click="toondoor">
                  {{ $t("login.Onlbeine") }}
                </div>
              </div>
            </div>
            <div class="languagechange">
              <div class="inputleft" @click.stop="toggclass">
                <el-select
                  v-model="languane"
                  placeholder=""
                  :popper-append-to-body="false"
                  disabled
                  @change="newvalue"
                  ref="fuzzySearch"
                >
                  <el-option
                    v-for="item in shoseone"
                    :key="item.value"
                    :label="item.label"
                    :value="item.name"
                  >
                  </el-option>
                </el-select>
                <!-- Global -->
                <!-- <img
                  class="bomright"
                  src="../assets/img/alltitle/whitesmile.png"
                  alt=""
                /> -->
              </div>
              <!-- <transition
                enter-active-class="kerwin-enter-active"
                leave-active-class="kerwin-leave-active"
              >
                <div v-if="changeclass">
                  <div :class="!changeclass ? 'languagees' : 'languageestwo'">
                    <div>Global</div>
                    <div>China</div>
                    <div>United States</div>
                    <div>Canada</div>
                    <div>Indonesia</div>
                    <div>Malaysia</div>
                    <div>Singapore</div>
                    <div>Australia</div>
                    <div>New Zealand</div>
                    <div>Thailand</div>
                    <div>Philippines</div>
                  </div>
                </div>
                
              </transition> -->

              <div class="inputright">
                <el-select
                  v-model="fanti"
                  placeholder=""
                  :popper-append-to-body="false"
                  @change="newlanguage"
                  ref="fuzzySearch2"
                >
                  <el-option
                    v-for="item in shosetwo"
                    :key="item.value"
                    :label="item.label"
                    :value="item.name"
                  >
                  </el-option>
                </el-select>
                <img
                  class="swithchrow"
                  src="../assets/img/alltitle/whitesmile.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { WOW } from "wowjs";
import {
  apiDeviceDetail,
  navProductList,
  getseries,
  getseriesgoods,
} from "../request/api";
import { eventbut } from "../utils/bus";
export default {
  data() {
    return {
      frlag: true,
      optionstwo: [],
      flflag: 0,
      arg: 1,
      languane: "",
      fanti: "",
      jiayangchange: 1,
      changeclass: false,
      isFixed: false,
      ddd: {},
      agains: false,
      shoseone: [],
      shosetwo: [],
      tagname: "",
      langnametag: "",
      recompro: [],
      recoidmpro: "",
    };
  },
  watch: {
    jiayangchange: function (newV, oldV) {
      console.log(newV, oldV);
      sessionStorage.setItem("newval", newV);
    },
  },
  created() {
    if (typeof window !== "undefined") {
      document.addEventListener("click", (e) => {
        if (e.target.className !== "inputleft") {
          // this.changeclass = false;
          this.$refs.fuzzySearch.blur();
        }
      });
    }

    if (typeof window !== "undefined") {
      document.addEventListener("click", (e) => {
        if (e.target.className !== "inputright") {
          // this.changeclass = false;
          this.$refs.fuzzySearch2.blur();
        }
      });
    }
    if (sessionStorage.getItem("newlangusge") == "cn") {
      this.$i18n.locale = "zh";
    } else if (sessionStorage.getItem("newlangusge") == "en") {
      this.$i18n.locale = "en";
    } else if (sessionStorage.getItem("newlangusge") == "tw") {
      this.$i18n.locale = "simples";
    }
    console.log(sessionStorage.getItem("newlangusge"), 90909090);
    if (sessionStorage.getItem("newlangusge") == null) {
      var url = window.location.href; //获取url地址
      var str = url.substr(1);
      var strs = str.split("&");
      strs[0].split("=")[1];
      var urlParmStr = strs[0].split("=")[1];
      urlParmStr = urlParmStr.slice(0, 2);
      console.log(urlParmStr, 8887);
      if (urlParmStr == "cn") {
        this.$i18n.locale = "zh";
        this.fanti = "简";
        urlParmStr = "cn";
      } else if (urlParmStr == "tw") {
        this.$i18n.locale = "simples";
        this.fanti = "繁";
        urlParmStr = "tw";
      } else if (urlParmStr == "en") {
        this.$i18n.locale = "en";
        this.fanti = "EN";
        urlParmStr = "en";
      } else {
        this.$i18n.locale = "en";
        this.fanti = "EN";
        urlParmStr = "en";
      }
      console.log(urlParmStr, 4637382);
      console.log(this.fanti, 938373737);
      this.getothers(urlParmStr);
      console.log(2222222);
      this.tagname = "au";
      this.gettoppros(urlParmStr);
      this.outgetseriesgoods("au", urlParmStr, 1, 11);
      // this.langnametag = "en";
      this.shosetwo.forEach((val) => {
        console.log(val, 2345678);
        if (urlParmStr == val.name) {
          this.langnametag = val.tag;
        }
      }); // this.langnametag = this.$i18n.locale;
      console.log(urlParmStr, 876543);
      this.langnametag = urlParmStr;
      console.log(this.langnametag, 18282);

      sessionStorage.setItem("newlangusge", urlParmStr);

      this.$bus.$emit("add", "au", this.langnametag, this.jiayangchange);
      this.getlang(this.langnametag); // location.reload();
    } else {
      this.getothers(sessionStorage.getItem("newlangusge"));
      this.tagname = "au";
      this.gettoppros(sessionStorage.getItem("newlangusge"));
      this.outgetseriesgoods(
        "au",
        sessionStorage.getItem("newlangusge"),
        1,
        11
      );
    }
  },

  methods: {
    toshoppings() {
      console.log(1111111111);
      console.log(this.frlag);

      this.frlag = !this.frlag;
      console.log(this.frlag);
    },
    TOAHH1() {
      var url = "https://www.efotile.com.au/Service";
      window.open(url, "_blank");
    },
    // closeOptions() {
    //   this.$refs.fuzzySearch.blur(); //通过ref直接绑定对应的selection调用blur方法即可隐藏下拉框
    // },
    // closeOptions2() {
    //   console.log(1111111111111);
    //   this.$refs.fuzzySearch2.blur(); //通过ref直接绑定对应的selection调用blur方法即可隐藏下拉框
    // },
    toarht(url, a) {
      this.$router.push({
        path: url,
        query: { type: 3, name: a, cate: 0 },
      });
      location.reload();
    },
    todoor() {
      this.$router.push("/exhibition");
    },
    toondoor() {
      var url = "https://efotile.com.au/";
      window.open(url, "_blank");
    },

    tourl(a) {
      console.log(a);
      this.$router.push({ path: a });
      location.reload();
    },
    tokfa(a) {
      console.log(a);
      this.$router.push({ path: "/about" });
      // setTimeout(() => {
      //   location.reload();
      // },10);
    },
    todetail(a, b, c, d) {
      console.log(d, 1111);
      if (b == 1) {
        this.$router.push({
          path: "/otherwebsite",
          query: { id: c, type: 2, name: a, cate: 0 },
        });
      } else if (b == 0) {
        this.$router.push({ path: "/productdetail", query: { id: a } });
      }

      this.$bus.$emit("changedetail", a);
      location.reload();
    },
    //2024,4,20甲方要求关闭其他国家的跳转
    newvalue(e) {
      // console.log(e, 111);
      // this.shoseone.forEach((el) => {
      //   if (e == el.name) {
      //     this.tagname = el.tag;
      //     // this.shosetwo = el.lang;
      //     var url = el.outer_url;
      //     window.open(url, "_blank");
      //   }
      // });
      // this.tagname = "au";
      // this.languane = this.shoseone[7].name;
      // this.gettwo();
    },
    //切换语言----------------
    newlanguage(e) {
      console.log(e);

      if (e === "简") {
        this.$i18n.locale = "zh";
      } else if (e === "EN") {
        this.$i18n.locale = "en";
      } else if (e === "韩") {
        this.$i18n.locale = "other";
      } else if (e === "ID") {
        this.$i18n.locale = "Indonesia";
      } else if (e === "繁") {
        this.$i18n.locale = "simples";
      }

      this.fanti = e;
      // console.log(this.fanti);
      this.shosetwo.forEach((val) => {
        console.log(val, 33333333333);
        if (e == val.name) {
          this.langnametag = val.tag;
        }
      });
      console.log(this.langnametag);

      sessionStorage.setItem("newlangusge", this.langnametag);

      this.$bus.$emit("add", "au", this.langnametag, this.jiayangchange);
      this.getlang(this.langnametag);
      // location.reload();
    },
    colorchangered(e, id) {
      this.flflag = e;
      this.outgetseriesgoods(this.tagname, this.langnametag, 1, id);
    },
    gettwo() {
      console.log(this.langnametag);
      getseries({
        lang: this.langnametag,
        country: this.tagname,
        cate: this.jiayangchange,
      }).then((res) => {
        console.log(res);
        this.recompro = res.data;
        // this.recoidmpro = this.recompro[0].id;
        if (this.recompro.length != 0) {
          this.outgetseriesgoods(
            this.tagname,
            this.langnametag,
            1,
            this.recompro[0].id
          );
        } else {
          // this.optionstwo = [];
          this.outgetseriesgoods(this.tagname, this.langnametag, 1, 0);
        }
      });
    },
    gettoppros(e) {
      getseries({
        lang: e,
        country: "au",
        cate: 1,
      }).then((res) => {
        console.log(res);
        this.recompro = res.data;
        console.log(this.recompro[0].id);
        this.recoidmpro = 11;
      });
    },
    outgetseriesgoods(a, b, c, d) {
      getseriesgoods({
        cate: this.jiayangchange,
        lang: b,
        country: a,
        type: d,
        id: 0,
      }).then((res) => {
        console.log(res);
        this.optionstwo = res.data;
      });
    },

    getlang(e) {
      apiDeviceDetail({
        lang: e,
      }).then((res) => {
        // console.log(res);
        this.shoseone = res.data;
        // console.log(this.shoseone);
        console.log(this.tagname);

        this.shoseone.forEach((el) => {
          if (this.tagname == el.tag) {
            this.languane = el.name;
          }
        });
      });
    },
    getothers(e) {
      apiDeviceDetail({
        lang: e,
      }).then((res) => {
        console.log(res);
        this.shoseone = res.data;
        this.languane = this.shoseone[7].name;

        this.shosetwo = this.shoseone[7].lang;
        console.log(this.shosetwo, 282828282);
        if (this.shosetwo.length != 0) {
          if (sessionStorage.getItem("newlangusge") == null) {
            this.fanti = this.shosetwo[2].name;
          } else {
            this.shosetwo.forEach((el) => {
              if (el.tag == sessionStorage.getItem("newlangusge")) {
                this.fanti = el.name;
              }
            });
          }
          this.langnametag = this.shosetwo[2].tag;
          console.log(this.langnametag, 999999999999999);
        } else {
          this.fanti = "";
        }
      });
    },
    xialashow(e) {
      console.log(e);
      if (e == true) {
      } else {
        this.jiayangchange = 1;
        this.outgetseriesgoods("au", "en", 1, 11);
        this.gettwo();
        // document.getElementById("test1").setAttribute("class", "fajjjjj");
      }
    },

    xialahidden() {
      console.log(222);
      this.isFixed = false;
    },
    adding(url) {
      this.$router.push(url);
    },
    addingallpro(url) {
      // console.log(this.tagname);
      // console.log(this.langnametag);
      this.$bus.$emit(
        "add",
        this.tagname,
        this.langnametag,
        this.jiayangchange
      );
      this.$router.push({
        path: url,
        query: { id: this.jiayangchange },
      });
      location.reload();
    },
    addingnew(url, b) {
      this.$bus.$emit(
        "add",
        this.tagname,
        this.langnametag,
        this.jiayangchange
      );

      this.$router.push({
        path: url,
        query: { id: b },
      });
    },
    lifeandlive(val) {
      // console.log(val);

      this.jiayangchange = val;
      // console.log(this.jiayangchange);
      this.gettwo();
      this.flflag = 0;

      this.$forceUpdate();
    },
    toggclass() {
      this.changeclass = !this.changeclass;
    },
  },
  mounted() {
    // if (document.documentElement.scrollTop > 0) {
    //   document.documentElement.scrollTop = 0;
    // }
  },
};
</script>
<style scoped lang='less'>
/* 进场动画 */
.kerwin-enter-active {
  animation: aaa 2s;
  transition: all 2s ease;
}
/* 出场动画 */
.kerwin-leave-active {
  animation: aaa 2s reverse;
  transition: all 2s ease;
}
/* 定义动画的名字和开始到结束的样式 */
@keyframes aaa {
  0% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(-20px);
  }
}

.fangtaihead {
  border-bottom: 1px solid #f2f2f2;
  height: 70px;
  z-index: 3000;
  /deep/ .el-input__suffix {
    right: 12px;
    top: -3px;
  }
  /deep/ .el-input__icon {
    line-height: 3px;
  }
  /deep/ .el-select .el-input .el-select__caret {
    font-size: 3px;
    opacity: 0.75;
  }
  /deep/ .el-input__inner {
    color: #fff;
  }
  /deep/ .el-input__inner::placeholder {
    color: #fff;
    font-weight: 400;
  }
  .fangtaihead {
    font-wight: 200;
    border-bottom: 1px solid #f2f2f2;
    z-index: 3000;
  }
  .make_transist {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .hidescale {
    -webkit-transform: scale(0.9);
    -moz-transform: scale(0.9);
    -o-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
    -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    filter: alpha(opacity=0);
    opacity: 0;
  }

  .showscale {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    opacity: 1;
    -webkit-transition-delay: 0.3s;
    -moz-transition-delay: 0.3s;
    -o-transition-delay: 0.3s;
    -ms-transition-delay: 0.3s;
    transition-delay: 0.3s;
  }

  .qie_1 {
    position: fixed;
    left: 0;
    top: 70px;
    width: 100%;
  }
  display: flex;
  .fancontai {
    width: 100%;
    height: 70px;

    background-color: none;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 3000;
    background-color: white;
    width: 100vw;
    .fancontainer {
      height: 70px;
      margin: 0px 67px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      z-index: 3000;
      .balcklogo {
        width: 83px;
        height: 19px;
        cursor: pointer;
        z-index: 999;
      }
      .buynavs {
        display: flex;
        // align-items: center;
        justify-content: center;
        .buynavsleft {
          display: flex;
          align-items: center;
          justify-content: center;
          // text-align: center;
          // margin: 0 auto;
          .troubleting {
            // line-height: 70px;
            position: relative;
            height: 70px;
            display: flex;
            align-items: center;
            margin-right: 53px;
            font-size: 14px;
            /* font-family: Arial; */
            font-weight: 400;
            color: #000000;
            cursor: pointer;
          }
          .mustcheee {
            font-size: 14px;
            /* font-family: Arial; */
            font-weight: 400;
            color: #000000;
            cursor: pointer;
            padding-bottom: 33px;
          }
          .troubleting:hover {
            color: #9e2b29;
            /* cursor: pointer; */
          }
          .troubleting:hover::before {
            left: 0;
            right: 0;
          }
          .troubleting:before {
            content: "";
            position: absolute;
            z-index: -1;
            left: 50%;
            right: 50%;
            bottom: 0px;
            background: #9e1d22;
            height: 2px;
            -webkit-transition-property: left, right;
            transition-property: left, right;
            -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
            -webkit-transition-timing-function: ease-out;
            transition-timing-function: ease-out;
          }
          .likechanpin:hover {
            transition: all 2s linear 1s;
          }
          // .likechanpin:hover .productbox {
          //   // display: block;
          //   // transition: all 1s linear 1s;
          //   // cursor: default;
          // }
        }

        .buynavsright {
          display: flex;
          height: 80px;
          align-items: center;
          .shoppcaring {
            cursor: pointer;
            // width: 89px;
            height: 30px;
            background: #9e2b29;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50px;
            padding: 0px 10px 0px 10px;
            img {
              width: 14px;
              /* height: 14px; */
            }
            span {
              margin-left: 5px;
              font-size: 14px;
              /* font-family: Arial; */
              font-weight: 400;
              color: #ffffff;
            }
          }
          .shoppcaring:hover {
            background: #000000;
          }
          .languagechange {
            display: flex;
            margin-left: 27px;
            cursor: pointer;
            /deep/.el-select-dropdown__item.selected {
              color: #9e2b29;
            }
          }
          .inputleft {
            position: relative;
            cursor: pointer;
            border-radius: 0 0 10px 10px;
            box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.19);

            /deep/.el-input__inner {
              background-color: #000000;
              border-radius: 50px 0px 0px 50px;
              width: 90px;
              height: 30px;
              border: none;
              text-align: center;
              font-size: 14px;
              // margin-right: 10px;
              padding-right: 0px;
            }

            background-color: #000000;
            border-radius: 50px 0px 0px 50px;
            // width: 100px;
            // height: 30px;
            // padding-left: 10px;
            padding-right: 10px;
            border: none;
            text-align: center;
            font-size: 14px;
            /* font-weight: bold; */
            /* font-family: Arial; */
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              // margin-left: 10px;
              opacity: 0.75;
            }
            /deep/ .el-input__icon {
              height: 121%;
              font-weight: bold;
              display: none;
            }
            // .bomright {
            //   margin-left: -20px;
            // }
          }
          .languagees {
            display: none;
            position: absolute;
            top: 50px;
            width: 80px;

            z-index: 9999;
            // margin-left: -20px;
            // text-align: center;
            // left: 0px;
            background-color: white;
            padding: 20px;
            padding-bottom: 10px;
            div {
              margin-bottom: 10px;
              // background-color: white;
            }
          }

          .languageestwo {
            overflow-y: scroll;
            width: 90px;
            height: 200px;
            display: block;
            position: absolute;
            top: 50px;
            z-index: 9999;
            margin-left: -100px;
            // text-align: center;
            // left: 0px;
            background-color: white;
            padding: 20px;
            padding-bottom: 10px;
            border-radius: 0 0 10px 10px;
            div {
              margin-bottom: 10px;
              font-size: 13px;
              padding: 3px 0;
              color: #333;
              cursor: pointer;
              text-align: center;
              border-radius: 0 0 10px 10px;
              // background-color: white;
            }
            div:hover {
              color: #9d2736;
            }
          }
          .inputright {
            margin-left: -4px;
            /deep/.el-input__inner {
              background-color: #3c3c3c;
              border-radius: 0px 50px 50px 0px;
              width: 78px;
              height: 30px;
              border: none;
              text-align: center;
              font-size: 14px;
              /* font-weight: bold; */
              /* font-family: Arial; */
            }
            /deep/ .el-input__icon {
              height: 121%;
              font-weight: bold;
              display: none;
            }
            .swithchrow {
              margin-left: -20px;
              margin-top: 15px;
              z-index: 99999;
              position: absolute;
              opacity: 0.5;
            }
            /deep/.el-select-dropdown__item.selected {
              color: #9e2b29;
            }
          }
        }
      }
    }
  }
}
.outsidewidth {
  display: flex;
  width: 100%;
  // overflow: hidden;
  /deep/ .el-dropdown-menu {
    position: absolute;
    top: 65px;
  }
}
.cuihua {
  width: 100px;
  height: 200px;
  background-color: red;
}
.yell {
  width: 100px;
  height: 200px;
  background-color: yellow;
}
.likecuihua {
  // display: flex;
  overflow: hidden;
  .productbox {
    // display: none;
    position: fixed;
    // height: 572px !important;
    z-index: 1;
    // top: 10px;
    // left: 0px;
    transition: all 2s linear 1s;
    width: 100%;
    background-color: white;
    // height: 572px;
    // display: flex;
    margin-left: -5px;
    .steamovens {
      display: flex;
      height: 100px;
      width: 100%;
      background-color: #f8f8f8;
      // background-color: white;
      // box-shadow: 1px 1px 1px 1px #f8f8f8;
      .steamovensinner {
        width: 1200px;
        display: flex;
        align-items: center;
        margin: 0 auto;
        justify-content: space-around;
        .hobsrange {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          img {
            width: 45px;
            /* height: 56px; */
          }
          span {
            margin-top: -5px;
            font-size: 12px;
            /* font-family: Arial; */
            font-weight: 400;
            color: #000000;
            line-height: 30px;
          }
        }
        .hobsrangetwo {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          img {
            width: 45px;
            /* height: 56px; */
          }
          span {
            margin-top: -5px;
            font-size: 12px;
            /* font-family: Arial; */
            font-weight: 400;
            color: #898989;
            line-height: 30px;
          }
        }
      }
      .steam2ovensinner {
        // width: 1200px;
        display: flex;
        align-items: center;
        position: relative;
        // margin: 0 auto;
        justify-content: space-around;
        .hobsrange {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          img {
            width: 45px;
            /* height: 56px; */
          }
          span {
            margin-top: -5px;
            font-size: 12px;
            /* font-family: Arial; */
            font-weight: 400;
            color: #000000;
            line-height: 30px;
          }
        }
        .hobsrangetwo {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          img {
            width: 45px;
            /* height: 56px; */
          }
          span {
            margin-top: -5px;
            font-size: 12px;
            /* font-family: Arial; */
            font-weight: 400;
            color: #898989;
            line-height: 30px;
          }
        }
      }
      .otherstear {
        width: 800px;
        position: absolute;
        right: 10%;
        top: 40%;
      }
      .anthoowehing {
        width: 300px;
        position: absolute;
        right: 20%;
        top: 40%;
      }
    }
    .face-sea {
      height: 70px;
    }
    .enjoycheck {
      // height: 350px;
      padding-bottom: 50px;
      background-color: white;
      box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.02);
      .enjoycheckinner {
        width: 1200px;
        display: flex;
        margin: 0 auto;
        flex-direction: column;
        justify-content: space-around;
        .rubbishs {
          font-size: 14px;
          margin-top: 40px;
          cursor: pointer;
        }
        .enjoytop {
          display: flex;
          justify-content: space-evenly;
          .wifeandhusband {
            text-align: center;
            color: #9e2b29;
            font-size: 14px;
          }
        }
        .enjoybottom {
          display: flex;
          margin: 0 auto;
          flex-wrap: wrap;
          .familys {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-top: 40px;
            cursor: pointer;
            img {
              width: 120px;
              height: 120px;
            }
            span {
              font-size: 14px;
              /* font-family: Arial; */
              font-weight: bold;
              color: #000000;
              line-height: 19px;
            }
            .fanifamitex {
              width: 144px;
              margin-top: 10px;
              font-size: 12px;
              /* font-family: Arial; */
              font-weight: 400;
              color: #7f7f7f;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
              // display: flex;
              // align-items: center;
              // justify-content: center;
              text-align: center;
            }
          }
        }
      }
    }
    .allsonmproduct {
      cursor: pointer;
      width: 130px;
      height: 42px;
      background-color: #000000;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 30px;
      font-size: 12px;
      /* font-family: Arial; */
      /* font-weight: bold; */
      color: #ffffff;
      /* opacity: 0.75; */
      margin: 0 auto;
      margin-top: 45px;
    }
    .allsonmproduct:hover {
      background-color: #992025;
    }
  }
}
// .gibrtlike {
//   padding-bottom: 100px;
// }
.myclasses {
  .productbox {
    height: 70px !important;
    margin-left: -5px;
  }
}
// /deep/.el-dropdown-menu {
//   z-index: 0 !important;
// }
.watsfiling {
  display: flex;
  flex-direction: column;
  // opacity: 0;
  // display: none;
  position: absolute;
  top: 50px;
  // left: 50%;
  background-color: white;
  width: 90px;
  text-align: center;
  border-radius: 12px;
  padding-bottom: 20px;
  .shonw1 {
    color: black;
    font-size: 17px;
    margin-top: 20px;
  }
  .shonw2 {
    color: black;
    font-size: 17px;
    margin-top: 20px;
  }
}
.abuour {
  display: flex;
  flex-direction: column;
  // opacity: 0;
  display: none;
  position: absolute;
  top: 40px;
  // left: 50%;
  background-color: white;
  width: 100px;
  text-align: center;
  border-radius: 12px;
  padding-bottom: 30px;
  .shonw1 {
    color: black;
    font-size: 17px;
    margin-top: 20px;
  }
  .shonw2 {
    color: black;
    font-size: 17px;
    margin-top: 20px;
  }
}
</style>