<template>
  <div>
    <div @click="tonn">ayemian</div>
  </div>
</template>
<script>
export default {
  methods: {
    tonn() {
      this.$router.push({
        path: "./bbb",
        query: { l: "cnjjj", temp: "1715866058868" },
      });
    },
  },
};
</script>