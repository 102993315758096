<template>
  <div class="pearhome">
    <div class="faqas">
      <div class="faqatop wow animate fadeInUp">
        <div class="faqselfash">{{ $t("login.manualdownload") }}</div>
        <div class="appliance">
          <div class="television" @click="imgchange((titlechanges = 1))">
            <img
              v-if="titlechanges == 1"
              src="../assets/img/alltitle/icon01.png"
              alt=""
            />
            <img v-else src="../assets/img/alltitle/icon01red.png" alt="" />

            <div :class="titlechanges == 1 ? 'televtext' : 'televtexttwo'">
              {{ $t("login.rangehoods") }}
            </div>
            <div class="handdown" @click="todeown(1)">
              <span>{{ $t("login.bighand") }}</span>
            </div>
          </div>
          <div class="television" @click="imgchange((titlechanges = 2))">
            <img
              v-if="titlechanges == 2"
              src="../assets/img/alltitle/icon02.png"
              alt=""
            />
            <img v-else src="../assets/img/alltitle/icon02red.png" alt="" />

            <div :class="titlechanges == 2 ? 'televtext' : 'televtexttwo'">
              {{ $t("login.hobs") }}
            </div>
            <div class="handdown" @click="todeown(2)">
              <span>{{ $t("login.bighand") }}</span>
            </div>
          </div>
          <div class="television" @click="imgchange((titlechanges = 3))">
            <img
              v-if="titlechanges == 3"
              src="../assets/img/alltitle/icon03.png"
              alt=""
            />
            <img v-else src="../assets/img/alltitle/icon03red.png" alt="" />
            <div :class="titlechanges == 3 ? 'televtext' : 'televtexttwo'">
              {{ $t("login.lectricovens") }}
            </div>
            <div class="handdown" @click="todeown(3)">
              <span>{{ $t("login.bighand") }}</span>
            </div>
          </div>
          <div class="television" @click="imgchange((titlechanges = 4))">
            <img
              v-if="titlechanges == 4"
              src="../assets/img/alltitle/icon04.png"
              alt=""
            />
            <img v-else src="../assets/img/alltitle/icon04red.png" alt="" />
            <div :class="titlechanges == 4 ? 'televtext' : 'televtexttwo'">
              {{ $t("login.steamovens") }}
            </div>
            <div class="handdown" @click="todeown(4)">
              <span>{{ $t("login.bighand") }}</span>
            </div>
          </div>
          <div class="television" @click="imgchange((titlechanges = 5))">
            <img
              v-if="titlechanges == 5"
              src="../assets/img/alltitle/icon05.png"
              alt=""
            />
            <img v-else src="../assets/img/alltitle/icon05red.png" alt="" />
            <div :class="titlechanges == 5 ? 'televtext' : 'televtexttwo'">
              {{ $t("login.micovens") }}
            </div>
            <div class="handdown" @click="todeown(5)">
              <span>{{ $t("login.bighand") }}</span>
            </div>
          </div>
          <div class="television" @click="imgchange((titlechanges = 6))">
            <img
              v-if="titlechanges == 6"
              src="../assets/img/alltitle/icon06.png"
              alt=""
            />
            <img v-else src="../assets/img/alltitle/icon06red.png" alt="" />
            <div :class="titlechanges == 6 ? 'televtext' : 'televtexttwo'">
              {{ $t("login.dishwasher") }}
            </div>
            <div class="handdown" @click="todeown(6)">
              <span>{{ $t("login.bighand") }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="cneterfotile">
      <div class="fotileinner">
        <div
          class="jqgqqzero wow animate fadeInUp delay-300ms"
          v-for="item in downloadting"
          :key="item.id"
        >
          <div class="jqgqqinner">
            <div class="fitietop">{{ item.name }}</div>
            <div class="fitiebottome">
              <div
                class="fitiefirstchild"
                v-for="ele in item.data"
                :key="ele.id"
              >
                <img :src="ele.thumb" alt="" />
                <!-- <img
                  src="https://fotileglobaloss.oss-accelerate.aliyuncs.com/uploads/20191125/64adf4070b72578c6aeaf3fc003288fb.png"
                  alt=""
                /> -->
                <span>{{ ele.code }}</span>
                <div class="serieses">
                  <span class="healthfather"
                    ><a
                      :href="ele.leaflet"
                      style="text-decoration: none"
                      class="helsth"
                      >{{ $t("login.downloadli") }}</a
                    ></span
                  >

                  <!-- <img src="../assets/img/alltitle/atuu.png" alt=""> -->
                </div>
              </div>
              <div v-if="item.data.length == 0" class="nodatfather">
                <img
                  class="nodataimg"
                  src="../assets/img/alltitle/nodata.png"
                  alt=""
                />
              </div>
              <div class="partyfirstchild"></div>
              <div class="partyfirstchild"></div>
              <div class="partyfirstchild"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { WOW } from "wowjs";
import { getdownting, gethomepro } from "../request/api";

export default {
  data() {
    return {
      titlechanges: 1,
      messagenew: "",
      newb: "",
      downloadting: [],
      togetserimg: [],
      filepdf: "",
    };
  },
  created() {
    if (sessionStorage.getItem("newlangusge") == null) {
      this.outpadate("au", "en", this.titlechanges);
      this.getSeriesList("au", "en");
    } else {
      this.outpadate(
        "au",
        sessionStorage.getItem("newlangusge"),
        this.titlechanges
      );
      this.getSeriesList("au", sessionStorage.getItem("newlangusge"));
    }
    this.$route.meta.title = this.$t("login.querythere6");
    this.$router.replace({
      query: {
        temp: Date.now(), //必有
      },
    });
    this.$bus.$on("add", (message, b, d) => {
      console.log(message);
      console.log(b);
      this.messagenew = message;
      this.newb = b;
      this.outpadate(message, b, this.titlechanges);
      this.getSeriesList(message, b);
      this.$route.meta.title = this.$t("login.querythere6");
      this.$router.replace({
        query: {
          temp: Date.now(), //必有
        },
      });
    });
  },
  mounted() {
    if (document.documentElement.scrollTop > 0) {
      document.documentElement.scrollTop = 0;
    }
    // document.documentElement.scrollTop = 0;
    // window.addEventListener("scroll", '0');
    this.$nextTick(() => {
      // 在dom渲染完后,再执行动画
      const wow = new WOW({
        offset: 0,
        // mobile: true,
        live: true,
      });
      wow.init();
    });
  },
  methods: {
    getSeriesList(a, b) {
      gethomepro({
        type: "1",
        lang: b,
        country: a,
      }).then((res) => {
        console.log(res);
        this.togetserimg = res.data;
      });
    },
    todeown(a) {
      console.log(a);
      this.togetserimg.forEach((el) => {
        if (a == el.cate) {
          if (el.filepdf != "") {
            this.filepdf = el.filepdf;
            var url = this.filepdf;
            window.open(url, "_blank");
          }
        }
      });
    },

    imgchange(val) {
      this.titlechanges = val;

      if (sessionStorage.getItem("newlangusge") == null) {
        this.outpadate("au", "en", this.titlechanges);
      } else {
        this.outpadate(
          "au",
          sessionStorage.getItem("newlangusge"),
          this.titlechanges
        );
      }
    },
    outpadate(a, b, c) {
      getdownting({
        cate: c,
        lang: b,
        country: a,
      }).then((res) => {
        console.log(res);
        this.downloadting = res.data.series;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.pearhome {
  .faqas {
    width: 1200px;
    margin: 0 auto;

    .faqatop {
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      display: flex;
      justify-content: center;
      align-items: center;
      .faqselfash {
        font-size: 30px;
        // font-family: Arial;
        font-weight: 900;
        color: #000;
        line-height: 30px;
        margin-top: 30px;
      }
      .frtextlis {
        margin-top: 56px;
      }
      .frtext {
        font-size: 20px;
        // font-family: Arial;
        font-weight: bold;
        color: #231916;
        line-height: 30px;
      }
      .appliance {
        margin-top: 38px;
        display: flex;
        // border-bottom: 1px solid #E1E1E1;
        padding-bottom: 50px;
        .television {
          cursor: pointer;
          width: 197px;
          height: 160px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border-right: 1px solid #f2f2f2;
          img {
            width: 56px;
            height: 56px;
          }
          .televtext {
            margin-top: 0px;
            font-size: 14px;
            // font-family: Arial;
            font-weight: bold;
            color: #231916;
            line-height: 30px;
          }
          .televtexttwo {
            margin-top: 0px;
            font-size: 14px;
            // font-family: Arial;
            font-weight: bold;
            color: rgb(219, 216, 216);
            line-height: 30px;
          }
        }
        .television:last-child {
          border-right: none;
        }
      }
    }
  }
  .cneterfotile {
    width: 100%;
    background-color: #f2f2f2;
    overflow: hidden;
    .fotileinner {
      margin: 0 auto;
      width: 1200px;
      .jqgqqzero {
        margin-top: 82px;
        // height: 546px;
        padding-bottom: 40px;
        background-color: white;
        box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.01);
        border-radius: 6px;
        overflow: hidden;
        margin-bottom: 84px;
        .jqgqqinner {
          margin: 30px 42px 42px;
          .fitietop {
            width: 100%;
            text-transform: uppercase;
            border-bottom: 1px solid #f2f2f2;
            padding-bottom: 25px;

            font-size: 18px;
            // font-family: Arial;
            font-weight: bold;
            color: #000000;
            line-height: 30px;
          }
          .fitiebottome {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            .fitiefirstchild {
              width: 240px;
              margin-top: 40px;
              justify-content: center;
              align-items: center;
              display: flex;
              flex-direction: column;
              img {
                width: 140px;
                height: 140px;
              }
              span {
                font-size: 14px;
                // font-family: Arial;
                font-weight: bold;
                color: #000000;
                line-height: 24px;
                a {
                  color: black;
                  text-decoration: none;
                }
              }
              .serieses {
                // width: 84px;
                // height: 32px;
                border: 1px solid black;
                // background: #9e2b29;
                // box-shadow: 0px 0px 20px 8px rgba(0, 0, 0, 0.2);
                border-radius: 3px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 20px;

                span {
                  font-size: 12px;
                  // font-family: Arial;
                  // font-weight: bold;
                  // color: #ffffff;
                }
                img {
                  margin-left: 10px;
                  width: 20px;
                  height: 20px;
                }
              }
              .serieses:hover {
                cursor: pointer;
                background: #9e2b29;
                border: 1px solid #9e2b29;
                color: white;

                span {
                  color: #ffffff;
                  a {
                    color: white;
                  }
                }
              }
              .seriesestwo {
                background: none;
                border: 1px solid black;
                span {
                  color: white;
                  font-weight: normal;
                }
              }
            }
          }
        }
      }
    }
  }
}
.partyfirstchild {
  width: 240px;
}
.nodatfather {
  width: 100%;
  .nodataimg {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    vertical-align: middle;
    width: 160px;
    height: 100px;
    margin-top: 100px;
  }
}

.handdown {
  margin-top: 10px;
  width: 84px;
  height: 32px;
  border: 1px solid black;
  // background: #9e2b29;
  // box-shadow: 0px 0px 20px 8px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  span {
    font-size: 12px;
  }
  a {
    color: black;
    font-size: 12px;
    font-weight: bold;
    color: #231916;
  }
}
.handdown:hover {
  cursor: pointer;
  background: #9e2b29;
  border: 1px solid #9e2b29;
  color: white;
}
.helsth {
  padding-left: 26px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 26px;
}
.healthfather {
  display: flex;
}
</style>