import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index.vue'
import alltitle from '../views/alltitle.vue'
import portrait from '../views/portrait.vue'
import alltitleandall from '../views/alltitleandall.vue'
import menu from '../views/menu.vue'
import information from '../views/information.vue'
import details from '../views/details.vue'
import pearlike from '../views/pearlike.vue'
import machine from '../views/machine.vue'
import exhibition from '../views/exhibition.vue'
import words from '../views/words.vue'
import text from '../views/text.vue'
import productlist from '../views/productlist.vue'
import constrast from '../views/constrast.vue'
import wholeworld from '../views/wholeworld.vue'
import consumer from '../views/consumer.vue'
import kitchen from '../views/kitchen.vue'
import cooperation from '../views/cooperation.vue'
import cooperationall from '../views/cooperationall.vue'
import Engineering from '../views/Engineering.vue'
import ClassicCase from '../views/ClassicCase.vue'
import ServiceIdea from '../views/ServiceIdea.vue'
import productdetail from '../views/productdetail.vue'
import register from '../views/register.vue'
import joinin from '../views/joinin.vue'
import Process from '../views/Process.vue'
// import notfound from '../views/notfound.vue'
import detailstomenu from '../views/detailstomenu.vue'
import detailstovideo from '../views/detailstovideo.vue'
import detailsproject from '../views/detailsproject.vue'
import otherwebsite from '../views/otherwebsite.vue'
import informationcopy from '../views/informationcopy.vue'
import xx from '../views/xx.vue'
import infodetion from '../views/infodetion.vue'
import contentnew from '../views/contentnew.vue'
import feedback from '../views/feedback.vue'
import aaaaa from '../views/aaaaa.vue'
import bbb from '../views/bbb.vue'





// import bottommode from '../components/bottommode.vue'
import topmode from '../components/topmode.vue'
import detailone from '../components/detailone.vue'





const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


Vue.use(VueRouter)

const routes = [
  {
    path: '/xx',
    name: 'xx',
    component: xx,
    redirect: '/informationcopy',
  },
  {
    path: '/xx',
    // name: 'xx',
    component: xx,
    children: [
      {
        path: '/informationcopy',
        name: 'Happy Kitchen',
        component: informationcopy
      },
    ]
  },
  {
    path: '/',
    name: 'FOTILE',
    component: index
  },
   {
    path: '/feedback',
    name: 'feedback',
    component: feedback
  },
  {
    path: '/aaaaa',
    name: 'aaaaa',
    component: aaaaa
  },
  {
    path: '/bbb',
    name: 'bbb',
    component: bbb
  },
  {
    path: '/infodetion',
    name: 'infodetion',
    component: infodetion
  },
  {
    path: '/contentnew',
    name: 'contentnew',
    component: contentnew
  },
  {
    path: '/otherwebsite',
    name: 'otherwebsite',
    component: otherwebsite
  },
  
  {
    path: '/joinin',
    name: 'Contact Fotile',
    component: joinin
  },
  {
    path: '/detailsproject',
    name: 'detailsproject',
    component: detailsproject
  },
  {
    path: '/detailstovideo',
    name: 'FOTILE Video Detail',
    component: detailstovideo
  },
  {
    path: '/detailstomenu',
    name: 'Recipes for Creativity Detail',
    component: detailstomenu
  },
  {
    path: '/register',
    name: 'Warranty Card',
    component: register,
    meta: { title: 'register' }
  },
  {
    path: '/detailone',
    name: 'detailone',
    component: detailone
  },
  {
    path: '/cooperation',
    name: 'ENGINEERING',
    component: cooperation,
    redirect: '/cooperationall',
  },
  {
    path: '/productdetail',
    name: 'Product details',
    component: productdetail,
   
  },
  {
    path: '/cooperation',
    name: 'ENGINEERING',
    component: cooperation,
    children: [
      {
        path: '/cooperationall',
        name: 'ENGINEERING',
        component: cooperationall,
      },
      {
        path: '/Engineering',
        name: 'ENGINEERING',
        component: Engineering,
      },
      {
        path: '/ClassicCase',
        name: 'Engineering Cooperation',
        component: ClassicCase,
      },
      {
        path: '/ServiceIdea',
        name: 'Engineering Cooperation',
        component: ServiceIdea,
      },
      {
        path: '/Process',
        name: 'Engineering Cooperation',
        component: Process,
      },
    ]
  },
  {
    path: '/details',
    name: 'FOTILE News Detail',
    component: details
  },
  {
    path: '/kitchen',
    name: 'HAPPY KITCHEN',
    component: kitchen
  },
  {
    path: '/wholeworld',
    name: 'GLOBAL OUTLETS',
    component: wholeworld
  },
  {
    path: '/consumer',
    name: 'consumer',
    component: consumer
  },
  {
    path: '/constrast',
    name: 'Contrast',
    component: constrast
  },
  {
    path: '/productlist',
    name: 'Productlist',
    component: productlist
  },
  // {
  //   path: '/bottommode',
  //   name: 'bottommode',
  //   component: bottommode
  // },
  {
    path: '/topmode',
    name: 'topmode',
    component: topmode
  },
  {
    path: '/words',
    name: 'FAQ',
    component: words
  },
  {
    path: '/text',
    name: 'text',
    component: text
  },
  {
    path: '/exhibition',
    name: 'Store Inquiry',
    component: exhibition
  },
  {
    path: '/pearlike',
    name: 'pearlike',
    component: pearlike
  },
  {
    path: '/machine',
    name: 'Download Manuals',
    component: machine
  },
  {
    path: '/alltitle',
    name: 'FOTILE News',
    component: alltitle,
    redirect: '/alltitleandall',
  },
  {
    path: '/alltitle',
    // name: 'alltitle',
    component: alltitle,
    children: [
      {
        path: '/alltitleandall',
        name: 'FOTILE News',
        component: alltitleandall,
      },
      {
        path: '/portrait',
        name: 'FOTILE Video',
        component: portrait,
      },
      {
        path: '/menu',
        name: 'Recipes for Creativity',
        component: menu,
      },
      {
        path: '/information',
        name: 'FOTILE News',
        component: information,
      },
    ]
  },
  // {
  //      path: "*",
  //      name: "notfound",
  //      component: notfound
  //    },
  {
    path: '/about',
    name: 'About FOTILE',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  routes
})
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})
export default router
