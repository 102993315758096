<template>
  <div>bsjkdhkfkhsd</div>
</template>
<script>
export default {
  created() {
    var url = window.location.href; //获取url地址
    var str = url.substr(1);
    var strs = str.split("&");
    strs[0].split("=")[1];
    var urlParmStr = strs[0].split("=")[1];
    urlParmStr = urlParmStr.slice(0, 2);
    console.log(urlParmStr, 8887);
  },
};
</script>