/**
 * api接口统一管理
 */
import { post } from "./http";

// 设备详情
export const apiDeviceDetail = data => post("/api/index/getCountryList", data);

export const navProductList = data => post("/api/Product/navProductList", data);

export const getlunbo = data => post("/api/index/bannerList", data);

export const gethomepro = data => post("/api/index/getSeriesList", data);

export const gethot = data => post("/api/index/getHotProduct", data);

export const getnews = data => post("/api/index/getHotNews", data);

//产品
export const getproban = data => post("/api/Product/bannerList", data);

export const getscreen = data => post("/api/Product/productScreen", data);

export const getGoods = data => post("/api/Product/filterGoods", data);

export const getnewslist = data => post("/api/News/getnewslist", data);

export const getnewsdetails = data => post("/api/News/getnewsdetail", data);

export const getdealer = data => post("/api/Index/dealer", data);

export const getztcountrylist = data => post("/api/Index/getztcountrylist", data);

export const getcitylist = data => post("/api/Index/getcitylist", data);

export const getshoplist = data => post("/api/Index/getshoplist", data);

export const getproductDatat = data => post("/api/Product/productData", data);

export const getbooklist = data => post("/api/News/getcookbooklist", data);

export const getvideolist = data => post("/api/News/getvideolist", data);

export const getrecnewslist = data => post("/api/News/getrecnewslist", data);

export const getrecvideolist = data => post("/api/News/getrecvideolist", data);

export const getreccookbooklist = data => post("/api/News/getreccookbooklist", data);

export const getcookbookdetail = data => post("/api/News/getcookbookdetail", data);

export const getvideodetail = data => post("/api/News/getvideodetail", data);

export const getprojectcaselist = data => post("/api/News/getprojectcaselist", data);

export const getprojectcasedetail = data => post("/api/News/getprojectcasedetail", data);

export const getrecprojectcaselist = data => post("/api/News/getrecprojectcaselist", data);

export const getdownting = data => post("/api/Product/download", data);

export const getcontrast = data => post("/api/Product/contrast", data);
//end
export const getseries = data => post("/api/Product/getseries", data);

export const getseriesgoods = data => post("/api/Product/get_series_goods", data);

export const get_goods_info = data => post("/api/Product/get_goods_info", data);

export const newcontrast = data => post("/api/Product/newcontrast", data);

export const getfaqlist = data => post("/api/News/getfaqlist", data);

export const gethappinesslist = data => post("/api/News/gethappinesslist", data);

export const gethappinessdetail = data => post("/api/News/gethappinessdetail", data);




export const getsubscribe = data => post("/api/Information/subscribe", data);

export const customerform = data => post("/api/Information/customerform", data);

export const projectcooperation = data => post("/api/Information/projectcooperation", data);

export const joinus = data => post("/api/Information/joinus", data);

export const guarantee = data => post("/api/Information/guarantee", data);

export const getiframeurl = data => post("/api/index/getiframeurl", data);

export const dsshing = data => post("/api/index/getHotReccookbook", data);

export const advice = data => post("/api/Information/advice", data);


export const severbanoner = data => post("/api/setseries/bannerList", data);

export const getneedlist = data => post("/api/setseries/setseriesList", data);














