<template>
  <div>
    <div class="toplunbo wow animate fadeInUp">
      <el-carousel
        :autoplay="false"
        height="570px"
        trigger="click"
        :loop="false"
      >
        <el-carousel-item v-for="item in list_img" :key="item.id">
          <div
            class="allmosting wow animate fadeInUp"
            @click="
              toirfrom(
                item.link,
                item.link_type,
                item.cate,
                item.type,
                item.name
              )
            "
            :style="{ backgroundImage: 'url(' + item.img + ')' }"
          ></div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="innmiddle">
      <div class="imgtotle" v-for="item in topimg" :key="item.id">
        <div class="imgtime">{{ item.series_name }}</div>
        <div
          class="imgintro"
          :style="{ backgroundImage: 'url(' + item.series_pc_img + ')' }"
        >
          <div class="imgtext" v-html="item.series_introduce"></div>
        </div>
        <div
          class="imgintro"
          v-for="val in item.goods_pc_details"
          :key="val.id"
          :style="{ backgroundImage: 'url(' + val.img + ')' }"
        >
          <div class="imgother">{{ val.title }}</div>
          <div class="imgiootntro" v-html="val.intro"></div>
          <div class="imgbox" @click="todetail(val.code)">
            {{ $t("login.morevery") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { severbanoner, getneedlist } from "../request/api";
export default {
  data() {
    return {
      list_img: [],
      topimg: [],
    };
  },
  created() {
    this.$bus.$on("add", (message, b, d) => {
      console.log(message);
      console.log(b);
      this.getbanner(message, b);
      this.getimglist(message, b);

      this.$router.replace({
        query: {
          temp: Date.now(), //必有
        },
      });
    });

    if (sessionStorage.getItem("newlangusge") == null) {
      this.getbanner("au", "en");
      this.getimglist("au", "en");
    } else {
      this.getbanner("au", sessionStorage.getItem("newlangusge"));
      this.getimglist("au", sessionStorage.getItem("newlangusge"));
    }
  },
  methods: {
    todetail(a) {
      this.$router.push({ path: "/productdetail", query: { id: a } });
    },
    getbanner(a, b) {
      severbanoner({
        type: "1",
        lang: b,
        country: a,
      }).then((res) => {
        console.log(res);
        this.list_img = res.data;
        console.log(this.list_img);
      });
    },
    getimglist(a, b) {
      getneedlist({
        lang: b,
        country: a,
      }).then((res) => {
        console.log(res, 464646);
        this.topimg = res.data;
      });
    },
  },
};
</script>
<style lang='less' scoped>
.toplunbo {
  // overflow: hidden;
  /deep/.el-carousel__indicators--horizontal {
    /* width: 50px; */
    height: 7px;
    // background-color: yellow;
  }
  /deep/ .el-carousel__indicator--horizontal {
    /* width: 6.7px !important; */
    height: 7px !important;
    padding: 0;
    margin: 0 3px;
    // background-color: purple;
  }
  /deep/.el-carousel__indicator {
    /* width: 6.7px; */
    height: 7px;
  }
  /deep/ .el-carousel__button {
    border-radius: 50px;
    width: 6.7px;
    height: 7px;
    background-color: #cecece;
  }
  /deep/ .is-active {
    // background-color: #9e2b29;
  }
}
/deep/ .el-carousel--horizontal {
  overflow: hidden;
}
/deep/ .el-carousel__indicators--horizontal {
  bottom: 30px;
}

/deep/ .toplunbo .el-carousel__button {
  opacity: 1;
}
/deep/
  .el-carousel__indicator.el-carousel__indicator--horizontal.is-active
  .el-carousel__button {
  background: #992025;
  width: 12px !important;
}
/deep/ .el-carousel__container {
  height: 400px;
}
.allmosting {
  height: 100%;
  width: 100%;
  height: 570px;
  // height: 90vh;
  background-repeat: no-repeat;
  /* background-position: center center; */
  overflow: hidden;
  // background-image: url(../assets/img/alltitle/homebanner3.jpg);
  background-position: center;
  background-size: cover;
}
.innmiddle {
  width: 1200px;
  margin: 0 auto;
  margin-top: 40px;
  height: 100%;
  // height: 900px;
  .imgtotle {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    .imgtime {
      margin-bottom: 40px;
      width: 1200px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 38px;
      font-family: Arial Black;
      font-weight: 400;
      color: #000000;
    }
    .imgintro {
      height: 100%;
      width: 100%;
      // height: 873px;
      height: 600px;
      background-repeat: no-repeat;
      /* background-position: center center; */
      overflow: hidden;
      // background-image: url(../assets/img/alltitle/homebanner3.jpg);
      background-position: center;
      background-size: cover;
      margin-bottom: 40px;
      position: relative;
    }
    .imgtext {
      color: white;
      font-size: 20px;
      font-weight: 400;
      margin: 0 auto;

      display: flex;

      justify-content: center;
      margin-top: 400px;

      align-items: center;
      position: absolute;
      bottom: 20px;
      left: 100px;
      width: 1000px;
      // text-align: center;
      line-height: 30px;
    }
    .imgother {
      color: white;
      font-size: 18px;
      font-weight: 400;
      margin: 0 auto;

      display: flex;

      justify-content: center;
      margin-top: 400px;

      align-items: center;

      // text-align: center;
      margin-top: 250px;
      font-weight: 500;
      font-size: 30px;
    }
    .imgiootntro {
      color: white;
      font-size: 20px;
      font-weight: 400;
      display: flex;
      justify-content: center;

      text-align: center;
      margin: 0 auto;
      margin-top: 40px;
      letter-spacing: 2px;
      line-height: 30px;
    }
    .imgbox {
      width: 140px;
      height: 30px;
      color: white;
      border: 1px solid white;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      font-size: 16px;
      font-weight: 400;
      margin-top: 40px;
      cursor: pointer;
    }
  }
}
</style>