<template>
  <div id="app" class="caselist">
    <tophead></tophead>
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <div class="globalstyle wow animate fadeInUp delay-900ms">
      <div
        :class="panduandian == 2 ? 'another' : ''"
        @click="dialogFormVisible = true"
      >
        {{ $t("login.dingyue") }}
      </div>
      <div
        :class="panduandian == 1 ? 'another' : ''"
        @click.stop="TOAHH12('register')"
      >
        {{ $t("login.baoxiu") }}
      </div>

      <!-- <div @click="dialogFormVisibletwo = true">
        {{ $t("login.contentus") }}
      </div> -->
    </div>
    <div class="wrapfather">
      <el-dialog
        title=""
        :visible.sync="dialogFormVisible"
        width="750px"
        top="250px"
      >
        <div class="singup">
          <div class="newstter">{{ $t("login.joninene") }}</div>
          <div class="alertsepact">
            {{ $t("login.ionudd") }}
          </div>
          <div class="jointing">
            <input
              class="joinleft"
              type="text"
              name=""
              id=""
              v-model="emails"
              :placeholder="placeholder1"
            />
            <div class="joinright" @click.stop="toemail">
              {{ $t("login.joininn") }}
            </div>
          </div>
        </div>
      </el-dialog>
    </div>
    <div class="wrapfather">
      <el-dialog
        title=""
        :visible.sync="dialogFormVisibletwo"
        width="750px"
        top="250px"
      >
        <div class="singup">
          <div class="newstter">
            <!-- Sign up for our mailing list to receive new product alerts, special
            offers, and coupon codes -->
          </div>
          <div class="alertsepact">
            Sign up for our mailing list to receive new product alerts, special
            offers, and coupon codes
          </div>
          <div class="jointing">
            <input
              class="joinleft"
              type="text"
              name=""
              id=""
              v-model="emailss"
              :placeholder="placeholder1"
            />
            <div class="joinright" @click.stop="tosub">Submit</div>
          </div>
        </div>
      </el-dialog>
    </div>
    <router-view />
    <bomode />
  </div>
</template>
<script>
import axios from "axios";
import bomode from "./components/bottommode";
import tophead from "./components/topmode";
// import { WOW } from "wowjs";
import { getsubscribe } from "./request/api";
export default {
  components: {
    bomode,
    tophead,
  },
  data() {
    return {
      dialogFormVisible: false,
      dialogFormVisibletwo: false,

      panduandian: 2,
      form: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
        emailss: "",
        emails: "",
      },
      emails: "",
      formLabelWidth: "120px",
    };
  },
  created() {
    console.log(this.$route.meta.title);
    if (this.$route.meta.title == "register") {
      this.panduandian = 1;
      console.log(this.panduandian);
    } else {
      this.panduandian = 2;
    }
  },
  computed: {
    placeholder1() {
      return this.$t("login.kehunews4");
    },
  },
  methods: {
    adding(url) {
      this.panduandian = 1;
      this.$router.push(url);
    },
    TOAHH12() {
      var url = "https://www.efotile.com.au/Warranty";
      window.open(url, "_blank");
    },
    toemail() {
      var verify = /^\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}/;

      if (this.emails == "") {
        this.$message({
          message: this.$t("login.borderred"),
          type: "error",
          offset: 90,
        });
      }
      // else if (!verify.test(this.emails)) {
      //   this.$message({
      //     message: "Please fill in the correct email format",
      //     type: "error",
      //     offset: 90,
      //   });
      // }
      else {
        if (sessionStorage.getItem("newlangusge") == null) {
          this.fith("en");
        } else {
          this.fith(sessionStorage.getItem("newlangusge"));
        }
      }
    },
    tosub() {
      console.log(11111);

      var api =
        "https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8";
      axios
        .post(api, {
          oid: "00D2y000000q5Z6",
          retURL: this.emailss,
          email: "948421361@qq.com",
        })
        .then((res) => {
          console.log(res);
          this.emailss = "";
        });
    },
    fith(a) {
      getsubscribe({
        lang: a,
        country: "au",
        email: this.emails,
      }).then((res) => {
        console.log(res);
        this.emails = "";
        this.$message({
          message: this.$t("login.sucssfil"),
          type: "success",
          offset: 90,
        });
      });
    },
  },
  mounted() {
    this.$bus.$on("registid", (a) => {
      console.log(a);
      this.panduandian = a;
    });

    if (document.documentElement.scrollTop > 0) {
      document.documentElement.scrollTop = 0;
    }
  },
  befordestroye() {
    console.log(348968954738968943798);
  },
  destroyed() {
    this.panduandian = 2;

    console.log(348968954738968943798);
  },
};
</script>
<style lang="less">
body {
  padding: 0px;
  margin: 0px;
  overflow-x: hidden;
}
/deep/ .el-dialog {
  border-radius: 8px;
  width: 680px;
}

.wrapfather {
  /deep/.el-dialog__wrapper {
    /deep/ .el-dialog {
      border-radius: 8px;
      width: 680px !important;
    }
  }
}
.globalstyle {
  position: fixed;
  bottom: 0px;
  right: 180px;
  z-index: 99;
  display: flex;
  justify-content: space-around;
  width: 260px;
  height: 47px;
  background: #ffffff;
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.19);
  border-radius: 8px;
  align-items: center;
  padding: 0 8px 0 0;
  div {
    font-size: 13px;
    font-family: Arial;
    font-weight: 400;
    color: #000000;
    cursor: pointer;
  }
  div:hover {
    color: #9d2736;
  }
  .another {
    width: 116px;
    height: 37px;
    background: #9d2736;
    border-radius: 8px;
    font-size: 13px;
    font-family: Arial;
    font-weight: 400;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .another:hover {
    background: #000;
    color: #fff;
  }
}

.singup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // margin-top: 20px;
  .newstter {
    // width: 522px;
    font-size: 24px;
    font-family: Arial Black;
    font-weight: 900;
    color: #000000;
    text-transform: uppercase;
  }
  .alertsepact {
    width: 428px;
    font-size: 16px;
    // font-family: Arial;
    font-weight: bold;
    color: #000000;
    line-height: 22px;
    opacity: 0.75;
    text-align: center;
    margin-top: 18px;
  }
  .jointing {
    margin-top: 40px;
    display: flex;
    margin-bottom: 30px;
    .joinleft {
      width: 438px;
      height: 49px;
      background: #f7f7f7;
      border-radius: 10px 0px 0px 10px;
      outline: none;
      border: none;
      font-size: 13px;
      text-indent: 12px;
    }
    .joinright {
      width: 120px;
      height: 50px;
      background: #3a3737;
      border-radius: 5px 5px 5px 5px;
      font-size: 15px;
      // font-family: Arial;
      font-weight: bold;
      color: #faf8f8;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 30px;
      cursor: pointer;
    }
    .joinright:hover {
      background: #81181c;
    }
  }
}
// /*滚动条里面轨道*/
// body::-webkit-scrollbar-track {
//   -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0);
//   background-color: rgb(20, 19, 19);
// }
// /*定义滚动条整体的样式*/
// body::-webkit-scrollbar {
//   width: 6px;
//   background-color: rgb(20, 19, 19);
// }
// /*滚动条的样式*/
// body::-webkit-scrollbar-thumb {
//   height: 20px;
//   background-image: -webkit-gradient(
//     linear,
//     left bottom,
//     left top,
//     color-stop(0.2, rgb(125, 126, 128)),
//     color-stop(0.4, rgb(97, 98, 99)),
//     color-stop(0.8, rgb(125, 126, 128))
//   );
// }

// #app {
//   font-family: Avenir, Helvetica, Arial, sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   text-align: center;
//   color: #2c3e50;
// }

// #nav {
//   padding: 30px;

//   a {
//     font-weight: bold;
//     color: #2c3e50;

//     &.router-link-exact-active {
//       color: #42b983;
//     }
//   }
// }
// ::-webkit-scrollbar{width:5px;height:5px;}
// ::-webkit-scrollbar-track{border-radius: 0px;background:rgba(255,255,255,0);}
// ::-webkit-scrollbar-thumb{border-radius:5px;background:#999;}
// ::-webkit-scrollbar-thumb:hover{border-radius:5px;background:#999;}
* {
  font-family: "PingFang SC", "PingHei", "STHeitiSC-Light", "Myriad Set Pro",
    "Lucida Grande", "Helvetica Neue", "Helvetica", "microsoft yahei", "SimHei",
    "tahoma", "Arial", "Verdana", "sans-serif";
  *font-family: "SimHei";
  transition: all 0.3s linear;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-track {
  border-radius: 0px;
  background: rgba(255, 255, 255, 0);
}
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #cccccc;
}
::-webkit-scrollbar-thumb:hover {
  border-radius: 5px;
  background: #cccccc;
}

@-webkit-keyframes fadeInDown2 {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInDown2 {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes fadeInUp2 {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 40px, 0);
    transform: translate3d(0, 40px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInUp2 {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 40px, 0);
    transform: translate3d(0, 40px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes fadeInUp3 {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, toMRem(0), 0);
    transform: translate3d(0, toMRem(0), 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInUp3 {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, toMRem(0), 0);
    transform: translate3d(0, toMRem(0), 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.fadeInUp {
  -webkit-animation-name: fadeInUp2;
  animation-name: fadeInUp2;
}
.fadeInUp2 {
  -webkit-animation-name: fadeInUp3;
  animation-name: fadeInUp3;
}
.fadeInDown {
  -webkit-animation-name: fadeInDown2;
  animation-name: fadeInDown2;
}
.animated {
  -webkit-animation-duration: 1.2s;
  animation-duration: 1.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.animated2 {
  -webkit-animation-duration: 0.8s;
  animation-duration: 0.8s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.animate.delay-200ms {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
.animate.delay-300ms {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.animate.delay-400ms {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}
.animate.delay-500ms {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}
.animate.delay-600ms {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}
.animate.delay-700ms {
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}
.animate.delay-800ms {
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}
.animate.delay-900ms {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}
// .caselist {
//   width: 100%;
//   height: 100%;
//   overflow: auto;
// }
// /*滚动条整体部分*/
// .caselist::-webkit-scrollbar {
//   width: 0;
//   height: 0;
// }
</style>
