<template>
  <div class="newoutside">
    <div class="newinner">
      <div class="newtime">{{ manynew.publishdate }}&nbsp&nbsp&nbsp&nbsp</div>
      <div class="newtitle">
        <!-- BEHIND THE "SCIENCE AND TECHNOLOGY PROGRESS AWARD" AT THE NOBEL PRIZE
        WINNER MEDICAL SUMMIT, FANG TAI ONCE AGAIN LEVERAGED THE DEVELOPMENT OF
        THE INDUSTRY WITH HIS ORIGINAL INVENTION -->
        {{ manynew.title }}
      </div>
      <div class="peopleimg">
        <!-- <img src="../assets/img/alltitle/people.png" alt="" /> -->
        <!-- <img :src="manynew.img" alt="" /> -->
      </div>
      <div class="newcontent" v-html="manynew.content"></div>
    </div>
  </div>
</template>
<script>
import { WOW } from "wowjs";
// import { quillEditor } from "vue-quill-editor";
// import "quill/dist/quill.core.css";
// import "quill/dist/quill.snow.css";
// import "quill/dist/quill.bubble.css";
import { getnewsdetails, gethappinessdetail } from "../request/api";

export default {
  // components: { quillEditor },
  data() {
    return {
      manynew: {},
      // content: "",
      // editorOption: {},
    };
  },
  created() {
    console.log(this.$route.query.id);

    if (sessionStorage.getItem("newlangusge") == null) {
      this.outdets("au", "en", "d", this.$route.query.id);
    } else {
      this.outdets(
        "au",
        sessionStorage.getItem("newlangusge"),
        "d",
        this.$route.query.id
      );
    }

    this.$bus.$on("add", (message, b, d) => {
      console.log(message);
      console.log(b);
      console.log(d);
      this.outdets(message, b, d, this.$route.query.id);
    });
  },
  methods: {
    outdets(a, b, c, d) {
      gethappinessdetail({
        lang: b,
        country: a,
        id: d,
      }).then((res) => {
        console.log(res);
        this.manynew = res.data;
      });
    },
  },
  mounted() {
    if (document.documentElement.scrollTop > 0) {
      document.documentElement.scrollTop = 0;
    }
    // document.documentElement.scrollTop = 0;
    // window.addEventListener("scroll", '0');
    this.$nextTick(() => {
      // ��dom��Ⱦ�����ִ�ж���
      const wow = new WOW({
        offset: 0,
        // mobile: true,
        live: true,
      });
      wow.init();
    });
  },
};
</script>
<style lang="less" scoped>
.newoutside {
  .newinner {
    width: 1200px;
    margin: 0 auto;
    .newtime {
      margin-top: 52px;
      font-size: 12px;
      font-family: Helvetica Neue LT Pro;
      font-weight: normal;
      color: #474545;
    }
    .newtitle {
      margin-top: 42px;
      font-size: 19px;
      // font-family: Arial;
      font-weight: bold;
      color: #000000;
      line-height: 30px;
    }
    .peopleimg {
      img {
        width: 1200px;
        height: 600px;
        margin-top: 50px;
      }
    }
    .newcontent {
      margin-top: 50px;
      font-size: 12px;
      // font-family: PingFang SC;
      font-weight: 400;
      color: #151515;
      line-height: 25px;
      padding-bottom: 50px;
    }
    .newcontenttwo {
      margin-bottom: 200px;
    }
  }
}
</style>