<template>
  <div class="manuslgude">
    <div class="brochure">{{ $t("login.manual") }}</div>
    <div class="warrantly">
      <div class="policty" v-if="manual != ''">
        <img src="../assets/img/detailimg/book1.png" alt="" />
        <div class="englisth">{{ $t("login.mandetai1") }}</div>
        <div class="todcha todcha-huitt">
          <a
            :href="manual"
            class="helsth"
            style="text-decoration: none; color: white"
            >{{ $t("login.downloadli") }}</a
          >
        </div>
      </div>
      <div class="policty" v-if="spec_sheet != ''">
        <img src="../assets/img/detailimg/book2.png" alt="" />
        <div class="englisth">{{ $t("login.mandetai2") }}</div>
        <div class="todcha todcha-huitt">
          <a
            :href="spec_sheet"
            class="helsth"
            style="text-decoration: none; color: white"
            >{{ $t("login.downloadli") }}</a
          >
        </div>
      </div>
      <div class="policty" v-if="design_file != ''">
        <img src="../assets/img/detailimg/book3.png" alt="" />
        <div class="englisth">{{ $t("login.mandetai3") }}</div>
        <div class="todcha todcha-huitt">
          <a
            :href="design_file"
            class="helsth"
            style="text-decoration: none; color: white"
            >{{ $t("login.downloadli") }}</a
          >
        </div>
      </div>
      <div class="policty" v-if="warranty_document != ''">
        <img src="../assets/img/detailimg/book4.png" alt="" />
        <div class="englisth">{{ $t("login.mandetai4") }}</div>
        <div class="todcha todcha-huitt">
          <a
            class="helsth"
            :href="warranty_document"
            style="text-decoration: none; color: white"
            >{{ $t("login.downloadli") }}</a
          >
        </div>
      </div>
      <div class="policty" v-if="accessories_lists != ''">
        <img src="../assets/img/detailimg/book5.png" alt="" />
        <div class="englisth">{{ $t("login.mandetai5") }}</div>
        <div class="todcha todcha-huitt">
          <a
            class="helsth"
            :href="accessories_lists"
            style="text-decoration: none; color: white"
            >{{ $t("login.downloadli") }}</a
          >
        </div>
      </div>

      <div class="policty" v-if="menu_dow != ''">
        <img src="../assets/img/detailimg/book7.png" alt="" />
        <div class="englisth">{{ $t("login.newthis") }}</div>
        <div class="todcha todcha-huitt">
          <a
            :href="menu_dow"
            class="helsth"
            style="text-decoration: none; color: white"
            >{{ $t("login.downloadli") }}</a
          >
        </div>
      </div>

      <div class="policty" v-if="brochure != ''">
        <img src="../assets/img/detailimg/book6.png" alt="" />
        <div class="englisth">{{ $t("login.mandetai6") }}</div>
        <div class="todcha todcha-huitt">
          <a
            :href="brochure"
            class="helsth"
            style="text-decoration: none; color: white"
            >{{ $t("login.downloadli") }}</a
          >
        </div>
      </div>
      <div class="anotherp"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "brochure",
    "manual",
    "spec_sheet",
    "warranty_document",
    "accessories_lists",
    "menu_dow",
    "design_file",
  ],

  data() {
    return {};
  },
};
</script>
<style lang="less" scoped>
.manuslgude {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  .brochure {
    margin-top: 40px;
    font-size: 18px;
    font-family: Arial;
    font-weight: bold;
    color: #000000;
  }
  .warrantly {
    margin-top: 44px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .policty {
      margin-bottom: 22px;
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 370px;
      margin: 0 10px 10px 10px;

      background: #fafafa;
      transition: transform 0.3s;
      // height: 200px;
      img {
        margin-top: 34px;
        width: 30px;

        // height: 40px;
      }
      .englisth {
        margin-top: 11px;
        font-size: 14px;
        font-family: Arial;
        font-weight: bold;
        color: #000000;
      }
      .todcha {
        margin-top: 17px;
        margin-bottom: 34px;
        // width: 90px;
        // height: 32px;
        background: #9e2b29;
        border-radius: 3px;
        font-size: 14px;
        font-family: Arial;
        font-weight: 400;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
      .todcha:hover {
        // background: #000000;
      }

      .todcha-hui {
        background: #e6e6e6;
        color: #7d7d7d;
        width: 170px;
      }
      .todcha-huitt {
        background: #9e2b29;
        color: #7d7d7d;
        // width: 170px;
      }
      .todcha-huitt:hover {
        background: #000000;
      }
    }
    .policty:hover {
      transform: translate(0, -5px);
      box-shadow: 5px 8px 16px 0px rgba(8, 1, 3, 0.05);
    }
  }
}
.anotherp {
  width: 370px;
}
.helsth {
  padding-left: 46px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 46px;
}
</style>