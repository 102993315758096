<template>
  <div class="allcontainer">
    <div class="centerwidth">
      <div class="firstwird firstwirdtwo">
        <div
          class="firstcontent wow animate fadeInUp"
          v-if="newlist.length != 0"
        >
          {{ $t("login.gourmet") }}
        </div>
        <div
          class="secondcontent wow animate fadeInUp delay-100ms"
          v-if="newlist.length != 0"
        >
          <div class="context">
            {{ $t("login.cokintmast") }}
          </div>
          <!-- <div class="conarrow">
									<div class="conall">All Activity</div>
									<img class="likearrow" src="../assets/img/alltitle/rowarrow.png" />
								</div> -->
        </div>
        <img
          v-if="newlist.length == 0"
          class="nodataimg"
          src="../assets/img/alltitle/nodata.png"
          alt=""
        />
        <div class="theretext">
          <div
            class="newspaper newspapertwo wow animate fadeInUp delay-200ms"
            v-for="item in newlist"
            :key="item.id"
            @click="todetail(item.id)"
          >
            <div class="paperinner">
              <div class="">
                <img class="twoimgscopy twoimgscopyTWO" :src="item.img" />
              </div>
              <div class="lastfont">
                <!-- THE PERFECT ENCOUNTER OF CHEESE AND EGGPLANT -->
                {{ item.title }}
              </div>
              <div class="lasttext">
                <!-- Fangtai Culture Research Institute is a non-profit pate nental
                organization, dissemin ... -->
                {{ item.intro }}
              </div>
              <div class="keoutdoor">
                <div class="learnmore learnmoretwo">
                  {{ $t("login.mostdetail") }}
                </div>
              </div>
            </div>
          </div>

          <div class="backcolor"></div>
          <div class="backcolor"></div>
        </div>
      </div>

      <!-- <div class="therelikecamera">
              <el-pagination
                background
                prev-text='prev'
                next-text='next'
                layout="prev, pager, next"
                :total="1000">
              </el-pagination>
            </div> -->
    </div>
  </div>
</template>
<script>
import { WOW } from "wowjs";
import { getbooklist } from "../request/api";

export default {
  data() {
    return {
      newlist: [],
      page: 1,
      limit: 99,
    };
  },
  created() {
    console.log(this.$route.query.id);
    this.newlist = this.$route.query.id;

    if (sessionStorage.getItem("newlangusge") == null) {
      this.outmenu("au", "en", "d");
    } else {
      this.outmenu("au", sessionStorage.getItem("newlangusge"), "d");
    }
    setTimeout(() => {
      this.$route.meta.title = this.$t("login.querythere13");
      this.$router.replace({
        query: {
          temp: Date.now(), //必有
        },
      });
    }, 10);
    this.$bus.$on("add", (message, b, d) => {
      console.log(message);
      console.log(b);
      console.log(d);
      this.outmenu(message, b, d);
      this.$route.meta.title = this.$t("login.querythere13");
      this.$router.replace({
        query: {
          temp: Date.now(), //必有
        },
      });
    });
  },
  methods: {
    outmenu(a, b, c) {
      getbooklist({
        lang: b,
        country: a,
        page: this.page,
        limit: this.limit,
      }).then((res) => {
        console.log(res);
        this.newlist = res.data.data;
      });
    },
    todetail(a) {
      this.$router.push({ path: "/detailstomenu", query: { id: a } });
    },
  },
  mounted() {
    if (document.documentElement.scrollTop > 0) {
      document.documentElement.scrollTop = 0;
    }
    // document.documentElement.scrollTop = 0;
    // window.addEventListener("scroll", '0');
    this.$nextTick(() => {
      // ��dom��Ⱦ���,��ִ�ж���
      const wow = new WOW({
        offset: 0,
        // mobile: true,
        live: true,
      });
      wow.init();
    });
  },
};
</script>
<style scoped lang='less'>
.alltitle {
  .fournavoutside {
    .navtitlehead {
      width: 100%;
      height: 70px;
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      .navtitleinner {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 260px;
        display: flex;
        img {
          width: 20px;
          height: 20px;
        }
        .navtext {
          margin-left: 6px;
          font-size: 16px;
          font-family: Arial;
          font-weight: 400;
          color: #000000;
          line-height: 24px;
        }
      }
    }
  }
  .allcontainer {
    width: 100%;
    background-color: #f2f2f2;
  }

  .centerwidth {
    width: 1200px;
    margin: 0 auto;
  }

  .firstwird {
    width: 1200px;
    display: flex;
    flex-direction: column;
    padding-top: 70px;
  }

  .firstcontent {
    font-size: 28px;
    font-family: Arial Black;
    font-weight: 900;
    color: #000000;
    text-transform: uppercase;
  }

  .secondcontent {
    display: flex;
    /*background-color: red;*/
    justify-content: space-between;
    margin-top: 10px;
  }

  .context {
    font-size: 15px;
    /* font-family: Arial; */
    font-weight: 400;
    color: #000000;
    line-height: 24px;
    opacity: 0.85;
    /* width: 698px; */
  }

  .conarrow {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .conall {
    vertical-align: middle;
    font-size: 12px;
    /* font-family: Arial; */
    /* font-weight: 400; */
    color: #000000;
    line-height: 48px;
    cursor: pointer;
  }

  .likearrow {
    margin-left: 11px;
    vertical-align: middle;
    width: 14%;
    /* height: 14px; */
  }

  .theretext {
    display: flex;
    margin-top: 55px;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .newspaper {
    width: 386px;
    /* height: 300px; */
    background: #ffffff;
    border-radius: 7px;
    margin-bottom: 25px;
    padding-bottom: 0px;
    cursor: pointer;
    transition: transform 0.3s;
  }

  .newspaper:hover {
    transform: translate(0, -10px);
    box-shadow: 5px 8px 16px 0px rgba(8, 1, 3, 0.13);
  }

  .newspapertwo {
    width: 386px;
    /* height: 407px!important; */
    background: #ffffff;
    border-radius: 7px;
    /*background-color: red;*/
  }
  .backcolor {
    background: #f2f2f2;
    width: 386px;
  }

  .paperinner {
    margin: 18px 24px;
  }

  .paperhead {
    display: flex;
    width: 315px;
    border-bottom: 1px solid #f2f2f2;
    padding-bottom: 16px;
  }

  .papertime {
    font-size: 12px;
    font-family: Helvetica Neue LT Pro;
    font-weight: normal;
    color: #474545;
  }

  .paperfont {
    margin-left: 19px;
    font-size: 12px;
    font-family: Helvetica Neue LT Pro;
    font-weight: normal;
    color: #474545;
  }

  .fontbig {
    margin-top: 20px;
    /*display: flex;*/
    font-size: 18px;
    font-family: Arial;
    font-weight: bold;
    color: #3c3c3c;
    line-height: 24px;
  }

  .imgandfont {
    display: flex;
    margin-top: 20px;
  }

  .leftimgs {
    width: 82px;
    height: 66px;
  }

  .rightfont {
    width: 212px;
    /* height: 60px; */
    font-size: 12px;
    line-height: 17px;
    /* font-family: Arial; */
    /* font-weight: 400; */
    color: #898989;
    opacity: 0.75;
    margin-left: 16px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
  }

  .keoutdoor {
    display: flex;
    /* width: 315px; */
    justify-content: flex-end;
  }

  .learnmore {
    /* width: 65px; */
    margin-top: 12px;
    padding-bottom: 5px;
    font-size: 12px;
    /* font-family: Arial; */
    /* font-weight: 400; */
    color: #898989;
    line-height: 13px;
    /* border-bottom: 1px solid #AB1A30; */
    text-align: right;
  }

  .learnmore::after {
    content: "";
    display: block;
    background-color: #9e2b29;
    // width: 65px;
    height: 1px;
    margin-top: 4px;
    /* position: absolute; */
    font-size: 12px;
    transition: all 0.6s ease 0s;
  }

  .learnmore:hover::after {
    content: "";
    display: block;
    margin-top: 4px;
    background-color: #9e2b29;
    width: 0px;
    height: 1px;
    font-size: 12px;
    transition: all 0.6s ease 0s;
  }

  .learnmore:hover {
    color: #9e2b29;
  }

  .twoimgscopy {
    margin-top: 30px;
    width: 310px;
    height: 170px;
    background: #000000;
    /*opacity: 0.3;*/
    border-radius: 18px;
  }

  .twoimgscopyTWO {
    margin-top: 10px;
    width: 100%;
    height: 220px;
    background: #000000;
    /*opacity: 0.3;*/
    border-radius: 4px;
  }

  .lastfont {
    width: 100%;
    font-size: 16px;
    /* font-family: Arial; */
    font-weight: bold;
    color: #3c3c3c;
    line-height: 24px;
    margin-top: 15px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }

  .lasttext {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    margin-top: 12px;
    width: 100%;
    font-size: 12px;
    /* font-family: Arial; */
    /* font-weight: 400; */
    color: #898989;
    line-height: 18px;
    opacity: 0.75;
  }

  .learnmoretwo {
    margin-top: 12px;
  }

  .firstwirdtwo {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .therelikecamera {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 100px;
    /deep/ .el-pager li {
      background-color: white;
      height: 30px;
      color: #898989;
    }
    /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
      background-color: #000000;
      color: #ffffff;
      border: 1px solid #000000;
      height: 30px;
    }
    /deep/ .el-pagination button,
    .el-pagination span:not([class*="suffix"]) {
      background-color: white;
      color: #898989;
      width: 50px;
      height: 30px;
    }
  }
}
.nodataimg {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  vertical-align: middle;
  width: 160px;
  height: 100px;
}
</style>
