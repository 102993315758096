<template>
  <div class="home">
    <!-- <tophead></tophead> -->
    <div class="toplunbo wow animate fadeInUp">
      <el-carousel
        :autoplay="false"
        height="90vh"
        trigger="click"
        :loop="false"
      >
        <el-carousel-item v-for="item in list_img" :key="item.id">
          <div
            class="allmosting wow animate fadeInUp"
            @click="
              toirfrom(
                item.link,
                item.link_type,
                item.cate,
                item.type,
                item.name
              )
            "
            :style="{ backgroundImage: 'url(' + item.img + ')' }"
          ></div>
        </el-carousel-item>
      </el-carousel>
    </div>

    <div class="centerwidth" v-if="togetserimg.length != 0">
      <div class="firstwird">
        <div class="firstcontent wow animate fadeInUp">
          <!-- CREATE GREAT PRIDUCTS WITH LOVE AND<br />
          KINDNESSFOTILE Six product series -->
          {{ $t("login.biginning") }}
        </div>
        <div class="secondcontent wow animate fadeInUp delay-100ms">
          <div class="context">
            <!-- fotile believes that products created for love and kindness will be
            favored by the market products created for love <br />and kindness
            will be favored products created for love and -->
            {{ $t("login.nowhave") }}
          </div>
          <div class="conarrow">
            <!-- <img
              class="likearrowleft"
              @click="moverleft"
              src="../assets/img/alltitle/rowarrowleft.png"
            /> -->
            <!-- <div class="conall">{{ $t("login.allproduct") }}</div>
            <img class="likearrow" src="../assets/img/alltitle/rowarrow.png" /> -->
            <!-- <img
              class="likearrow"
              @click="moveright"
              src="../assets/img/alltitle/rowarrow.png"
            /> -->
          </div>
        </div>
      </div>
    </div>
    <!-- valscroll -->
    <div class="namevalcoll" v-if="togetserimg.length != 0">
      <div class="centerlikeresion wow animate fadeInUp" id="showlove">
        <div
          class="everyimg wow animate fadeInUp delay-200ms"
          v-for="(item, index) in togetserimg"
          :key="item.id"
        >
          <div class="picBox" @click="tolovesmile(item.cate)">
            <img class="dioenonn" :src="item.img" alt="" />
            <!-- src="../assets/img/alltitle/middle-2.jpg" -->
            <img class="yy" src="../assets/img/alltitle/yy.png" alt="" />
          </div>
          <div class="dignbegin" @click="tolovesmile(item.cate)">
            <div class="mounthsnose">
              <span>{{ item.title }}</span>
              <img src="../assets/img/alltitle/shashalove.png" alt="" />
            </div>
            <!-- <div class="mounthsnose">
              <span>{{ item.intro }}</span>
              <img src="../assets/img/alltitle/shashalove.png" alt="" />
            </div> -->
          </div>
          <div class="handdown" v-if="item.filepdf != ''">
            <span
              ><a :href="item.filepdf" style="text-decoration: none">{{
                $t("login.manualdownload")
              }}</a></span
            >
          </div>
        </div>

        <!-- 滚动条遮盖框 -->
        <div :class="blackchange ? 'zhegaiscrooltwo' : 'zhegaiscrool'"></div>
      </div>
    </div>

    <!-- mother happy -->
    <div class="twicechange wow animate fadeInUp">
      <div class="twoceleft">
        <!-- <el-carousel :interval="5000" arrow="always" height="880px">
          <el-carousel-item
            v-for="(item, index) in twolunimg"
            :key="index"
            class="Rotation"
          >
            <img class="twoheadimg" :src="item.url" alt="" />
          </el-carousel-item>
        </el-carousel>
      --></div>
      <div class="motherright">
        <div class="motherinner">
          <div class="mothersonone wow animate fadeInUp animate__delay-300ms">
            <!-- Fotile life philosophy<br />Fotile life HAPPU KITCHER
            <br />IS THE CENTER OF HOME -->
            {{ $t("login.happytochoose") }}
          </div>
          <div class="mothersontwo wow animate fadeInUp animate__delay-600ms">
            <p>
              {{ $t("login.allowaystodo") }}
            </p>
            <!-- <p>
              We strive to find a better solution to the problem of cooking
              grease and fumes, thus opening up the kitchen and letting it
              return from the farthest corner of the home to its rightful place
              at the center of the family. Time in the kitchen is no longer a
              lonely chore...
            </p> -->
          </div>
          <div
            class="mothersonthere wow animate fadeInUp animate__delay-800ms"
            @click="tohappiness"
          >
            <span>{{ $t("login.moreandmore") }}</span>
            <!-- <img src="../assets/img/alltitle/littlein.png" alt="" /> -->
          </div>
        </div>
      </div>
    </div>

    <div class="newaddfateher" v-if="hotspot.length != 0">
      <div class="newsonsing">
        <div class="debughma wow animate fadeInUp">
          {{ $t("login.newadd1") }}
        </div>
        <div class="fnetwite wow animate fadeInUp">
          {{ $t("login.newadd2") }}
        </div>
      </div>
      <div class="chouyousmoke">
        <div
          class="smokeson wow animate fadeInUp delay-200ms"
          v-for="item in hotspot"
          :key="item.id"
        >
          <!-- <img class="waterwell" src="../assets/img/alltitle/cxw-2.jpg" alt="" /> -->
          <img class="waterwell" :src="item.thumb" alt="" />
          <div class="watercentertext">
            <!-- <div class="jzyson">EQS/T/R-JEM9</div> -->
            <div class="jzyson">{{ item.code }}</div>
            <div class="fotileson">
              <!-- fotile's R&D Team visited more than 1,000 households in 25 cities on
            an itinerary of more than 100,000 -->
              {{ item.intro }}
            </div>
            <div class="moreson" @click="gowithe(item.code)">
              <span>{{ $t("login.moreandmore") }}</span>
              <!-- <img
            class="moteimm"
            src="../assets/img/alltitle/whiterow.png"
            alt=""
          /> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="blackwhtch wow animate fadeInUp">
      <div class="tablebalck">
        <!-- <img
          class="bigbalimm"
          src="../assets/img/alltitle/balkwatch.png"
          alt=""
        /> -->
        <div class="bigisfather"></div>
        <div class="bottson">
          <div class="foursonfirst wow animate fadeInUp delay-200ms">
            <div class="foursoninner">
              <div class="eightadd">
                <animate-number
                  class="changenumbers"
                  from="0"
                  to="900"
                  ref="myNum1"
                  mode="manual"
                ></animate-number
                ><span>+</span>
              </div>
              <div class="fotilein">
                {{ $t("login.number1") }}
              </div>
              <!-- <div class="insists">
                <span>{{ $t("login.moreandmore") }}</span>
              </div> -->
            </div>
          </div>
          <!-- <div class="shoppcarting"></div> -->
          <img
            src="../assets/img/alltitle/lingray.png"
            alt=""
            class="sizhiehjhei"
          />
          <div class="foursonfirst wow animate fadeInUp delay-400ms">
            <div class="foursoninner">
              <div class="eightadd">
                <animate-number
                  class="changenumbers"
                  from="0"
                  to="16000"
                  ref="myNum2"
                  mode="manual"
                ></animate-number
                ><span>+</span>
              </div>
              <div class="fotilein">
                {{ $t("login.number2") }}
              </div>
              <!-- <div class="insists">
                <span>{{ $t("login.moreandmore") }}</span>
              </div> -->
            </div>
          </div>
          <img
            src="../assets/img/alltitle/lingray.png"
            alt=""
            class="sizhiehjhei"
          />

          <div class="foursonfirst wow animate fadeInUp delay-400ms">
            <div class="foursoninner">
              <div class="eightadd">
                <animate-number
                  class="changenumbers"
                  from="0"
                  to="4500"
                  ref="myNum3"
                  mode="manual"
                ></animate-number
                ><span>+</span>
              </div>
              <div class="fotilein">
                {{ $t("login.number3") }}
              </div>
              <!-- <div class="insists">
                <span>{{ $t("login.moreandmore") }}</span>
              </div> -->
            </div>
          </div>
          <img
            src="../assets/img/alltitle/lingray.png"
            alt=""
            class="sizhiehjhei"
          />

          <div class="foursonfirst wow animate fadeInUp delay-400ms">
            <div class="foursoninner">
              <div class="eightadd">
                <animate-number
                  class="changenumbers"
                  from="0"
                  to="12000"
                  ref="myNum4"
                  mode="manual"
                ></animate-number
                ><span>+</span>
              </div>
              <div class="fotilein">
                {{ $t("login.number4") }}
              </div>
              <!-- <div class="insists">
                <span>{{ $t("login.moreandmore") }}</span>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 此处 -->
    <!-- <div class="namevalcoll2" v-if="manynews.length != 0">
      <div class="worrydnew">
        <div class="centerwidth">
          <div class="firstwird">
            <div class="firstcontent wow animate fadeInUp">
              {{ $t("login.newactive") }}
            </div>
            <div class="secondcontent">
              <div class="context wow animate fadeInUp delay-200ms">
                {{ $t("login.newmodel") }}
              </div>
              <div
                class="conarrow wow animate fadeInUp delay-200ms"
                @click="gonewpeapel"
              >
                
                <div class="conall">{{ $t("login.allting") }}</div>
                <img
                  class="likearrow"
                  src="../assets/img/alltitle/rowarrow.png"
                />
                
              </div>
            </div>
          </div>
        </div>

        <div class="centerlikeresion lingshancheck" id="showbrother">
          <div
            class="
              newspaper
              mr36
              animatechangeone
              wow
              animate
              fadeInUp
              delay-300ms
            "
            v-for="item in manynews"
            :key="item.id"
            @click="todetail(item.id)"
          >
            <div class="paperinner">
              <div class="paperhead">
                <div class="papertime">{{ item.publishdate }}</div>
              </div>
              <div class="fontbig">
                {{ item.title }}
              </div>
              <div class="imgandfont">
                <img class="leftimgs" :src="item.img" />
                <div class="rightfont">
                  {{ item.intro }}
                </div>
              </div>
              <div class="keoutdoor">
                <div class="learnmore">{{ $t("login.allting") }}</div>
              </div>
            </div>
          </div>
        </div>
        <div :class="abblack ? 'ansefa' : 'ansefatwo'"></div>
      </div>
    </div> -->

    <div class="allcontainer1 wow animate fadeInUp">
      <div class="centerwidth1 wow animate fadeInUp">
        <div
          class="firstwird1 firstwirdtwo1 wow animate fadeInUp"
          v-if="manynews.length != 0"
        >
          <div class="firstcontent1 wow animate fadeInUp">
            {{ $t("login.newactive") }}
          </div>
          <div class="secondcontent1 wow animate fadeInUp delay-200ms">
            <div class="context1">
              {{ $t("login.newmodel") }}
            </div>
            <!-- <div class="conarrow">
            <div class="conall">{{ $t("login.allting") }}</div>
            <img class="likearrow" src="../assets/img/alltitle/rowarrow.png" />
          </div> -->
          </div>
        </div>
        <div class="theretext1">
          <div
            class="newspaper1 newspapertwo1 wow animate fadeInUp delay-300ms"
            v-for="item in manynews"
            :key="item.id"
            @click="todetail(item.id)"
          >
            <div class="paperinner1">
              <div class="">
                <!-- <img
                  class="twoimgscopy twoimgscopyTWO"
                  src="../assets/img/alltitle/cp-01.jpg"
                /> -->
                <img class="twoimgscopy1 twoimgscopyTWO1" :src="item.img" />
              </div>
              <div class="lastfont1" style="height: 45px">
                <!-- THE PERFECT ENCOUNTER OF CHEESE AND EGGPLANT -->
                {{ item.title }}
              </div>
              <div class="lasttext1">
                <!-- Fangtai Culture Research Institute is a non-profit pate nental
                organization, dissemin ... -->
                {{ item.intro }}
              </div>
              <div class="keoutdoor1">
                <div class="learnmore1 learnmoretwo1">
                  {{ $t("login.mostdetail") }}
                </div>
              </div>
            </div>
          </div>
          <div class="othernews1" v-if="manynews.length < 4"></div>

          <!-- <div class="antohwidth"></div> -->
        </div>
      </div>
    </div>
    <!-- over -->

    <!-- <div class="imagquns">
      <div class="fenfirstrow">
        <div class="manydingsome w66 wow animate fadeInUp">
          <img class="fenicon1" src="../assets/img/alltitle/1.png" alt="" />
          <img
            class="snalnfont"
            src="../assets/img/alltitle/balkiny.png"
            alt=""
          />
          <div class="jinjuecfire">
            <div class="scalbeead">{{ $t("login.food1") }}</div>
          </div>
        </div>
        <div class="manydingsome w34 wow animate fadeInUp delay-200ms">
          <img class="fenicon2" src="../assets/img/alltitle/2.png" alt="" />
          <img
            class="snalnfont chanbejing"
            src="../assets/img/alltitle/balkiny.png"
            alt=""
          />
          <div class="jinjuecfire">
            <div class="scalbeead">{{ $t("login.food2") }}</div>
          </div>
        </div>
      </div>
      <div class="fensencondrow">
        <div class="manydingsome w30 wow animate fadeInUp delay-400ms">
          <img class="fenicon3" src="../assets/img/alltitle/3.png" alt="" />
          <img
            class="snalnfont tiemsave"
            src="../assets/img/alltitle/balkiny.png"
            alt=""
          />
          <div class="jinjuecfire">
            <div class="scalbeead">{{ $t("login.food3") }}</div>
          </div>
        </div>
        <div class="manydingsome w30 wow animate fadeInUp delay-600ms">
          <img class="fenicon4" src="../assets/img/alltitle/4.png" alt="" />
          <img
            class="snalnfont pasdkck"
            src="../assets/img/alltitle/balkiny.png"
            alt=""
          />
          <div class="jinjuecfire">
            <div class="scalbeead">{{ $t("login.food4") }}</div>
          </div>
        </div>
        <div class="manydingsome w40 wow animate fadeInUp delay-800ms">
          <img class="fenicon5" src="../assets/img/alltitle/5.png" alt="" />
          <img
            class="snalnfont chanbejing"
            src="../assets/img/alltitle/balkiny.png"
            alt=""
          />
          <div class="jinjuecfire">
            <div class="scalbeead">{{ $t("login.food5") }}</div>
          </div>
        </div>
      </div>
      <div
        class="cookbooks wow animate fadeInUp delay-800ms"
        @click="gonewpeapel"
      >
        <img src="../assets/img/alltitle/rowarrow.png" alt="" />
        <span> {{ $t("login.allting") }}</span>
      </div>
    </div> -->

    <!-- 般菜单 -->
    <div class="allcontainer1 wow animate fadeInUp">
      <div class="centerwidth1 wow animate fadeInUp">
        <div
          class="firstwird1 firstwirdtwo1 wow animate fadeInUp"
          v-if="thirdlist.length != 0"
        >
          <div class="firstcontent1 wow animate fadeInUp">
            {{ $t("login.gourmet") }}
          </div>
          <div class="secondcontent1 wow animate fadeInUp delay-200ms">
            <div class="context1">
              {{ $t("login.cokintmast") }}
            </div>
            <!-- <div class="conarrow">
            <div class="conall">{{ $t("login.allting") }}</div>
            <img class="likearrow" src="../assets/img/alltitle/rowarrow.png" />
          </div> -->
          </div>
        </div>
        <div class="theretext1">
          <div
            class="newspaper1 newspapertwo1 wow animate fadeInUp delay-300ms"
            v-for="item in thirdlist"
            :key="item.id"
            @click="tomenudetail(item.id)"
          >
            <div class="paperinner1">
              <div class="">
                <!-- <img
                  class="twoimgscopy twoimgscopyTWO"
                  src="../assets/img/alltitle/cp-01.jpg"
                /> -->
                <img class="twoimgscopy1 twoimgscopyTWO1" :src="item.img" />
              </div>
              <div class="lastfont1" style="height: 45px">
                <!-- THE PERFECT ENCOUNTER OF CHEESE AND EGGPLANT -->
                {{ item.title }}
              </div>
              <div class="lasttext1">
                <!-- Fangtai Culture Research Institute is a non-profit pate nental
                organization, dissemin ... -->
                {{ item.intro }}
              </div>
              <div class="keoutdoor1">
                <div class="learnmore1 learnmoretwo1">
                  {{ $t("login.mostdetail") }}
                </div>
              </div>
            </div>
          </div>
          <div class="othernews1" v-if="thirdlist.length < 4"></div>

          <!-- <div class="antohwidth"></div> -->
        </div>
      </div>
    </div>
    <!-- 般菜单 -->

    <div class="retained">
      <div class="captial wow animate fadeInUp">{{ $t("login.newsersh") }}</div>
      <div class="doyouwant wow animate fadeInUp delay-200ms">
        {{ $t("login.newkonw") }}
      </div>
      <div class="modellcont wow animate fadeInUp delay-400ms">
        <div class="prisun">
          <div class="selfpro">{{ $t("login.xielipro") }}</div>
          <el-select
            class="jj"
            v-model="value"
            :placeholder="placeholder1"
            @change="rangehood"
            :class="faooult1 ? '' : 'fackending'"
            @blur="mobei1"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>

        <div class="prisun">
          <div class="selfpro">{{ $t("login.xingnum") }}</div>
          <el-select
            class="jj"
            v-model="valuetwo"
            :placeholder="placeholder2"
            @focus="thirsty"
            :class="faooult2 ? '' : 'fackending'"
            @blur="mobei2"
          >
            <el-option
              v-for="item in optionstwo"
              :key="item.value"
              :label="item.code"
              :value="item.code"
            >
            </el-option>
          </el-select>
        </div>

        <div class="prisun">
          <div class="selfpro">{{ $t("login.conttype") }}</div>
          <input
            class="phone"
            type="text"
            name=""
            id=""
            :placeholder="placeholder3"
            :class="faooult3 ? '' : 'fackending'"
            v-model="mobilandemail"
            @blur="mobei3"
          />
        </div>

        <div class="prisun">
          <div class="selfpro" style="visibility: hidden">weew</div>
          <div class="subint" @click="submithome">
            {{ $t("login.submitsing") }}
          </div>
        </div>
      </div>
    </div>

    <!-- <bomode /> -->
  </div>
</template>

<script>
import { WOW } from "wowjs";
import {
  getlunbo,
  gethomepro,
  gethot,
  getnews,
  customerform,
  getreccookbooklist,
  dsshing,
  getseries,
  getseriesgoods,
} from "../request/api";
import { eventbut } from "../utils/bus";
// import bomode from "../components/bottommode";
// import tophead from "../components/topmode";
// import { WOW } from "wowjs";

export default {
  props: ["cases"],
  // components: {
  //   bomode,
  //   tophead,
  // },
  data() {
    return {
      faooult1: true,
      faooult2: true,
      faooult3: true,
      blackchange: false,
      abblack: false,
      isFlag: true,
      january: "",
      // logoimgs:["",]
      list_img: [
        // { url: require("../assets/img/alltitle/banner01.jpg") },
        // { url: require("../assets/img/alltitle/banner01.jpg") },
        // { url: require("../assets/img/alltitle/banner01.jpg") },
        // { url: require("../assets/img/alltitle/banner01.jpg") },
        //       { url: require("../assets/img/alltitle/banner01.jpg") },
        // { url: require("../assets/img/alltitle/banner01.jpg") },
        // { url: require("../assets/img/alltitle/logolun.png") },
      ],
      togetserimg: [],
      hotspot: [],
      manynews: [],
      twolunimg: [
        { url: require("../assets/img/alltitle/mother.jpg"), text: "erterlkj" },
        { url: require("../assets/img/alltitle/mother-1.jpg") },
        { url: require("../assets/img/alltitle/mother-2.jpg") },
      ],
      list: [{ tt: "jdj" }],

      optionstwo: [],
      value: "",
      valuetwo: "",
      torightchangelunbo: 1,
      desk: 1,
      mobilandemail: "",
      thirdlist: [],
    };
  },
  created() {
    if (document.documentElement.scrollTop > 0) {
      document.documentElement.scrollTop = 0;
    }
    window.addEventListener("scroll", this.handleScroll);
    this.$nextTick(() => {
      // 在dom渲染完后,再执行动画
      const wow = new WOW({
        offset: 0,
        // mobile: true,
        live: true,
      });
      wow.init();
    });
    this.$route.meta.title = this.$t("login.querythere1");
    this.$router.replace({
      query: {
        temp: Date.now(), //必有
      },
    });
    this.$bus.$on("add", (message, b, d) => {
      console.log(message);
      console.log(b);
      this.getbanner(message, b);
      this.getSeriesList(message, b);
      this.gethotpro(message, b);
      this.geteverynew(message, b);
      this.outreccookbooklist(message, b);
      this.outgetseries(message, b);
      this.$route.meta.title = this.$t("login.querythere1");
      this.$router.replace({
        query: {
          temp: Date.now(), //必有
        },
      });
    });

    if (sessionStorage.getItem("newlangusge") == null) {
      this.getbanner("au", "en");
      this.getSeriesList("au", "en");
      this.gethotpro("au", "en");
      this.geteverynew("au", "en");
      this.outreccookbooklist("au", "en", "d");
    } else {
      this.getbanner("au", sessionStorage.getItem("newlangusge"));
      this.getSeriesList("au", sessionStorage.getItem("newlangusge"));
      this.gethotpro("au", sessionStorage.getItem("newlangusge"));
      this.geteverynew("au", sessionStorage.getItem("newlangusge"));
      this.outreccookbooklist("au", sessionStorage.getItem("newlangusge"), "d");
    }
  },
  mounted() {
    window.addEventListener("mousedown", this.september);
    this.septembertwo();

    if (document.documentElement.scrollTop > 0) {
      document.documentElement.scrollTop = 0;
    }
    window.addEventListener("scroll", this.handleScroll);
    this.$nextTick(() => {
      // 在dom渲染完后,再执行动画
      const wow = new WOW({
        offset: 0,
        // mobile: true,
        live: true,
      });
      wow.init();
    });
  },
  beforeCreate() {},

  beforeDestroy() {
    // 实例销毁之前对点击事件进行解绑
    window.removeEventListener("mousedown", this.september);
  },
  methods: {
    toirfrom(a, b, c, d, e) {
      if (b == 1) {
        this.$router.push({
          path: "/otherwebsite",
          query: { type: d, name: e, cate: c },
        });
      } else if (b == 2) {
        if (a != "") {
          var url = a;
          window.open(url, "_blank");
        }
      }
    },
    outgetseries(a, b, c) {
      getseriesgoods({
        lang: b,
        country: a,
        cate: this.january,
        type: 0,
      }).then((res) => {
        console.log(res);
        this.optionstwo = res.data;
      });
    },
    tomenudetail(a) {
      console.log(111111);
      this.$router.push({ path: "/detailstomenu", query: { id: a } });
    },
    outreccookbooklist(a, b, c) {
      dsshing({
        lang: b,
        country: a,
      }).then((res) => {
        console.log(res);
        this.thirdlist = res.data;
      });
    },
    gonewpeapel() {
      this.$router.push({ path: "/alltitleandall" });
    },
    gowithe(e) {
      console.log(e);
      this.$router.push({ path: "/productdetail", query: { id: e } });
    },
    tohappiness() {
      this.$router.push({ path: "/informationcopy" });
    },
    tolovesmile(e) {
      console.log(e);
      this.$router.push({ path: "/productlist", query: { id: e } });
    },
    todetail(a) {
      this.$router.push({ path: "/details", query: { id: a } });
    },
    geteverynew(a, b) {
      getnews({
        lang: b,
        country: a,
      }).then((res) => {
        console.log(res);
        this.manynews = res.data;
      });
    },
    gethotpro(a, b) {
      gethot({
        lang: b,
        country: a,
      }).then((res) => {
        console.log(res);
        this.hotspot = res.data;
        console.log(this.hotspot);
      });
    },
    getSeriesList(a, b) {
      gethomepro({
        type: "1",
        lang: b,
        country: a,
      }).then((res) => {
        console.log(res);
        this.togetserimg = res.data;
      });
    },
    getbanner(a, b) {
      getlunbo({
        type: "1",
        lang: b,
        country: a,
      }).then((res) => {
        console.log(res);
        this.list_img = res.data;
        console.log(this.list_img);
      });
    },
    mobei1() {
      this.faooult1 = true;
    },
    mobei2() {
      this.faooult2 = true;
    },
    mobei3() {
      this.faooult3 = true;
    },
    submithome() {
      console.log(1111);
      if (this.value == "") {
        this.faooult1 = false;
        this.$message({
          message: this.$t("login.borderred"),
          type: "error",
          offset: 90,
        });
      } else if (this.valuetwo == "") {
        this.faooult2 = false;

        this.$message({
          message: this.$t("login.borderred"),
          type: "error",
          offset: 90,
        });
      } else if (this.mobilandemail == "") {
        this.faooult3 = false;

        this.$message({
          message: this.$t("login.borderred"),
          type: "error",
          offset: 90,
        });
      } else {
        if (sessionStorage.getItem("newlangusge") == null) {
          this.makedo("en");
        } else {
          this.makedo(sessionStorage.getItem("newlangusge"));
        }
      }
    },
    makedo(a) {
      customerform({
        lang: a,
        country: "au",
        cate_name: this.value,
        series_name: this.valuetwo,
        contact: this.mobilandemail,
      }).then((res) => {
        console.log(res);
        this.value = "";
        this.valuetwo = "";
        this.mobilandemail = "";
        this.$message({
          message: this.$t("login.sucssfil"),
          type: "success",
          offset: 90,
        });
      });
    },
    rangehood(e) {
      console.log(e);
      this.january = e;
      this.valuetwo = "";
    },
    thirsty() {
      console.log(111111111111);
      if (sessionStorage.getItem("newlangusge") == null) {
        this.outgetseries("au", "en", "d");
      } else {
        this.outgetseries("au", sessionStorage.getItem("newlangusge"));
      }
    },
    handleScroll() {
      // console.log(9999999999999999999);
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop > 2700) {
        if (this.isFlag) {
          this.isFlag = false;
          this.$refs.myNum1.start();
          this.$refs.myNum2.start();
          this.$refs.myNum3.start();
          this.$refs.myNum4.start();
        }
      }
    },
    september() {
      var cheng = document.getElementById("showlove");
      if (cheng.scrollLeft < 180) {
        this.blackchange = true;
      } else {
        this.blackchange = false;
      }
    },
    septembertwo() {
      var lishe = document.getElementById("showbrother");
      if (lishe.scrollLeft < 180) {
        this.abblack = false;
      } else {
        this.abblack = true;
      }
    },
    hengscrool() {
      console.log(222222);
      document.getElementById("showlove").scrollLeft = 180;
      if (document.getElementById("showlove").scrollLeft > 180) {
        document.getElementById("showlove").scrollLeft = 180;
        if ((document.getElementById("showlove").scrollLeft = 180)) {
          this.torightchangelunbo = 1;
        }
      }
      document.getElementById("showbrother").scrollLeft = 180;
      if (document.getElementById("showbrother").scrollLeft > 180) {
        document.getElementById("showbrother").scrollLeft = 180;
        if ((document.getElementById("showbrother").scrollLeft = 180)) {
          this.desk = 1;
        }
      }
    },
    moverleft() {
      console.log(11111);
      this.torightchangelunbo--;
      if (this.torightchangelunbo == 3) {
        document.getElementById("showlove").scrollLeft = 1020;
      } else if (this.torightchangelunbo == 2) {
        document.getElementById("showlove").scrollLeft = 620;
      } else if (this.torightchangelunbo == 1) {
        document.getElementById("showlove").scrollLeft = 180;
      } else if (this.torightchangelunbo == 0) {
        this.torightchangelunbo = 1;
        // this.$message("It is the last one");
        console.log(this.torightchangelunbo);
      }
    },
    moveright() {
      this.torightchangelunbo++;
      console.log(this.torightchangelunbo);
      if (this.torightchangelunbo == 2) {
        document.getElementById("showlove").scrollLeft = 620;
      } else if (this.torightchangelunbo == 3) {
        document.getElementById("showlove").scrollLeft = 1020;
      } else if (this.torightchangelunbo == 4) {
        this.torightchangelunbo = 3;
        // this.$message("It is the last one");
        console.log(this.torightchangelunbo);
      }
    },
    shirtinleft() {
      console.log(11111);
      this.desk--;
      if (this.desk == 3) {
        document.getElementById("showbrother").scrollLeft = 1020;
      } else if (this.desk == 2) {
        document.getElementById("showbrother").scrollLeft = 620;
      } else if (this.desk == 1) {
        document.getElementById("showbrother").scrollLeft = 180;
      } else if (this.desk == 0) {
        this.desk = 1;
        // this.$message("It is the last one");
        console.log(this.desk);
      }
    },
    shirtinright() {
      console.log(11111);
      this.desk++;
      console.log(this.desk);
      if (this.desk == 2) {
        document.getElementById("showbrother").scrollLeft = 620;
      } else if (this.desk == 3) {
        document.getElementById("showbrother").scrollLeft = 1020;
      } else if (this.desk == 4) {
        this.desk = 3;
        // this.$message("It is the last one");
        console.log(this.desk);
      }
    },
  },
  computed: {
    options: function () {
      return [
        {
          value: "1",
          label: this.$t("login.rangehoods"),
        },
        {
          value: "2",
          label: this.$t("login.hobs"),
        },
        {
          value: "3",
          label: this.$t("login.lectricovens"),
        },
        {
          value: "4",
          label: this.$t("login.steamovens"),
        },
        {
          value: "5",
          label: this.$t("login.micovens"),
        },
        {
          value: "6",
          label: this.$t("login.dishwasher"),
        },
      ];
    },

    placeholder1() {
      return this.$t("login.ochoosepro");
    },
    placeholder2() {
      return this.$t("login.ochoosexinghoa");
    },
    placeholder3() {
      return this.$t("login.conttype");
    },
  },
};
</script>
<style lang='less' scoped>
body {
  overflow-x: hidden;
}
/deep/ .el-input__inner {
  border: 0;
}
/* .fangtaihead{position: fixed; left:0; top:0; width:100%;} */
.phone {
  border: 0;
  height: 40px;
  line-height: 40px;
  text-indent: 12px;
  color: #000;
  font-size: 13px;
  background: #f7f7f7;
  border-radius: 8px;
  width: 300px;
  outline: none;
}
.home {
  .allmosting {
    height: 100%;
    width: 100%;
    // height: 873px;
    height: 90vh;
    background-repeat: no-repeat;
    /* background-position: center center; */
    overflow: hidden;
    // background-image: url(../assets/img/alltitle/homebanner3.jpg);
    background-position: center;
    background-size: cover;
  }
  .backimginner {
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    /* background-position: center center; */
    overflow: hidden;
    background-image: url(../assets/img/alltitle/homebanner2.jpg);
    background-position: top center;
    background-size: cover;
  }
  .banner3 {
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    /* background-position: center center; */
    overflow: hidden;
    background-image: url(../assets/img/alltitle/homebanner1.jpg);
    background-position: top center;
    background-size: cover;
  }
  .banner4 {
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    /* background-position: center center; */
    overflow: hidden;
    background-image: url(../assets/img/alltitle/homebanner4.jpg);
    background-position: top center;
    background-size: cover;
  }
  width: 100%;
  overflow: hidden;
  .headerimg {
    // width: 100%;
    // height: 1080px;
    // // position: relative;
    // background-size: cover;

    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    /* background-position: center center; */
    overflow: hidden;
    background-image: url(../assets/img/alltitle/ba21.jpg);
    background-position: top center;
    background-size: cover;
  }
  .happinesses {
    position: absolute;
    top: 240px;
    left: 10%;
    width: 804px;
    // height: 200px;
    // background-color: red;
    display: flex;
    flex-direction: column;
    .startsiin {
      font-size: 30px;
      /* font-family: Arial Black; */
      font-weight: bold;
      color: #ffffff;
      line-height: 60px;
      opacity: 0.75;
    }
    .commitekit {
      font-size: 14px;
      margin-top: 10px;
      /* font-family: Arial; */
      font-weight: 400;
      color: #ffffff;
      line-height: 24px;
      opacity: 0.75;
    }
    .proandmore {
      margin-top: 32px;
      display: flex;
      .morebeaytiful {
        /* box-shadow:0px 10px 16px 0px rgba(0,0,0, 0.3); */
        /* width: 84px; */
        height: 40px;
        background: #ab1a30;
        opacity: 0.82;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-size: 12px;
        /* font-family: Arial; */
        font-weight: bold;
        color: #ffffff;
        padding: 0 18px;
      }
      .butflys {
        /* box-shadow:0px 10px 16px 0px rgba(0,0,0, 0.3); */
        margin-left: 40px;
        /* width: 120px; */
        height: 40px;
        background: #000000;
        opacity: 0.6;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        /* font-family: Arial; */
        font-weight: bold;
        color: #ffffff;
        cursor: pointer;
        padding: 0 18px;
      }
    }
  }
  .scdishwa {
    margin-top: 110px;
  }
  .centerlikeresion {
    margin: 0 auto;
    display: flex;
    margin-top: 33px;
    // flex-wrap: nowrap;
    // overflow: auto;
    // width: 1200px;
    overflow-x: scroll;
    /* margin-bottom: 50px; */
    padding-bottom: 33px;
    /* margin-left: 157px; */
    /* width: 80%; */
    padding-left: 120px;
    .everyimg {
      position: relative;
      .picBox {
        position: relative;
        border-radius: 7px;
        box-shadow: 6px 6px 14px 0px rgba(0, 0, 0, 0.2);
        overflow: hidden;
        margin-right: 50px;
        width: 300px;
        .yy {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
        }
        .dioenonn {
          width: 100%;
          float: left;
          /* height: 500px; */
        }
      }
      .dignbegin {
        position: absolute;
        bottom: 38px;
        left: 38px;
        // width: 100px;
        // height: 100px;
        // border-color: black;

        .mounthsnose {
          margin-top: 14px;
          display: flex;
          cursor: pointer;

          span {
            color: white;
            font-size: 13px;
            font-weight: 400;
          }
          img {
            width: 10px;
            height: 10px;
            margin-left: 10px;
            margin-top: 4px;
          }
        }
      }
      .handdown {
        color: white;
        font-size: 14px;
        position: absolute;
        bottom: 38px;
        right: 76px;
        cursor: pointer;
        a {
          color: white;
        }
      }
    }
    .everyimg:first-child {
      /* margin-left: 170px; */
    }
  }
  .worrydnew {
    // width: 100%;
    /* height: 900px; */
    background-color: #f2f2f2;
    // padding-bottom: 200px;
    padding-top: 100px;
    width: 88%;
    margin: 0 auto;
  }
  .twicechange {
    display: flex;
    // height: 850px;
    overflow: hidden;
    // padding-top: 46px;
    margin: 0 auto;
    .twoceleft {
      /* width: 960px; */
      width: 50%;
      height: 90vh;
      background: url(../assets/img/alltitle/mother-1.jpg) no-repeat center
        center;
      background-size: cover;
      // margin-left: 118px;

      .Rotation {
        display: flex;
        .twoheadimg {
          width: 880px;
          // width: 50%;
          height: 880px;
        }
      }
    }
    .motherright {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .motherinner {
        /* margin: 280px 117px; */
        // padding: 180px 0 0 0;
        width: 70%;
        margin: 0 auto;

        // display: flex;
        // justify-content: center;
        // align-items: center;
        .mothersonone {
          /* width: 692px; */
          /* height: 108px; */
          font-size: 28px;
          font-family: Arial Black;
          font-weight: 900;
          color: #010101;
          line-height: 38px;
          margin-bottom: 32px;
          text-transform: uppercase;
        }
        .mothersontwo {
          margin-top: 10px;
          /* width: 709px; */
          /* height: 197px; */
          font-size: 14px;
          /* font-family: Arial; */
          font-weight: 400;
          color: #000000;
          line-height: 24px;
        }
        .mothersonthere {
          margin-top: 35px;
          width: 110px;
          height: 34px;
          border: 1px solid #010101;
          cursor: pointer;
          /* opacity: 0.82; */
          border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: url(../assets/img/alltitle/littlein.png) no-repeat 75% 50%;
          background-size: 12px;
          span {
            font-size: 12px;
            /* font-family: Arial; */
            /* font-weight: bold; */
            color: #000000;
            margin-right: 20px;
          }
          img {
            margin-left: 30px;
            width: 24px;
            height: 24px;
          }
        }
        .mothersonthere:hover {
          border: 1px solid #9e1d22;
          background: url(../assets/img/alltitle/whiterow.png) no-repeat 80% 50%;
          background-size: 12px;
          background-color: #9e1d22;
          span {
            color: white;
          }
        }
      }
    }
  }
  .newaddfateher {
    // background: #f5f5f5;
    overflow: hidden;
    margin: 0 auto;

    .newsonsing {
      margin: 0 auto;
      width: 88%;
      // padding-left: 120px;
      // padding-right: 120px;
      margin-top: 52px;
      .debughma {
        width: 700px;
        font-size: 28px;
        line-height: 36px;
        font-family: Arial Black;
        font-weight: 900;
        color: #000000;
        text-transform: uppercase;
      }
      .fnetwite {
        width: 700px;
        font-size: 15px;
        /* font-family: Arial; */
        font-weight: 400;
        color: #000;
        margin-top: 16px;
      }
    }
  }
  .chouyousmoke {
    padding: 52px 0px 52px 0px;
    display: flex;
    width: 90%;
    margin: 0 auto;
    justify-content: space-evenly;
    .smokeson {
      display: flex;
      flex-direction: column;
      // width: 25%;
      width: 25vw;

      align-items: center;
      // margin: 0 auto;

      .waterwell {
        width: 90%;
        // width: 467px;
        border-radius: 8px;
        /* height: 540px; */
      }
      .watercentertext {
        width: 90%;
        .jzyson {
          margin-top: 9px;
          font-size: 20px;
          /* font-family: Arial; */
          font-weight: 900;
          color: #000000;
          line-height: 60px;
        }
        .fotileson {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          margin-top: 0px;
          font-size: 14px;
          /* font-family: Arial; */
          font-weight: 400;
          color: #000000;
          line-height: 20px;
          height: 44px;
        }
      }
      .moreson {
        margin-top: 20px;
        width: 110px;
        height: 34px;
        border: 1px solid #010101;
        cursor: pointer;

        /* opacity: 0.82; */
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: url(../assets/img/alltitle/littlein.png) no-repeat 75% 50%;
        background-size: 12px;
        span {
          font-size: 12px;
          /* font-family: Arial; */
          /* font-weight: bold; */
          color: #000000;
          margin-right: 20px;
        }
        .moteimm {
          margin-left: 35px;
          width: 23px;
          height: 15px;
        }
      }
      .moreson:hover {
        border: 1px solid #9e1d22;
        background: url(../assets/img/alltitle/whiterow.png) no-repeat 80% 50%;
        background-size: 12px;
        background-color: #9e1d22;
        span {
          color: white;
        }
      }
    }
  }

  .blackwhtch {
    background: #f5f5f5;
    /* padding-top: 60px; */
    overflow: hidden;
    .tablebalck {
      position: relative;
      .bigbalimm {
        width: 100%;
        height: 880px;
      }
      .bigisfather {
        height: 880px;

        width: 100%;
        background-repeat: no-repeat;
        background-position: center center;
        overflow: hidden;
        background-image: url(../assets/img/alltitle/balkwatch.png);
        background-position: center center;
      }
      .bottson {
        position: absolute;
        width: 100%;
        height: 880px;
        // background-color: red;
        top: 0px;
        left: 0px;
        z-index: 9;
        display: flex;
        justify-content: space-around;

        margin: 0 auto;
        .foursonfirst {
          display: flex;
          flex-direction: column;
          width: 25%;
          height: 880px;

          .foursoninner {
            margin: auto 15%;
            display: flex;
            width: 70%;

            flex-direction: column;
            .eightadd {
              .changenumbers {
                width: 100%;
                font-size: 50px;
                font-family: Helvetica Neue LT Pro;
                font-weight: 400;
                color: #ffffff;
                line-height: 48px;
                text-align: left;
                opacity: 0.95;
                margin-left: -5px;
              }
              span {
                font-size: 30px;
                vertical-align: text-top;
                margin-left: 10px;
                color: white;
              }
            }
            .fotilein {
              margin-top: 30px;
              /* width: 320px; */
              width: 100%;
              /* height: 125px; */
              font-size: 14px;
              /* font-family: Arial; */
              font-weight: 300;
              color: #ffffff;
              line-height: 24px;
              opacity: 0.75;
              overflow: hidden;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;
            }
            .insists {
              margin-top: 40px;
              width: 90px;
              height: 34px;
              border: 1px solid #ffffff;
              cursor: pointer;
              opacity: 0.68;
              /* opacity: 0.82; */
              border-radius: 3px;
              display: flex;
              justify-content: center;
              align-items: center;
              background: url(../assets/img/alltitle/whiterow.png) no-repeat 75%
                50%;
              background-size: 12px;

              span {
                font-size: 12px;
                /* font-family: Arial; */
                /* font-weight: bold; */
                color: #ffffff;
                margin-right: 20px;
              }
              img {
                width: 23px;
                height: 15px;
                margin-left: 20px;
                margin-right: 20px;
              }
            }
            .insists:hover {
              border: none;
              border: 1px solid #9e1d22;
              background: url(../assets/img/alltitle/whiterow.png) no-repeat 80%
                50%;
              background-size: 12px;
              background-color: #9e1d22;
              span {
                color: white;
              }
            }
          }
        }
        .sizhiehjhei {
          opacity: 0.5;
        }
        // .shoppcarting {
        //   height: 880px;
        //   width: 100%;
        //   background-repeat: no-repeat;
        //   background-position: center center;
        //   overflow: hidden;
        //   background-image: url(../assets/img/alltitle/lingray.png);
        //   background-position: center center;
        // }
      }
    }
  }

  .imagquns {
    padding-top: 50px;
    background: #f5f5f5;
    display: flex;
    flex-direction: column;
    // margin-top: 260px;
    overflow: hidden;
    .fenfirstrow {
      /* display: flex; */

      .manydingsome {
        cursor: pointer;
        position: relative;
        // width: 30%;
        overflow: hidden;

        float: left;
        .fenicon1 {
          width: 100%;
          float: left;
          /* height: 570px; */
          transition: all 2s;
        }
        .fenicon1:hover {
          transform: scale(1.1);
        }
        .snalnfont {
          position: absolute;
          width: 100%;
          /* height: 214px; */
          bottom: 0px;
          left: 0px;
        }
        .chanbejing {
          /* width: 900px !important; */
        }
        .jinjuecfire {
          position: absolute;
          bottom: 43px;
          left: 43px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .scalbeead {
            font-size: 24px;
            /* font-family: Arial Black; */
            font-weight: 900;
            color: #ffffff;
            // line-height: 50px;
          }
          .etioning {
            /* width: 544px; */
            width: 80%;
            font-size: 14px;
            /* font-family: Arial; */
            font-weight: 400;
            color: #ffffff;
            line-height: 22px;
            opacity: 0.75;
            margin-top: 5px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
          }
        }
      }
      .w66 {
        width: 60.41%;
      }
      .w34 {
        width: 39.58%;
      }
      .w30 {
        width: 33.3%;
      }
      .w40 {
        width: 33.3%;
      }
      .fenicon2 {
        width: 100%;
        float: left;
        /* width: 900px;
        height: 570px; */
        transition: all 2s;
        overflow: hidden;
      }
      .fenicon2:hover {
        transform: scale(1.1);
        overflow: hidden;
      }
    }

    .fensencondrow {
      // position: relative;
      /* display: flex; */
      .manydingsome {
        cursor: pointer;
        position: relative;
        overflow: hidden;
        float: left;
        .fenicon1 {
          /* width: 1162px; */
          /* height: 570px; */
        }
        .snalnfont {
          position: absolute;
          /* width: 1162px; */

          height: 214px;
          bottom: 0px;
          left: 0px;
        }
        .chanbejing {
          width: 900px !important;
        }
        .pasdkck {
          width: 640px;
        }
        .tiemsave {
          width: 640px;
        }
        .jinjuecfire {
          position: absolute;
          bottom: 28px;
          left: 36px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .scalbeead {
            font-size: 24px;
            // width: 80%;
            /* font-family: Arial Black; */
            font-weight: 900;
            color: #ffffff;
            line-height: 40px;
          }
          .etioning {
            /* width: 544px; */
            font-size: 14px;
            /* font-family: Arial; */
            font-weight: 400;
            color: #ffffff;
            line-height: 21px;
            margin-top: 5px;
            opacity: 0.75;
            width: 80%;
            display: -webkit-box;

            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
          }
          .etioningtower {
            /* width: 482px; */
          }
          .etioningtCHE {
            /* width: 543px; */
          }
        }
      }
      .w66 {
        width: 66%;
      }
      .w34 {
        width: 34%;
      }
      .w30 {
        width: 33.3%;
      }
      .w40 {
        width: 33.3%;
      }
      .fenicon3 {
        width: 100%;
        float: left;
        /* width: 581px;
        height: 100%; */
        transition: all 2s;
      }
      .fenicon3:hover {
        transform: scale(1.1);
        overflow: hidden;
      }
      .fenicon4 {
        width: 100%;
        float: left;
        /* width: 582px;
        height: 100%; */
        transition: all 2s;
      }
      .fenicon4:hover {
        transform: scale(1.1);
        overflow: hidden;
      }
      .fenicon5 {
        width: 100%;
        float: left;
        /* width: 900px;
        height: 100%; */
        transition: all 2s;
      }
      .fenicon5:hover {
        transform: scale(1.1);
        overflow: hidden;
      }
    }
    .cookbooks {
      background: #ffffff;
      display: flex;
      padding-right: 107px;
      flex-direction: row-reverse;
      // justify-content: center;
      align-items: center;
      padding-top: 20px;
      padding-bottom: 20px;
      span {
        font-size: 14px;
        /* font-family: Arial; */
        font-weight: 400;
        color: #000000;
        line-height: 32px;
        cursor: pointer;
      }
      img {
        vertical-align: middle;
        margin-left: 12px;
        width: 14px;
        /* height: 24px; */
        cursor: pointer;
      }
    }
  }

  .retained {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 647px;
    background-color: #f2f2f2;
    .captial {
      width: 600px;
      height: 32px;
      font-size: 30px;
      font-family: Arial Black;
      font-weight: 900;
      color: #000000;
      text-transform: uppercase;
      text-align: center;
    }
    .doyouwant {
      margin-top: 30px;
      width: 800px;
      height: 44px;
      font-size: 16px;
      /* font-family: Arial; */
      font-weight: 400;
      color: #000000;
      line-height: 30px;
      opacity: 0.75;
      /* text-transform: uppercase; */
      text-align: center;
    }
    .modellcont {
      width: 1000px;
      min-width: 1000px;
      margin-top: 65px;
      height: 196px;
      background-color: #ffffff;
      padding: 0 100px;
      border-radius: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      justify-content: space-around;
      .prisun {
        .selfpro {
          margin-bottom: 8px;
          padding-left: 15px;

          font-size: 13px;
          /* font-family: Arial; */
          font-weight: 400;
          color: #757474;
          line-height: 30px;
        }
        /deep/.el-input__inner {
          background-color: #f7f7f7;
        }
        /deep/.el-input__inner::placeholder {
          color: #bdbcbc;
        }
        /deep/.phone::placeholder {
          color: #bdbcbc;
        }
        /deep/ .el-select__caret {
          -webkit-transform: none;
          transform: none;
        }

        /deep/ .el-icon-arrow-up:before {
          content: "\e790";
        }
      }
      .subint {
        width: 140px;
        height: 40px;
        cursor: pointer;
        background: #3a3737;
        box-shadow: 5px 8px 16px 0px rgba(8, 1, 3, 0.13);
        border-radius: 8px 8px 8px 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        /* font-family: Arial; */
        font-weight: bold;
        color: #faf8f8;
        line-height: 40px;
        transition: all 0.3s;
      }
      .subint:hover {
        background: #000;
      }
    }
  }
  .centerwidth {
    /* width: 80%; */
    margin: 0 auto;
    /* margin-left: 157px; */
    padding-left: 120px;
    padding-right: 120px;
  }
  .firstwird {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 52px;
  }

  .firstcontent {
    width: 700px;
    font-size: 28px;
    line-height: 36px;
    font-family: Arial Black;
    font-weight: 900;
    color: #000000;
    text-transform: uppercase;
  }

  .secondcontent {
    display: flex;
    /*background-color: red;*/
    justify-content: space-between;
    margin-top: 16px;
  }

  .context {
    width: 700px;
    font-size: 15px;
    /* font-family: Arial; */
    font-weight: 400;
    color: #000;
    // line-height: 24px;
    /* text-transform: uppercase; */
    /* font-weight: bold; */
    /* opacity: 0.85; */
  }
  .conall {
    // vertical-align: middle;
    font-size: 14px;
    /* font-family: Arial; */
    font-weight: 400;
    color: #000000;
    // line-height: 48px;
  }
  .likearrowleft {
    margin-right: 12px;
    width: 20px;
  }
  .likearrow {
    margin-left: 11px;
    // vertical-align: middle;
    width: 12px;
    margin-top: 3px;
    height: 10px;
  }

  .conarrow {
    display: flex;
    justify-content: center;
    // align-items: center;
    cursor: pointer;
  }

  .conarrow:hover {
    .conall {
      color: #9d2736;
    }
    .likearrow {
    }
  }

  .newspaper {
    width: 386px;
    /* height: 300px; */
    background: #ffffff;
    border-radius: 7px;
    cursor: pointer;
    transition: all 0.8s;
  }
  .newspaper:hover {
    /* transform: translate(0, -5px); */
    box-shadow: 3px 5px 12px 0px rgba(8, 1, 3, 0.08);
  }
  .newspaper:first-child {
    /* margin-left: 180px; */
  }
  .paperinner {
    margin: 18px 24px;
  }
  .paperhead {
    display: flex;
    /* width: 315px; */
    align-items: center;
    border-bottom: 1px solid #f2f2f2;
    padding-bottom: 12px;
  }

  .papertime {
    font-size: 12px;
    font-family: Helvetica Neue LT Pro;
    font-weight: normal;
    color: #474545;
    opacity: 0.35;
  }

  .paperfont {
    margin-left: 19px;
    font-size: 12px;
    margin-top: 2px;
    /* font-family: Helvetica Neue LT Pro; */
    font-weight: normal;
    color: #474545;
    opacity: 0.35;
    text-align: center;
  }
  .fontbig {
    margin-top: 15px;
    /* margin-bottom: 8px; */
    /*display: flex;*/
    font-size: 15px;
    /* font-family: Arial; */
    font-weight: bold;
    color: #3c3c3c;
    line-height: 20px;
    height: 40px;
    word-break: break-all;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-transform: uppercase;
  }

  .imgandfont {
    display: flex;
    margin-top: 15px;
  }

  .leftimgs {
    width: 82px;
    height: 66px;
    border-radius: 6px;
  }

  .rightfont {
    width: 212px;
    font-size: 12px;
    line-height: 17px;
    /*    font-family: Arial;
    font-weight: 400; */
    color: #898989;
    opacity: 0.75;
    margin-left: 16px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
  }

  .keoutdoor {
    display: flex;
    /* width: 315px; */
    justify-content: flex-end;
    /* padding-bottom: 20px; */
  }

  .learnmore {
    /* width: 65px; */
    margin-top: 12px;
    padding-bottom: 5px;
    font-size: 12px;
    /* font-family: Arial; */
    /* font-weight: 400; */
    color: #898989;
    line-height: 13px;
    /* border-bottom: 1px solid #AB1A30; */
    text-align: right;
  }

  .learnmore::after {
    content: "";
    display: block;
    background-color: #9e2b29;
    // width: 65px;
    height: 1px;
    margin-top: 4px;
    /* position: absolute; */
    font-size: 12px;
    transition: all 0.6s ease 0s;
  }

  .learnmore:hover::after {
    content: "";
    display: block;
    margin-top: 4px;
    background-color: #9e2b29;
    width: 0px;
    height: 1px;
    font-size: 12px;
    transition: all 0.6s ease 0s;
  }

  .learnmore:hover {
    color: #9e2b29;
  }
  .mr36 {
    margin-right: 36px;
  }
}

/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
.centerlikeresion::-webkit-scrollbar {
  width: 1px;
  height: 8px;
  background-color: #cccccc;
}

/*定义滚动条轨道 内阴影+圆角*/
.centerlikeresion::-webkit-scrollbar-track {
  // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  /* border-radius: 10px; */
  background-color: #ffffff;
}

/*定义滑块 内阴影+圆角*/
.centerlikeresion::-webkit-scrollbar-thumb {
  border-radius: 0px;
  // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  background-color: #ebebeb;
}

/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
.lingshancheck::-webkit-scrollbar {
  width: 1px;
  height: 8px;
  background-color: #f2f2f2;
}

/*定义滚动条轨道 内阴影+圆角*/
.lingshancheck::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(36, 17, 17, 0.1); */
  /* border-radius: 10px; */
  background-color: #f2f2f2;
}

/*定义滑块 内阴影+圆角*/
.lingshancheck::-webkit-scrollbar-thumb {
  border-radius: 0px;
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2); */
  background-color: #ebebeb;
}
.toplunbo {
  // overflow: hidden;
  /deep/.el-carousel__indicators--horizontal {
    /* width: 50px; */
    height: 7px;
    // background-color: yellow;
  }
  /deep/ .el-carousel__indicator--horizontal {
    /* width: 6.7px !important; */
    height: 7px !important;
    padding: 0;
    margin: 0 3px;
    // background-color: purple;
  }
  /deep/.el-carousel__indicator {
    /* width: 6.7px; */
    height: 7px;
  }
  /deep/ .el-carousel__button {
    border-radius: 50px;
    width: 6.7px;
    height: 7px;
    background-color: #cecece;
  }
  /deep/ .is-active {
    // background-color: #9e2b29;
  }
}
/deep/ .el-carousel--horizontal {
  overflow: hidden;
}
/deep/ .el-carousel__indicators--horizontal {
  bottom: 30px;
}

/deep/ .toplunbo .el-carousel__button {
  opacity: 1;
}
/deep/
  .el-carousel__indicator.el-carousel__indicator--horizontal.is-active
  .el-carousel__button {
  background: #992025;
  width: 12px !important;
}
/deep/ .el-carousel__container {
  height: 400px;
}
.namevalcoll {
  position: relative;
  padding-bottom: 80px;
}
.namevalcoll2 {
  background-color: #f2f2f2;
  padding-bottom: 0px;
}
.zhegaiscrool {
  width: 118px;
  height: 50px;
  /* display: none; */
  z-index: 99;
  background-color: #ffffff;
  position: absolute;
  bottom: 60px;
  left: 0px;
}
.zhegaiscrooltwo {
  width: 118px;
  height: 50px;
  display: block;
  z-index: 9999999;
  background-color: #ffffff;
  position: absolute;
  bottom: 60px;
  left: 0px;
  /* opacity:0; */
}
.ansefa {
  width: 118px;
  height: 50px;
  /* display: none; */
  z-index: 9;
  background-color: #f2f2f2;
  position: absolute;
  bottom: 200px;
  left: 0px;
}
.ansefatwo {
  width: 118px;
  height: 50px;
  display: block;
  z-index: 9;
  background-color: #f2f2f2;
  position: absolute;
  bottom: 200px;
  left: 0px;
}
.fangtaihead {
  border: 0;
}
.allcontainer1 {
  width: 100%;
  background-color: #f2f2f2;
  .centerwidth1 {
    // width: 88%;
    margin: 0 auto;
    // display: flex;
    // justify-content: space-evenly;
  }
  .firstwird1 {
    // width: 1200px;
    display: flex;
    flex-direction: column;
    padding-top: 70px;
    width: 88%;
    margin: 0 auto;
  }
  .firstwirdtwo1 {
    padding-bottom: 70px;
  }
  .firstcontent1 {
    font-size: 28px;
    font-family: Arial Black;
    font-weight: 900;
    color: #000000;
    text-transform: uppercase;
  }
  .secondcontent1 {
    display: flex;
    /*background-color: red;*/
    justify-content: space-between;
    margin-top: 10px;
  }
  .context1 {
    font-size: 15px;
    /* font-family: Arial; */
    font-weight: 400;
    color: #000000;
    line-height: 24px;
    opacity: 0.85;
    width: 1010px;
  }
  .theretext1 {
    display: flex;
    margin-top: 42px;
    justify-content: space-between;
    width: 88%;
    margin: 0 auto;
  }
  .newspaper1 {
    // width: 386px;
    width: 20%;
    /* height: 300px; */
    background: #ffffff;
    border-radius: 7px;
    margin-bottom: 25px;
    padding-bottom: 0px;
    cursor: pointer;
    transition: transform 0.3s;
  }
  .newspaper1:hover {
    transform: translate(0, -5px);
    box-shadow: 5px 8px 16px 0px rgba(8, 1, 3, 0.08);
  }
  .newspapertwo1 {
    // width: 386px;
    width: 23%;

    /* height: 407px!important; */
    background: #ffffff;
    border-radius: 7px;
    /*background-color: red;*/
  }
  .paperinner1 {
    margin: 18px 24px;
  }
  .twoimgscopy1 {
  }

  .twoimgscopyTWO1 {
    margin-top: 10px;
    width: 100%;
    // height: 225px;
    /* height: 170px; */
    background: #000000;
    /*opacity: 0.3;*/
    border-radius: 4px;
  }
  .lastfont1 {
    width: 100%;
    font-size: 16px;
    /* font-family: Arial; */
    font-weight: bold;
    color: #3c3c3c;
    line-height: 24px;
    margin-top: 15px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }

  .lasttext1 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    margin-top: 12px;
    width: 100%;
    font-size: 12px;
    /* font-family: Arial; */
    /* font-weight: 400; */
    color: #898989;
    line-height: 18px;
    opacity: 0.75;
  }
  .keoutdoor1 {
    display: flex;
    /* width: 315px; */
    justify-content: flex-end;
    .learnmore1 {
      /* width: 65px; */
      margin-top: 12px;
      padding-bottom: 5px;
      font-size: 12px;
      /* font-family: Arial; */
      /* font-weight: 400; */
      color: #898989;
      line-height: 13px;
      /* border-bottom: 1px solid #AB1A30; */
      text-align: right;
    }

    .learnmore1::after {
      content: "";
      display: block;
      background-color: #9e2b29;
      // width: 65px;
      height: 1px;
      margin-top: 4px;
      /* position: absolute; */
      font-size: 12px;
      transition: all 0.6s ease 0s;
    }

    .learnmore1:hover::after {
      content: "";
      display: block;
      margin-top: 4px;
      background-color: #9e2b29;
      width: 0px;
      height: 1px;
      font-size: 12px;
      transition: all 0.6s ease 0s;
    }

    .learnmore1:hover {
      color: #9e2b29;
    }
  }
  .othernews1 {
    // width: 386px;
  }
}
.fackending {
  border: 1px solid red !important;
}
</style>
