<template>
  <div class="pearhome">
    <div class="faqas">
      <div class="faqatop">
        <div class="faqselfash wow animate fadeInUp">{{ $t("login.faq") }}</div>

        <div class="frtext frtextlis wow animate fadeInUp delay-300ms">
          {{ $t("login.serisepro") }}
        </div>
        <div class="appliance wow animate fadeInUp delay-400ms">
          <div class="television" @click="imgchange((titlechanges = 1))">
            <img
              v-if="titlechanges == 1"
              src="../assets/img/alltitle/icon01.png"
              alt=""
            />
            <img v-else src="../assets/img/alltitle/icon01red.png" alt="" />

            <div :class="titlechanges == 1 ? 'televtext' : 'televtexttwo'">
              {{ $t("login.rangehoods") }}
            </div>
          </div>
          <div class="television" @click="imgchange((titlechanges = 2))">
            <img
              v-if="titlechanges == 2"
              src="../assets/img/alltitle/icon02.png"
              alt=""
            />
            <img v-else src="../assets/img/alltitle/icon02red.png" alt="" />

            <div :class="titlechanges == 2 ? 'televtext' : 'televtexttwo'">
              {{ $t("login.hobs") }}
            </div>
          </div>
          <div class="television" @click="imgchange((titlechanges = 3))">
            <img
              v-if="titlechanges == 3"
              src="../assets/img/alltitle/icon03.png"
              alt=""
            />
            <img v-else src="../assets/img/alltitle/icon03red.png" alt="" />
            <div :class="titlechanges == 3 ? 'televtext' : 'televtexttwo'">
              {{ $t("login.lectricovens") }}
            </div>
          </div>
          <div class="television" @click="imgchange((titlechanges = 4))">
            <img
              v-if="titlechanges == 4"
              src="../assets/img/alltitle/icon04.png"
              alt=""
            />
            <img v-else src="../assets/img/alltitle/icon04red.png" alt="" />
            <div :class="titlechanges == 4 ? 'televtext' : 'televtexttwo'">
              {{ $t("login.steamovens") }}
            </div>
          </div>
          <div class="television" @click="imgchange((titlechanges = 5))">
            <img
              v-if="titlechanges == 5"
              src="../assets/img/alltitle/icon05.png"
              alt=""
            />
            <img v-else src="../assets/img/alltitle/icon05red.png" alt="" />
            <div :class="titlechanges == 5 ? 'televtext' : 'televtexttwo'">
              {{ $t("login.micovens") }}
            </div>
          </div>
          <div class="television" @click="imgchange((titlechanges = 6))">
            <img
              v-if="titlechanges == 6"
              src="../assets/img/alltitle/icon06.png"
              alt=""
            />
            <img v-else src="../assets/img/alltitle/icon06red.png" alt="" />
            <div :class="titlechanges == 6 ? 'televtext' : 'televtexttwo'">
              {{ $t("login.dishwasher") }}
            </div>
          </div>
        </div>
      </div>
      <div
        class="newlistsomes wow animate fadeInUp delay-500ms"
        v-for="item in contentcontain"
        :key="item.index"
      >
        <el-collapse v-model="activeNames" @change="handleChange">
          <el-collapse-item title="一致性 Consistency" :name="item.id">
            <template slot="title">
              <div class="newstitle">
                <div class="leftfont">
                  {{ item.title }}
                </div>
              </div>
              <i v-if="item.checkeds" class="handmactingtwo"></i>
              <i v-else class="handmacting"></i>
            </template>
            <div class="newscontent">
              <div v-html="item.content">
                <!-- {{ item.content }} -->
              </div>
              <!-- <div>
                {{ item.sptingtwo }}
              </div>
              <div>
                {{ item.sptingthere }}
              </div> -->
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
      <img
        v-if="contentcontain.length == 0"
        class="nodataimg"
        src="../assets/img/alltitle/nodata.png"
        alt=""
      />
    </div>
  </div>
</template>
<script>
import { WOW } from "wowjs";
import { getfaqlist } from "../request/api";

export default {
  data() {
    return {
      titlechanges: 1,
      activeNames: [],
      contentcontain: [
        {
          checkeds: true,
          name: "1",
          title:
            "What are the maintenance tips for the range hood on a daily basis?",
          sptingone:
            "1. Before installing the oil cup into the hood, first put a  little water inside the cup and add some detergent. This willmake it easy to clean afterwards.Remove the yellow stains: Use a wet towel with a little gypsum powder (old powder) to clean, which generally can be purchased in a paint shop.",
          sptingthere:
            "2. Remove the yellow stains: Use a wet towel with a little gypsum powder (old powder) to clean, which generally can be purchased in a paint shop.Remove the yellow stains: Use a wet towel with a little gypsum powder (old powder) to clean, which generally can be purchased in a paint shop.",
          sptingtwo:
            "3. Remove the fingerprints on the stainless steel surface:White oil.",
        },
        {
          name: "2",
          checkeds: true,
          title:
            "Do I need to tear off the film on the range hood's decoration cover?",
          sptingone:
            "(1) Before installing the oil cup into the hood, first put a  little water inside the cup and add some detergent. This willmake it easy to clean afterwards.",
          sptingthere:
            "(3) Remove the yellow stains: Use a wet towel with a little gypsum powder (old powder) to clean, which generally can be purchased in a paint shop.",
          sptingtwo:
            "(2) Remove the fingerprints on the stainless steel surface:White oil.",
        },
        {
          name: "3",
          checkeds: false,
          title:
            "What shall be paid attention to for the daily cleaning and maintenance of range hood?",
          sptingone:
            "(1) Before installing the oil cup into the hood, first put a  little water inside the cup and add some detergent. This willmake it easy to clean afterwards.",
          sptingthere:
            "(3) Remove the yellow stains: Use a wet towel with a little gypsum powder (old powder) to clean, which generally can be purchased in a paint shop.",
          sptingtwo:
            "(2) Remove the fingerprints on the stainless steel surface:White oil.",
        },
        {
          name: "4",
          checkeds: false,
          title: "What role does the Auto Turbo System play?",
          sptingone:
            "(1) Before installing the oil cup into the hood, first put a  little water inside the cup and add some detergent. This willmake it easy to clean afterwards.",
          sptingthere:
            "(3) Remove the yellow stains: Use a wet towel with a little gypsum powder (old powder) to clean, which generally can be purchased in a paint shop.",
          sptingtwo:
            "(2) Remove the fingerprints on the stainless steel surface:White oil.",
        },
        {
          name: "5",
          checkeds: false,
          title:
            "Do I need to tear off the film on the range hood's decoration cover?",
          sptingone:
            "(1) Before installing the oil cup into the hood, first put a  little water inside the cup and add some detergent. This willmake it easy to clean afterwards.",
          sptingthere:
            "(3) Remove the yellow stains: Use a wet towel with a little gypsum powder (old powder) to clean, which generally can be purchased in a paint shop.",
          sptingtwo:
            "(2) Remove the fingerprints on the stainless steel surface:White oil.",
        },
        {
          name: "6",
          checkeds: false,
          title: "Can I remove the range hood and clean by myself?",
          sptingone:
            "(1) Before installing the oil cup into the hood, first put a  little water inside the cup and add some detergent. This willmake it easy to clean afterwards.",
          sptingthere:
            "(3) Remove the yellow stains: Use a wet towel with a little gypsum powder (old powder) to clean, which generally can be purchased in a paint shop.",
          sptingtwo:
            "(2) Remove the fingerprints on the stainless steel surface:White oil.",
        },
      ],
    };
  },
  created() {
    if (sessionStorage.getItem("newlangusge") == null) {
      this.outpadate("au", "en", this.titlechanges);
    } else {
      this.outpadate(
        "au",
        sessionStorage.getItem("newlangusge"),
        this.titlechanges
      );
    }

    this.$bus.$on("add", (message, b, d) => {
      console.log(message);
      console.log(b);
      this.outpadate(message, b, this.titlechanges);
    });
  },
  mounted() {
    if (document.documentElement.scrollTop > 0) {
      document.documentElement.scrollTop = 0;
    }
    // document.documentElement.scrollTop = 0;
    // window.addEventListener("scroll", '0');
    this.$nextTick(() => {
      // 在dom渲染完后,再执行动画
      const wow = new WOW({
        offset: 0,
        // mobile: true,
        live: true,
      });
      wow.init();
    });
  },
  methods: {
    outpadate(a, b, c) {
      getfaqlist({
        cate: c,
        lang: b,
        country: a,
      }).then((res) => {
        console.log(res);
        res.data.forEach((el) => {
          el.checkeds = false;
        });
        this.contentcontain = res.data;
      });
    },
    imgchange(val) {
      console.log(val);
      this.titlechanges = val;
      // location.reload();
      // this.outpadate("au", sessionStorage.getItem("newlangusge"), val);
      if (sessionStorage.getItem("newlangusge") == null) {
        this.outpadate("au", "en", this.titlechanges);
      } else {
        this.outpadate("au", sessionStorage.getItem("newlangusge"), val);
      }
    },
    handleChange(val) {
      console.log(val);
      this,
        this.contentcontain.forEach((el) => {
          if (el.checkeds == true) {
            el.checkeds = false;
          }
        });
      this.contentcontain.forEach((el) => {
        // console.log(el);

        val.forEach((ob) => {
          if (ob == el.id) {
            el.checkeds = true;
          }
        });
      });
    },
  },
};
</script>
<style lang="less" scoped>
.pearhome {
  .faqas {
    width: 1200px;
    margin: 0 auto;

    .faqatop {
      margin-top: 83px;
      display: flex;
      flex-direction: column;
      display: flex;
      justify-content: center;
      align-items: center;
      .faqselfash {
        font-size: 38px;
        font-family: Arial;
        font-weight: bold;
        color: #231916;
        line-height: 30px;
      }
      .frtextlis {
        margin-top: 32px;
        text-align: center;
      }
      .frtext {
        font-size: 14px;
        // font-family: Arial;
        font-weight: 400;
        color: #231916;
        line-height: 25px;
      }
      .appliance {
        margin-top: 53px;
        display: flex;
        // border-bottom: 1px solid #f2f2f2;
        padding-bottom: 76px;
        .television {
          width: 197px;
          height: 160px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border-right: 1px solid #f2f2f2;
          img {
            width: 48px;
            // height: 40px;
            cursor: pointer;
          }
          .televtext {
            margin-top: 0px;
            font-size: 14px;
            // font-family: Arial;
            // font-weight: bold;
            color: #231916;
            line-height: 30px;
            cursor: pointer;
          }
          .televtexttwo {
            margin-top: 0px;
            font-size: 14px;
            // font-family: Arial;
            // font-weight: bold;
            color: rgb(219, 216, 216);
            line-height: 30px;
            cursor: pointer;
          }
        }
        .television:last-child {
          border-right: none;
        }
      }
    }
    .newlistsomes {
      // border-bottom: 1px solid #f2f2f2;
      padding-bottom: 30px;
      display: flex;
      align-items: center;
      .newstitle {
        width: 1200px;
        align-items: center;
        margin-top: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .leftfont {
          cursor: pointer;
          font-size: 18px;
          // font-family: Arial;
          font-weight: bold;
          color: #000000;
          width: 1000px;
          line-height: 26px;
        }
        .rightimg {
          cursor: pointer;
          width: 21px;
          height: 21px;
        }
      }
      .newscontent {
        margin-top: 25px;
        margin-left: 0px;
        // margin-bottom: 17px;
        font-size: 14px;
        // font-family: Arial;
        font-weight: 400;
        color: #666666;
        line-height: 36px;
      }
      /deep/.el-collapse-item__content {
        padding-bottom: 0;
      }
      /deep/ .el-collapse {
        //       border-bottom:1px solid #e5e5e5;
        // border-top: none;
      }
      /deep/ .el-collapse-item__arrow {
        display: none;
      }
      /deep/.el-collapse-item__header {
        border-bottom: none;
      }
      /deep/.el-collapse-item__wrap {
        border-bottom: none;
      }
      .handmacting {
        display: inline-block;
        background-image: url(../assets/img/alltitle/add.png);
        background-size: 98%;
        width: 20px;
        height: 20px;
        background-repeat: no-repeat;
        background-position: 70% 100%;
        margin-top: 15px;
      }
      .handmactingtwo {
        display: inline-block;
        background-image: url(../assets/img/alltitle/jian.png);
        background-size: 98%;
        width: 20px;
        height: 20px;
        background-repeat: no-repeat;
        background-position: 70% 100%;
        margin-top: 15px;
      }
    }
    .newlistsomes:last-child {
      margin-bottom: 100px;
    }
  }
}
.nodataimg {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  vertical-align: middle;
  width: 160px;
  height: 100px;
  margin-top: 100px;
  margin-bottom: 100px;
  padding-bottom: 76px;
}
</style>