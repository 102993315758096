<template>
  <div class="joinbig">
    <div class="jonin">
      <div class="wantsome wow animate fadeInUp delay-100ms">
        {{ $t("login.tojoinwant") }}
      </div>
      <div class="maketoyou wow animate fadeInUp delay-200ms">
        {{ $t("login.tothankyou") }}
      </div>
      <div
        class="maketoyou wow animate fadeInUp delay-200ms"
        style="margin-top: 50px"
      >
        {{ $t("login.auneedadd1") }}
      </div>
      <div class="maketoyou wow animate fadeInUp delay-200ms">
        {{ $t("login.auneedadd2") }}
      </div>
      <div class="maketoyou wow animate fadeInUp delay-200ms">
        {{ $t("login.auneedadd3") }}
      </div>
      <div class="manyinput wow animate fadeInUp delay-500ms">
        <!-- <input type="text" :placeholder="placeholder1" v-model="comname" /> -->
        <!-- <input type="text" :placeholder="placeholder2" v-model="timeinc" /> -->
        <!-- <input type="text" :placeholder="placeholder3" v-model="comadd" /> -->
        <input
          type="text"
          :placeholder="placeholder4"
          v-model="mobilep"
          :class="mobooilep ? '' : 'fackending'"
          @blur="mobei1"
        />
        <input
          type="text"
          :placeholder="placeholder5"
          v-model="websi"
          :class="weoobsi ? '' : 'fackending'"
          @blur="mobei2"
        />
        <input
          type="text"
          :placeholder="placeholder6"
          v-model="fault"
          :class="faooult ? '' : 'fackending'"
          @blur="mobei3"
        />
        <!-- <input type="text" :placeholder="placeholder7" v-model="numemp" /> -->
      </div>
      <div class="secondinput wow animate fadeInUp delay-500ms">
        <textarea
          name=""
          id=""
          cols="30"
          rows="10"
          :placeholder="placeholder7"
          v-model="comname"
          :class="comooname ? '' : 'fackending'"
          @blur="mobei4"
        ></textarea>
      </div>
      <div
        class="tosub wow animate fadeInUp delay-700ms"
        @click.stop="subobten"
      >
        {{ $t("login.submitsing") }}
      </div>
    </div>
  </div>
</template>
<script>
import { WOW } from "wowjs";
import { joinus } from "../request/api";
export default {
  data() {
    return {
      comname: "",
      timeinc: "",
      comadd: "",
      telfax: "",
      mobilep: "",
      websi: "",
      numemp: "",
      fault: "",
      mobooilep: true,
      weoobsi: true,
      faooult: true,
      comooname: true,
    };
  },
  created() {
    setTimeout(() => {
      this.$route.meta.title = this.$t("login.querythere8");
      this.$router.replace({
        query: {
          temp: Date.now(), //必有
        },
      });
    }, 10);
    this.$bus.$on("add", (message, b, d) => {
      console.log(message);
      console.log(b);
      this.$route.meta.title = this.$t("login.querythere8");
      this.$router.replace({
        query: {
          temp: Date.now(), //必有
        },
      });
    });
  },
  mounted() {
    if (document.documentElement.scrollTop > 0) {
      document.documentElement.scrollTop = 0;
    }
    // document.documentElement.scrollTop = 0;
    // window.addEventListener("scroll", '0');
    this.$nextTick(() => {
      // 在dom渲染完后,再执行动画
      const wow = new WOW({
        offset: 0,
        // mobile: true,
        live: true,
      });
      wow.init();
    });
  },
  methods: {
    subbten() {
      console.log(1111);
      if (this.comname == "") {
        this.$message({
          message: "Please enter Company Name",
          type: "error",
          offset: 90,
        });
      } else if (this.timeinc == "") {
        this.$message({
          message: "Please enter Time of Incorporation",
          type: "error",
          offset: 90,
        });
      } else if (this.comadd == "") {
        this.$message({
          message: "Please enter Company address",
          type: "error",
          offset: 90,
        });
      } else if (this.telfax == "") {
        this.$message({
          message: "Please enter Telephone, fax",
          type: "error",
          offset: 90,
        });
      } else if (this.mobilep == "") {
        this.$message({
          message: "Please enter Mobile phone",
          type: "error",
          offset: 90,
        });
      } else if (this.websi == "") {
        this.$message({
          message: "Please enter Web site",
          type: "error",
          offset: 90,
        });
      } else if (this.numemp == "") {
        this.$message({
          message: "Please enter Number of employees",
          type: "error",
          offset: 90,
        });
      } else if (this.fault == "") {
        this.$message({
          message: "Please enter Fault description",
          type: "error",
          offset: 90,
        });
      } else {
        this.$message({
          message: "Submitted successfully",
          type: "success",
          offset: 90,
        });
        joinus({
          lang: "en",
          country: "au",
          company: this.comname,
          phone: this.mobilep,
          website: this.websi,
          main_business: this.fault,
        }).then((res) => {
          console.log(res);
          this.comname = "";
          this.timeinc = "";
          this.comadd = "";
          this.telfax = "";
          this.mobilep = "";
          this.websi = "";
          this.numemp = "";
          this.fault = "";
        });
      }
    },
    mobei1() {
      this.mobooilep = true;
    },
    mobei2() {
      this.weoobsi = true;
    },
    mobei3() {
      this.faooult = true;
    },
    mobei4() {
      this.comooname = true;
    },
    subobten() {
      if (this.mobilep == "") {
        this.mobooilep = false;
        this.$message({
          message: this.$t("login.borderred"),
          type: "error",
          offset: 90,
        });
      } else if (this.websi == "") {
        this.weoobsi = false;
        this.$message({
          message: this.$t("login.borderred"),
          type: "error",
          offset: 90,
        });
      } else if (this.fault == "") {
        this.faooult = false;
        this.$message({
          message: this.$t("login.borderred"),
          type: "error",
          offset: 90,
        });
      } else if (this.comname == "") {
        this.comooname = false;
        this.$message({
          message: this.$t("login.borderred"),
          type: "error",
          offset: 90,
        });
      } else {
        if (sessionStorage.getItem("newlangusge") == null) {
          this.gotu("en");
        } else {
          this.gotu(sessionStorage.getItem("newlangusge"));
        }
      }
    },
    gotu(a) {
      joinus({
        lang: a,
        country: "au",
        company: this.comname,
        phone: this.mobilep,
        website: this.websi,
        main_business: this.fault,
      }).then((res) => {
        console.log(res);
        this.$message({
          message: this.$t("login.sucssfil"),
          type: "success",
          offset: 90,
        });
        this.mobilep = "";
        this.websi = "";
        this.fault = "";
        this.comname = "";
      });
    },
  },
  computed: {
    placeholder1() {
      return this.$t("login.inputname1");
    },
    placeholder2() {
      return this.$t("login.inputname2");
    },
    placeholder3() {
      return this.$t("login.inputname3");
    },
    placeholder4() {
      return this.$t("login.inputname4");
    },
    placeholder5() {
      return this.$t("login.inputname5");
    },
    placeholder6() {
      return this.$t("login.inputname6");
    },
    placeholder7() {
      return this.$t("login.inputname7");
    },
    placeholder8() {
      return this.$t("login.inputname8");
    },
  },
};
</script>
<style lang="less" scoped>
.joinbig {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .jonin {
    display: flex;
    width: 900px;
    margin: 0 auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 60px 0 120px 0;
    .wantsome {
      font-size: 36px;
      font-family: Arial Black;
      font-weight: 900;
      color: #000000;
      margin-top: 43px;
      text-transform: uppercase;
    }
    .maketoyou {
      margin-top: 14px;
      font-size: 14px;
      font-family: Arial;
      // font-weight: bold;
      color: #000000;
    }
    .manyinput {
      margin-top: 40px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      input {
        outline: none;
        border: none;
        width: 435px;
        height: 42px;
        font-size: 14px;
        background: #f7f7f7;
        border-radius: 5px;
        margin-bottom: 25px;
        text-indent: 15px;
      }
    }
    .secondinput {
      margin-top: 1px;
      textarea {
        outline: none;
        width: 900px;
        height: 140px;
        background: #f7f7f7;
        border-radius: 5px;
        border: none;
        resize: none;
        text-indent: 15px;
        padding-top: 18px;
      }
    }

    .tosub {
      font-size: 12px;
      // font-family: Arial;
      font-weight: bold;
      color: #ffffff;
      width: 180px;
      height: 48px;
      background: #000000;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      margin-top: 38px;
      margin-bottom: 60px;
      cursor: pointer;
    }
    .tosub:hover {
      // border: 1px solid #9e1d22;
      background-size: 14px 10px;
      background-color: #9e1d22;
    }
  }
}
input::placeholder,
textarea::placeholder {
  color: #ccc;
}
.fackending {
  border: 1px solid red !important;
}
</style>