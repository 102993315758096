<template>
  <div class="home">
    <!-- <tophead></tophead> -->
    <div class="toplunbo wow animate fadeInUp">
      <el-carousel
        :autoplay="true"
        trigger="click"
        arrow="never"
        height="512"
        :loop="false"
      >
        <!-- <el-carousel-item v-for="(item, index) in list_img" :key="index"> -->
        <el-carousel-item v-for="item in banlist" :key="item.id">
          <!-- <img
            class="headerimg"
            :src="item.img"
            alt=""
            @click="toirfrom(item.link)"
          /> -->
          <div
            class="allmosting"
            @click="
              toirfrom(
                item.link,
                item.link_type,
                item.cate,
                item.type,
                item.name
              )
            "
            :style="{ backgroundImage: 'url(' + item.img + ')' }"
          ></div>
          <!-- <div class="allmosting"></div> -->
          <!-- <div class="happinesses">
          <div class="startsiin">HAPPINESS STARTS IN YOUR KITCHEN</div>
          <div class="commitekit">
            FOTILE is committed to turning the kitchen into an open place filled
            with love and happiness and bringing this lifestyle to every corner
            of the world.
          </div>
          <div class="proandmore">
            <div class="morebeaytiful">MORE</div>
          </div>
        </div> -->
        </el-carousel-item>
      </el-carousel>
    </div>

    <!-- 中间部分 -->
    <div class="filterandreset wow animate fadeInUp delay-300ms">
      <div class="rangehodd">{{ tylpclass }}</div>
      <div class="twosailder">
        <div class="sailderleft">
          <div class="saillefandtop">
            <!-- <div class="theeling">
              <img src="../assets/img/alltitle/thereling.png" alt="" />
              <span>Filter</span>
            </div>
            <div class="middleline"></div>
            <div class="chered">
              <img src="../assets/img/alltitle/chered.png" alt="" />
              <span>Reset</span>
            </div> -->
          </div>
          <div class="iwantan">
            <el-collapse v-model="activeNames" @change="handleChange">
              <!-- <el-collapse-item>
                <template slot="title" name="1">
                  FOTILE V Series
                </template>
                <div class="mostchosessche">
                  <el-checkbox>FOTILE T Series</el-checkbox>
                  <el-checkbox>FOTILE W Series</el-checkbox>
                  <el-checkbox>FOTILE E Series</el-checkbox>
                </div>
              </el-collapse-item> -->
              <el-collapse-item
                :title="item.title"
                v-for="item in lefttype"
                :key="item.id"
                :name="item.index"
              >
                <div class="mostchosessche">
                  <el-checkbox
                    v-for="val in item.value"
                    :key="val.id"
                    @change="tochackpost(item.type, val.id, $event)"
                    >{{ val.name }}</el-checkbox
                  >
                  <!-- <el-checkbox>Ex-venting/Recycling</el-checkbox>
                  <el-checkbox>Recycling</el-checkbox> -->
                </div>
              </el-collapse-item>
              <!-- <el-collapse-item title="Type of Hood" name="2">
                <div class="mostchosessche">
                  <el-checkbox>Under-Cabinet</el-checkbox>
                  <el-checkbox>Chimney</el-checkbox>
                  <el-checkbox>Island</el-checkbox>
                </div>
              </el-collapse-item>
              <el-collapse-item title="Dimensions" name="3">
                <div class="mostchosessche">
                  <el-checkbox>900</el-checkbox>
                  <el-checkbox>600</el-checkbox>
                  <el-checkbox>600/750/900</el-checkbox>
                </div>
              </el-collapse-item>
              <el-collapse-item title="Color/Material" name="4">
                <div class="mostchosessche">
                  <el-checkbox>Stainless steel</el-checkbox>
                  <el-checkbox>Black/Tempered Glass</el-checkbox>
                  <el-checkbox>Grey/Tempered Glass</el-checkbox>
                  <el-checkbox>Brown/Tempered Glass</el-checkbox>
                  <el-checkbox>Stainless Steel + Tempered Glass</el-checkbox>
                  <el-checkbox>Clear/Tempered Glass</el-checkbox>
                  <el-checkbox>Others</el-checkbox>
                </div>
              </el-collapse-item>
              <el-collapse-item title="Features" name="5">
                <div class="mostchosessche">
                  <el-checkbox>Intelligent Air Steward</el-checkbox>
                  <el-checkbox
                    >Auto Extraction Resistance Detection</el-checkbox
                  >
                  <el-checkbox>DC Motor</el-checkbox>
                  <el-checkbox>Delay Off Function</el-checkbox>
                  <el-checkbox>Screen Lock Function</el-checkbox>
                  <el-checkbox>Intelligent Self-Adjusting</el-checkbox>
                  <el-checkbox>Wave start function</el-checkbox>
                  <el-checkbox>Gas & Heat Sensor</el-checkbox>
                </div>
              </el-collapse-item>
              <el-collapse-item title="Light" name="6">
                <div class="mostchosessche">
                  <el-checkbox>LED</el-checkbox>
                  <el-checkbox>Halogen Light</el-checkbox>
                </div>
              </el-collapse-item> -->
            </el-collapse>
          </div>
        </div>

        <el-checkbox-group @change="mostcheck" v-model="checkList" :max="3">
          <div class="sailderright">
            <div
              class="sailderrightinner wow animate fadeInUp"
              v-for="item in manyproductlist"
              :key="item.id"
            >
              <div class="patenrted">{{ item.name }}</div>
              <div class="needcoulum">
                <div class="openwide">
                  <div class="mowleft">{{ item.intro }}</div>
                  <div
                    class="mowright"
                    @click="toother(item.link, item.id)"
                    v-if="item.link"
                  >
                    {{ $t("login.seriesing") }}
                  </div>
                </div>
              </div>
              <div class="gmzerofi">
                <div class="cooking" v-for="ele in item.data" :key="ele.id">
                  <img
                    @click.stop="
                      addtodetail(
                        ele.code,
                        ele.is_outside,
                        ele.outside_url,
                        item.code
                      )
                    "
                    :src="ele.thumb"
                    alt=""
                  />
                  <span
                    @click.stop="
                      addtodetail(
                        ele.code,
                        ele.is_outside,
                        ele.outside_url,
                        item.code
                      )
                    "
                    class="emggwith"
                    >{{ ele.code }}</span
                  >
                  <span
                    @click.stop="
                      addtodetail(
                        ele.code,
                        ele.is_outside,
                        ele.outside_url,
                        item.code
                      )
                    "
                    class="yoursckk"
                    >{{ ele.tags }}</span
                  >

                  <el-checkbox
                    :label="ele.id"
                    @change="gaibi(ele.id)"
                    :disabled="ele.disabled"
                    v-model="ele.checked"
                    >{{ $t("login.contrast") }}</el-checkbox
                  >
                </div>
                <div class="havenoname"></div>
                <div class="havenoname"></div>
                <div class="havenoname"></div>
              </div>
            </div>
          </div>
        </el-checkbox-group>
      </div>
    </div>
    <!-- 右边对比右拉框 -->
    <div class="contrastfather" @click="drawer = true">
      <img src="../assets/img/alltitle/bookiconfack.png" alt="" />
      <span>{{ $t("login.contrast") }}</span>
      <div class="zhansize" v-if="choosecheck">{{ choosecheck }}</div>
    </div>

    <!-- righttitle -->
    <el-drawer
      title="我是标题"
      :visible.sync="drawer"
      :with-header="false"
      size="560px"
    >
      <div class="suchasting">
        <div class="suchasinner">
          <!-- <img src="../assets/img/alltitle/bifan.png" alt="" /> -->
          <img src="" alt="" />
          <span>{{ $t("login.protobi") }}</span>
          <div></div>
        </div>
      </div>
      <div class="airapple" v-for="(item, index) in addche" :key="index">
        <div class="airappleinner">
          <img class="contiollself" :src="item.thumb" alt="" />
          <div class="springthing">
            <!-- <div class="mgboserive1">FOTILE V SERIES</div> -->
            <div class="mgboserive2">{{ item.code }}</div>
            <div class="mgboserive3">
              {{ item.intro }}
            </div>
          </div>
          <!-- <img
            src="../assets/img/alltitle/shanchu.png"
            class="delesomting"
            alt=""
          /> -->
          <img class="delesomting" alt="" />
          <!-- @click="closeonlyone(index)" -->
        </div>
      </div>

      <div class="emptyting" @click="emptyclear">
        <span>{{ $t("login.obempty") }}</span>
      </div>
      <div class="begin" @click.stop="addings('constrast')">
        {{ $t("login.osure") }}
      </div>
    </el-drawer>

    <!-- <bomode /> -->

    <!-- <el-button @click="dfdfd">默认按钮</el-button> -->
  </div>
</template>

<script>
import { WOW } from "wowjs";
import { getproban, getscreen, getGoods } from "../request/api";

import bomode from "../components/bottommode";
import tophead from "../components/topmode";

export default {
  components: {
    bomode,
    tophead,
  },
  data() {
    return {
      tylpclass: "",
      drawer: false,
      activeNames: [""],
      checkList: [],
      choosecheck: 0,
      addche: [],
      chuanmessage: "",
      chuanb: "",
      chuancode: "",
      banlist: [],
      lefttype: [],
      list_img: [
        { url: require("../assets/img/alltitle/productlist-01.jpg") },
        { url: require("../assets/img/alltitle/productlist-02.jpg") },
        { url: require("../assets/img/alltitle/productlist-00.jpg") },
        // { url: require("../assets/img/alltitle/ductlist4.jpg") },
      ],
      manyproductlist: [],
      // manyproductlist: [
      //   // {
      //   //   id: 1,
      //   //   title: "FOTILE V Series",
      //   //   content:
      //   //     "Open wide air intake, extracting the fumes before they spread;Patented automatic smokescreen to control the spread of fumes effectively.Patented automatic smokescreen to control the spread of fumes effectively.",
      //   //   boxval: [
      //   //     {
      //   //       checked: false,
      //   //       disabled: false,
      //   //       id: 1,
      //   //       img: "https://z3.ax1x.com/2021/09/22/4NRq39.png",
      //   //       innertitle: "EMG9030",
      //   //       innercontent:
      //   //         "enjoy good times cooking with your family your open kitchen good times",
      //   //     },
      //   //     {
      //   //       checked: false,
      //   //       disabled: false,
      //   //       id: 2,
      //   //       img: "https://z3.ax1x.com/2021/09/22/4NRq39.png",
      //   //       innertitle: "EMG9030",
      //   //       innercontent:
      //   //         "enjoy good times cooking with your family your open kitchen good times",
      //   //     },
      //   //     {
      //   //       checked: false,
      //   //       disabled: false,
      //   //       id: 3,
      //   //       img: "https://z3.ax1x.com/2021/09/22/4NWMCQ.png",
      //   //       innertitle: "EMG9030",
      //   //       innercontent:
      //   //         "your family your open kitchen good times enjoy good times cooking with enjoy",
      //   //     },
      //   //     {
      //   //       checked: false,
      //   //       disabled: false,
      //   //       id: 4,
      //   //       img: "https://z3.ax1x.com/2021/09/22/4NWJbV.png",
      //   //       innertitle: "EMG9030",
      //   //       innercontent:
      //   //         "your family your open kitchen good times enjoy good times cooking with enjoy",
      //   //     },
      //   //     {
      //   //       checked: false,
      //   //       disabled: false,
      //   //       id: 15,
      //   //       img: "https://z3.ax1x.com/2021/09/22/4NRq39.png",
      //   //       innertitle: "EMG9030",
      //   //       innercontent:
      //   //         "enjoy good times cooking with your family your open kitchen good times",
      //   //     },
      //   //     {
      //   //       checked: false,
      //   //       disabled: false,
      //   //       id: 13,
      //   //       img: "https://z3.ax1x.com/2021/09/22/4NWJbV.png",
      //   //       innertitle: "EMG9030",
      //   //       innercontent:
      //   //         "enjoy good times cooking with your family your open kitchen good times",
      //   //     },
      //   //     {
      //   //       checked: false,
      //   //       disabled: false,
      //   //       id: 16,
      //   //       img: "https://z3.ax1x.com/2021/09/22/4NWMCQ.png",
      //   //       innertitle: "EMG9030",
      //   //       innercontent:
      //   //         "your family your open kitchen good times enjoy good times cooking with enjoy",
      //   //     },
      //   //     {
      //   //       checked: false,
      //   //       disabled: false,
      //   //       id: 14,
      //   //       img: "https://z3.ax1x.com/2021/09/22/4NRq39.png",
      //   //       innertitle: "EMG9030",
      //   //       innercontent:
      //   //         "your family your open kitchen good times enjoy good times cooking with enjoy",
      //   //     },
      //   //   ],
      //   // },
      //   // {
      //   //   id: 2,
      //   //   title: "FOTILE W Series",
      //   //   content:
      //   //     "Open wide air intake, extracting the fumes before they spread;Patented automatic smokescreen to control the spread of fumes effectively.Patented automatic smokescreen to control the spread of fumes effectively.",
      //   //   boxval: [
      //   //     {
      //   //       checked: false,
      //   //       disabled: false,
      //   //       id: 5,
      //   //       img: "https://z3.ax1x.com/2021/09/22/4NRq39.png",
      //   //       innertitle: "EMG9030",
      //   //       innercontent:
      //   //         "enjoy good times cooking with your family your open kitchen good times",
      //   //     },
      //   //     {
      //   //       checked: false,
      //   //       disabled: false,
      //   //       id: 6,
      //   //       img: "https://z3.ax1x.com/2021/09/22/4NRq39.png",
      //   //       innertitle: "EMG9030",
      //   //       innercontent:
      //   //         "enjoy good times cooking with your family your open kitchen good times",
      //   //     },
      //   //     {
      //   //       checked: false,
      //   //       disabled: false,
      //   //       id: 7,
      //   //       img: "https://z3.ax1x.com/2021/09/22/4NWMCQ.png",
      //   //       innertitle: "EMG9030",
      //   //       innercontent:
      //   //         "your family your open kitchen good times enjoy good times cooking with enjoy",
      //   //     },
      //   //     {
      //   //       checked: false,
      //   //       disabled: false,
      //   //       id: 8,
      //   //       img: "https://z3.ax1x.com/2021/09/22/4NWJbV.png",
      //   //       innertitle: "EMG9030",
      //   //       innercontent:
      //   //         "your family your open kitchen good times enjoy good times cooking with enjoy",
      //   //     },
      //   //   ],
      //   // },
      //   // {
      //   //   id: 3,
      //   //   title: "FOTILE T Series",
      //   //   content:
      //   //     "Open wide air intake, extracting the fumes before they spread;Patented automatic smokescreen to control the spread of fumes effectively.Patented automatic smokescreen to control the spread of fumes effectively.",
      //   //   boxval: [
      //   //     {
      //   //       checked: false,
      //   //       disabled: false,
      //   //       id: 9,
      //   //       img: "https://z3.ax1x.com/2021/09/22/4NRq39.png",
      //   //       innertitle: "EMG9030",
      //   //       innercontent:
      //   //         "enjoy good times cooking with your family your open kitchen good times",
      //   //     },
      //   //     {
      //   //       checked: false,
      //   //       disabled: false,
      //   //       id: 10,
      //   //       img: "https://z3.ax1x.com/2021/09/22/4NRq39.png",
      //   //       innertitle: "EMG9030",
      //   //       innercontent:
      //   //         "enjoy good times cooking with your family your open kitchen good times",
      //   //     },
      //   //     {
      //   //       checked: false,
      //   //       disabled: false,
      //   //       id: 11,
      //   //       img: "https://z3.ax1x.com/2021/09/22/4NWMCQ.png",
      //   //       innertitle: "EMG9030",
      //   //       innercontent:
      //   //         "your family your open kitchen good times enjoy good times cooking with enjoy",
      //   //     },
      //   //     {
      //   //       checked: false,
      //   //       disabled: false,
      //   //       id: 12,
      //   //       img: "https://z3.ax1x.com/2021/09/22/4NWJbV.png",
      //   //       innertitle: "EMG9030",
      //   //       innercontent:
      //   //         "your family your open kitchen good times enjoy good times cooking with enjoy",
      //   //     },
      //   //   ],
      //   // },
      // ],
      anothersting: {},
      totalting: [],
      tack: {},
      partynum: [],
    };
  },
  created() {
    // if (document.documentElement.scrollTop > 0) {

    // }
    console.log(this.$route.query.id, 11111111111111);
    // console.log(this.$route.query.id);
    // this.chuanmessage = "au";

    this.chuancode = this.$route.query.id;

    if (sessionStorage.getItem("newlangusge") == null) {
      this.outbanner("au", "en", this.$route.query.id);
      this.outscreen("au", "en", this.$route.query.id);
      this.outgoods("au", "en", {
        cate: this.$route.query.id,
      });
    } else {
      this.outbanner(
        "au",
        sessionStorage.getItem("newlangusge"),
        this.$route.query.id
      );
      this.outscreen(
        "au",
        sessionStorage.getItem("newlangusge"),
        this.$route.query.id
      );
      this.outgoods("au", sessionStorage.getItem("newlangusge"), {
        cate: this.$route.query.id,
      });
    }
    setTimeout(() => {
      this.$route.meta.title = this.$t("login.querythere2");
      this.$router.replace({
        query: {
          temp: Date.now(), //必有
          id: this.$route.query.id,
        },
      });
    }, 10);
    this.$bus.$on("add", (message, b, d) => {
      console.log(message);
      console.log(b);
      console.log(d);
      this.chuanmessage = message;
      this.chuanb = b;
      this.chuancode = d;
      this.outbanner(message, b, d);
      this.outscreen(message, b, d);
      this.outgoods(message, b, {
        cate: d,
      });
      if (this.chuancode == 1) {
        return (this.tylpclass = this.$t("login.rangehoods"));
      } else if (this.chuancode == 2) {
        return (this.tylpclass = this.$t("login.hobs"));
      } else if (this.chuancode == 3) {
        return (this.tylpclass = this.$t("login.lectricovens"));
      } else if (this.chuancode == 4) {
        return (this.tylpclass = this.$t("login.steamovens"));
      } else if (this.chuancode == 5) {
        return (this.tylpclass = this.$t("login.micovens"));
      } else if (this.chuancode == 6) {
        return (this.tylpclass = this.$t("login.dishwasher"));
      }
      this.$route.meta.title = this.$t("login.querythere2");
      this.$router.replace({
        query: {
          temp: Date.now(), //必有
          id: this.$route.query.id,
        },
      });
    });

    // if (this.chuancode == 1) {
    //   return (this.tylpclass = this.$t("login.rangehoods"));
    // } else if (this.chuancode == 2) {
    //   return (this.tylpclass = this.$t("login.hobs"));
    // } else if (this.chuancode == 3) {
    //   return (this.tylpclass = this.$t("login.lectricovens"));
    // } else if (this.chuancode == 4) {
    //   return (this.tylpclass = this.$t("login.steamovens"));
    // } else if (this.chuancode == 5) {
    //   return (this.tylpclass = this.$t("login.micovens"));
    // } else if (this.chuancode == 6) {
    //   return (this.tylpclass = this.$t("login.dishwasher"));
    // }
    this.$bus.$on("chaneshuing", (message, b, d) => {
      location.reload();
    });
    document.documentElement.scrollTop = 0;
  },

  methods: {
    toirfrom(a, b, c, d, e) {
      if (b == 1) {
        this.$router.push({
          path: "/otherwebsite",
          query: { type: d, name: e, cate: c },
        });
      } else if (b == 2) {
        if (a != "") {
          var url = a;
          window.open(url, "_blank");
        }
      }
    },
    toother(a, b) {
      console.log(a, 1111);
      this.$router.push({
        path: "/otherwebsite",
        query: { id: a, type: 1, name: b, cate: this.chuancode },
      });
    },
    tochackpost(a, b, c) {
      console.log(a);
      console.log(b);
      console.log(c);

      if (c == true) {
        if (this.tack[a]) {
          this.tack[a] = this.tack[a] + "," + b;
          console.log(this.tack);
          var str = this.tack[a].split(",").sort();
          console.log(str);
          this.tack[a] = str.join(",");
        } else {
          this.tack[a] = b;
          console.log(this.tack);
        }
      } else {
        console.log(this.tack);
        // console.log(this.tack[a] - 1);
        console.log(this.tack[a]);
        this.tack[a] = this.tack[a] + "0";

        console.log(this.tack[a].split(","));

        var newsthing = this.tack[a].split(",");
        console.log(newsthing.length);

        var idex = newsthing.indexOf(b.toString());
        console.log(idex);
        newsthing.splice(idex, 1);

        this.tack[a] = newsthing.join(",");
        console.log(newsthing.length);
        if (newsthing.length == 0) {
          delete this.tack[a];
        }
      }

      if (sessionStorage.getItem("newlangusge") == null) {
        this.outgoods("au", "en", {
          ...this.tack,
          cate: this.chuancode,
        });
      } else {
        this.outgoods("au", sessionStorage.getItem("newlangusge"), {
          ...this.tack,
          cate: this.chuancode,
        });
      }
    },
    outgoods(a, b, c) {
      getGoods({
        lang: b,
        country: a,
        post: c,
      }).then((res) => {
        console.log(res);
        this.manyproductlist = res.data;
      });
    },
    outscreen(a, b, c) {
      getscreen({
        lang: b,
        country: a,
        cate: c,
      }).then((res) => {
        console.log(res);
        this.lefttype = res.data;
      });
    },
    outbanner(a, b, c) {
      getproban({
        type: "1",
        lang: b,
        country: a,
        cate: c,
      }).then((res) => {
        console.log(res);
        this.banlist = res.data;
        console.log(this.banlist);
      });
    },

    addtodetail(a, b, c) {
      console.log(22222222, b, c);

      if (b == 1) {
        this.$router.push({
          path: "/otherwebsite",
          query: { id: c, type: 2, name: a, cate: 0 },
        });
      } else if (b == 0) {
        this.$router.push({
          path: "/productdetail",
          query: { id: a },
          meta: { title: "首页" },
        });
      }
    },
    addings(url) {
      if (this.addche.length == 0) {
        this.$message("Please select the products to compare");
      } else {
        // this.$router.push(url);
        this.$router.push({
          path: url,
          query: {
            todata: this.addche,
            manycode: this.tylpclass,
            chuancode: this.chuancode,
          },
        });
        // console.log(this.addche);
      }
    },
    handleChange(val) {
      // console.log(val);
    },
    mostcheck(el) {
      this.choosecheck = this.checkList.length;
    },

    gaibi(el) {
      this.manyproductlist.forEach((num) => {
        console.log(num);
        num.data.forEach((it) => {
          if (el == it.id) {
            if (it.checked == true) {
              it.checked = false;
              var queryidindex = this.addche.findIndex((value, index, arr) => {
                return value.id == el;
              });
              if (queryidindex != -1) {
                this.addche.splice(queryidindex, 1);
              }
            } else {
              it.checked = true;
              if (it.checked == true) {
                this.addche.push(it);

                if (this.addche.length > 3) {
                  this.addche.pop();
                }
              }
            }
          }
        });
      });

      console.log(this.addche);
    },
    emptyclear() {
      this.addche = [];
      location.reload();
    },
    closeonlyone(vall) {
      console.log(111111);
      // this.manyproductlist.forEach((num) => {

      //   num.boxval.forEach((it) => {
      //     console.log(it);
      //     if (vall == it.id) {
      //       it.checked = false;
      //     }
      //   });
      // });
      // this.addche.splice(vall, 1);
      // location.reload();
    },

    // dfdfd(val) {
    //   this.manyproductlist.forEach((num) => {
    //     num.boxval.forEach((it) => {
    //       console.log(it);
    //       if (val == it.id) {
    //         it.checked = true;
    //       }
    //     });
    //   });
    // },
  },

  mounted() {
    // if (document.documentElement.scrollTop > 0) {

    // }
    if (this.chuancode == 1) {
      return (this.tylpclass = this.$t("login.rangehoods"));
    } else if (this.chuancode == 2) {
      return (this.tylpclass = this.$t("login.hobs"));
    } else if (this.chuancode == 3) {
      return (this.tylpclass = this.$t("login.lectricovens"));
    } else if (this.chuancode == 4) {
      return (this.tylpclass = this.$t("login.steamovens"));
    } else if (this.chuancode == 5) {
      return (this.tylpclass = this.$t("login.micovens"));
    } else if (this.chuancode == 6) {
      return (this.tylpclass = this.$t("login.dishwasher"));
    }

    this.$nextTick(() => {
      // 在dom渲染完后,再执行动画
      const wow = new WOW({
        offset: 0,
        // mobile: true,
        live: true,
      });
      wow.init();
    });
    document.documentElement.scrollTop = 0;
  },
};
</script>
<style lang='less' scoped>
.contrastfather {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  position: fixed;
  right: 0px;
  top: 495px;
  z-index: 999;
  background: #ffffff;
  border-radius: 6px 0 0px 6px;
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.13);
  img {
    width: 22px;
    height: 22px;
  }
  span {
    font-size: 12px;
    margin-top: 10px;
    /* font-family: PingFang SC; */
    font-weight: 500;
    color: #000000;
    /* line-height: 20px; */
  }
  .zhansize {
    position: absolute;
    top: -3px;
    left: -3px;
    width: 18px;
    height: 18px;
    background-color: #9d2736;
    border-radius: 50%;
    font-size: 12px;
    color: white;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.suchasting {
  border-bottom: 1px solid #f2f2f2;
  // z-index: 999;
  .suchasinner {
    height: 50px;
    margin: 0px 29px;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-between;
    img {
      cursor: pointer;
      width: 8px;
      /* height: 20px; */
    }
    span {
      font-size: 20px;
      /* font-family: Arial; */
      font-weight: bold;
      color: #000000;
      text-transform: uppercase;
    }
  }
}
.airapple {
  // margin-top: 30px;
  .airappleinner {
    margin: 30px;
    display: flex;
    // justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #f2f2f2;
    padding-bottom: 29px;
    .contiollself {
      width: 78px;
      height: 78px;
    }
    .springthing {
      margin-left: 19px;
      margin-right: 10px;
      text-align: left;
      .mgboserive1 {
        font-size: 12px;
        /* font-family: Arial; */
        /* font-weight: bold; */
        color: #898989;
        line-height: 25px;
      }
      .mgboserive2 {
        font-size: 14px;
        /* font-family: Arial; */
        font-weight: bold;
        color: #000000;
        line-height: 19px;
        margin-top: 0px;
        margin-bottom: 3px;
      }
      .mgboserive3 {
        /* width: 269px; */
        width: 100%;
        font-size: 12px;
        /* font-family: Arial; */
        /* font-weight: 400; */
        color: #999999;
        /* opacity: 0.75; */
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        /* margin-top:9px; */
        line-height: 18px;
      }
    }
    .delesomting {
      cursor: pointer;
      width: 19px;
      opacity: 0.5;
      /* height: 24px; */
    }
  }
}
.emptyting {
  margin: 0 auto;
  margin-top: 70px;
  display: flex;
  /* width: 60px; */

  /* font-family: Arial; */
  font-weight: 400;

  /* opacity: 0.5; */
  justify-content: center;
  align-items: center;
  text-align: center;
  text-align: center;

  padding-bottom: 6px;

  span {
    border-bottom: 1px solid #898989;
    height: 24px;
    line-height: 24px;
    cursor: pointer;
    color: #898989;
    font-size: 12px;
  }
  span:hover {
    color: #93111d;
    border-bottom: 1px solid #93111d;
  }
}
.begin {
  cursor: pointer;
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 58px;
  background: #93111d;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-size: 14px;
  /* font-family: Arial; */
  font-weight: bold;
  color: #ffffff;
}
.begin:hover {
  background: #000;
}

/deep/ .el-carousel--horizontal {
  overflow: hidden;
}
/deep/ .el-carousel__indicators--horizontal {
  bottom: 30px;
}

/deep/ .toplunbo .el-carousel__button {
  opacity: 1;
}
/deep/
  .el-carousel__indicator.el-carousel__indicator--horizontal.is-active
  .el-carousel__button {
  background: #992025;
  width: 12px !important;
}
/deep/ .el-carousel__container {
  height: 400px;
}
.home {
  .headerimg {
    width: 100%;
    height: 400px;
    position: relative;
  }
  .allmosting {
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    overflow: hidden;
    background-image: url(../assets/img/alltitle/ductlist2.jpg);
    background-position: center center;
    // background-size: cover;
  }
  .pro2 {
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    overflow: hidden;
    background-image: url(../assets/img/alltitle/ductlist1.jpg);
    background-position: center center;
  }
  .pro3 {
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    overflow: hidden;
    background-image: url(../assets/img/alltitle/ductlist3.jpg);
    background-position: center center;
  }
  .pro4 {
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    overflow: hidden;
    background-image: url(../assets/img/alltitle/ductlist4.jpg);
    background-position: center center;
  }
  .happinesses {
    position: absolute;
    top: 175px;
    right: 99px;
    width: 612px;
    // height: 200px;
    // background-color: red;
    display: flex;
    flex-direction: column;
    .startsiin {
      font-size: 28px;
      /* font-family: Arial Black; */
      font-weight: bold;
      color: black;
      line-height: 30px;
    }
    .commitekit {
      font-size: 12px;
      margin-top: 20px;
      /* font-family: Arial; */
      font-weight: 400;
      color: black;
      line-height: 18px;
    }
    .proandmore {
      margin-top: 66px;
      display: flex;
      .morebeaytiful {
        width: 84px;
        height: 40px;
        background: #ab1a30;
        /* opacity: 0.82; */
        border-radius: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-size: 12px;
        /* font-family: Arial; */
        font-weight: bold;
        color: #ffffff;
      }
      .butflys {
        margin-left: 40px;
        width: 222px;
        height: 55px;
        background: #000000;
        opacity: 0.6;
        border-radius: 9px 9px 9px 9px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        /* font-family: Arial; */
        font-weight: bold;
        color: #ffffff;
      }
    }
  }
  .filterandreset {
    width: 1200px;
    margin: 0 auto;
    margin-top: 65px;
    margin-bottom: 111px;
    .rangehodd {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 28px;
      /* font-family: Arial; */
      font-weight: bold;
      color: #000000;
      line-height: 36px;
    }
    .twosailder {
      margin-top: 50px;
      display: flex;
      .sailderleft {
        width: 190px;
        margin-right: 20px;
        font-size: 14px;
        .saillefandtop {
          display: flex;
          align-content: center;
          .theeling {
            cursor: pointer;
            img {
              margin-right: 5px;
              width: 12px;
              opacity: 0.75;
            }
          }
          .middleline {
            width: 1px;
            height: 12px;
            background-color: #e8e8e8;
            margin-left: 15px;
            margin-right: 15px;
          }
          .chered {
            cursor: pointer;
            color: #9e2b29;
            img {
              margin-right: 5px;
              width: 12px;
              opacity: 0.75;
            }
          }
        }
        .iwantan {
          margin-top: 24px;
          /deep/ .el-collapse-item__arrow el-icon-arrow-right {
            display: none;
          }
          /deep/ .el-collapse-item__arrow {
            // display: none;
          }
          /deep/ .el-collapse-item__wrap .el-collapse-item__content {
            padding-bottom: 8px;
            padding-top: 5px;
          }
          /deep/ .el-collapse-item__header {
            color: #000;
            font-weight: bold;
          }
          .mostchosessche {
            display: flex;
            flex-direction: column;
            /deep/ .el-checkbox__inner {
              border-radius: 50px;
            }
            /deep/.el-checkbox__input + .el-checkbox__label {
              font-size: 12px;
            }
            /deep/.el-checkbox__input.is-checked + .el-checkbox__label {
              color: #9d2736;
              font-size: 12px;
            }
            /deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
            .el-checkbox__input.is-indeterminate .el-checkbox__inner {
              background-color: #9d2736;
              border-color: #9d2736;
            }
            /deep/ .el-checkbox__input.is-focus .el-checkbox__inner {
              border-color: #9d2736;
            }
            /deep/ .el-checkbox__input.is-focus .el-checkbox__inner:hover {
              border-color: #9d2736;
            }
            /deep/.el-checkbox__inner:hover {
              border-color: #9d2736;
            }

            /deep/ .el-checkbox {
              margin-bottom: 10px;
              font-size: 12px;
            }
          }
          .handmacting {
            display: inline-block;
            background-image: url(../assets/img/alltitle/add.png);
            width: 50px;
            height: 50px;
            background-repeat: no-repeat;
            background-position: center center;
          }
        }
      }

      .sailderright {
        display: flex;
        flex-direction: column;
        width: 960px;
        border-left: 1px dashed #eaeaea;
        .sailderrightinner {
          margin-left: 21px;
          .patenrted {
            font-size: 18px;
            /* font-family: Arial; */
            font-weight: bold;
            color: #000000;
            line-height: 26px;
            text-transform: uppercase;
          }
          .needcoulum {
            display: flex;
          }
          .openwide {
            margin-top: 8px;
            margin-bottom: 24px;
            font-size: 12px;
            /* font-family: Arial; */
            font-weight: 400;
            color: #898989;
            line-height: 21px;
            /* opacity: 0.85; */
            display: flex;
            justify-content: space-between;
            // align-items: center;
            .mowleft {
              width: 800px;
              vertical-align: top;
            }
            .mowright {
              width: 140px;
              text-align: right;
              // font-size: 16px;
              font-weight: 400;
              cursor: pointer;
              color: #9e2b29;
              margin-top: -32px;
            }
          }
          .gmzerofi {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            margin-bottom: 24px;
            .cooking {
              margin-bottom: 20px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              width: 220px;
              height: 261px;
              background: #f8f8f8;
              img {
                width: 120px;
                height: 120px;
                cursor: pointer;
              }
              .emggwith {
                font-size: 14px;
                /* font-family: Arial; */
                font-weight: bold;
                color: #000000;
                line-height: 19px;
                cursor: pointer;
              }
              .yoursckk {
                // width: 75%;
                // width: 150px;
                // margin-top: 16px;
                // font-size: 12px;
                // line-height: 15px;
                // /* font-family: Arial; */
                // // height: 30px;
                // font-weight: 400;
                // color: #999999;
                // display: flex;
                // text-align: center;
                // justify-content: center;
                // align-items: center;
                // margin-bottom: 16px;
                // cursor: pointer;
                // // word-break: break-all;
                // text-overflow: ellipsis;
                // display: -webkit-box;
                // -webkit-box-orient: vertical;
                // -webkit-line-clamp: 2;
                // overflow: hidden;
                // word-wrap: break-word;
                // word-break: break-all;

                width: 200px;
                // height: 500px;
                margin-top: 16px;
                font-size: 12px;
                color: #999999;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 16px;
                text-align: center;
                cursor: pointer;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                word-wrap: break-word;
              }
              /deep/ .el-checkbox__inner {
                border-radius: 50px;
              }
              /deep/.el-checkbox__input + .el-checkbox__label {
                color: #999999;
                font-size: 12px;
              }
              /deep/.el-checkbox__input.is-checked + .el-checkbox__label {
                color: #9d2736;
                font-size: 12px;
              }
              /deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
              .el-checkbox__input.is-indeterminate .el-checkbox__inner {
                background-color: #9d2736;
                border-color: #9d2736;
              }
              /deep/ .el-checkbox__input.is-focus .el-checkbox__inner {
                border-color: #9d2736;
              }
              /deep/ .el-checkbox__input.is-focus .el-checkbox__inner:hover {
                border-color: #9d2736;
              }
              /deep/.el-checkbox__inner:hover {
                border-color: #9d2736;
              }
            }
          }
        }
      }
    }
  }
}
.toplunbo {
  // overflow: hidden;
  /deep/.el-carousel__indicators--horizontal {
    // width: 50px;
    height: 7px;
    // background-color: yellow;
  }
  /deep/ .el-carousel__indicator--horizontal {
    /* width: 6.7px !important; */
    height: 7px !important;
    // background-color: purple;
  }
  /deep/.el-carousel__indicator {
    /* width: 6.7px; */
    height: 7px;
  }
  /deep/ .el-carousel__button {
    border-radius: 50px;
    width: 6.7px;
    height: 7px;
    background-color: #cecece;
  }
  /deep/ .is-active {
    // background-color: #9e2b29;
  }
}
/deep/ .el-drawer__open .el-drawer.rtl {
  z-index: 99999;
}
/deep/ .el-drawer__wrapper {
  z-index: 999999999 !important;
}
.havenoname {
  width: 220px;
  // height: 261px;
}
</style>
