<template>
  <div class="certication">
    <!-- <div>{{ adddbegin }}</div> -->

    <div class="TechnicalParameters">{{ $t("login.teparnters") }}</div>
    <div class="volltage">
      <!-- <div class="fontleft" v-if="spec_img == null || spec_img == ''"> -->
      <div class="fontleft">
        <div class="Certification" v-for="item in param" :key="item.id">
          <div>{{ item.name }}:</div>
          <div class="rightcontent">
            {{ item.content }}
          </div>
        </div>
      </div>
    </div>
    <!-- <img
      class="fhashi"
      :src="spec_img"
      alt=""
      v-if="spec_img != null || spec_img != ''"
    /> -->
    <img class="fhashi" :src="spec_img" alt="" />
  </div>
</template>
<script>
export default {
  props: ["spec_img", "param"],

  data() {
    return {};
  },
  created() {
    console.log(this.spec_img);
  },
  computed: {
    // adddbegin: function () {
    //   // return this.param.slice(0, this.param.length / 2);
    //   // return this.param.slice(0, 15);
    //   console.log(this.param.length % 2);
    //   if (this.param.length % 2 == 0) {
    //     return this.param.slice(0, this.param.length / 2);
    //   } else {
    //     return this.param.slice(0, this.param.length / 2 + 1);
    //   }
    // },
    // adddend: function () {
    //   // return this.param.slice(this.param.length / 2 + 1, this.param.length);
    //   // return this.param.slice(16, 30);
    //   if (this.param.length % 2 == 0) {
    //     return this.param.slice(this.param.length / 2, this.param.length);
    //   } else {
    //     return this.param.slice(this.param.length / 2 + 1, this.param.length);
    //   }
    // },
  },
  mounted() {
    console.log(this.param);
  },
};
</script>
<style lang="less" scoped>
.certication {
  width: 1200px;
  display: flex;
  margin: 0 auto;
  margin-bottom: 47px;
  flex-direction: column;
  .TechnicalParameters {
    font-size: 18px;
    font-family: Arial;
    font-weight: bold;
    margin-top: 40px;
    color: #000000;
  }
  .volltage {
    margin-top: 40px;
    display: flex;
    .fontleft {
      width: 100%;
      display: flex;
      border-top: 1px solid #f2f2f2;
      flex-direction: column;
      .Certification {
        font-size: 14px;
        font-family: Arial;
        font-weight: 400;
        color: #606060;
        line-height: 80px;
        height: 60px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #f2f2f2;
        justify-content: space-between;
      }
    }
    .fontright {
      width: 48%;
      display: flex;
      border-top: 1px solid #f2f2f2;
      flex-direction: column;
      margin-left: 20px;
      .Certification {
        font-size: 14px;
        font-family: Arial;
        font-weight: 400;
        color: #606060;
        line-height: 80px;
        height: 60px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #f2f2f2;
        justify-content: space-between;
      }
    }
  }
  .fhashi {
    margin-top: 30px;
    width: 100%;
  }
}
.rightcontent {
  margin-right: 20px;
}
</style>