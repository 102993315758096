<template>
  <div class="joinbig">
    <div class="jonin">
      <div class="wantsome wow animate fadeInUp delay-100ms">
        {{ $t("login.wantfaku") }}
      </div>
      <div class="maketoyou wow animate fadeInUp delay-200ms">
        {{ $t("login.wantfakuson") }}
      </div>
      <div class="manyinput wow animate fadeInUp delay-500ms">
        <!-- <input type="text" :placeholder="placeholder1" v-model="comname" /> -->
        <!-- <input type="text" :placeholder="placeholder2" v-model="timeinc" /> -->
        <!-- <input type="text" :placeholder="placeholder3" v-model="comadd" /> -->

        <input
          type="text"
          :placeholder="feek1"
          v-model="telfax"
          @blur="losetbuer1"
          :class="mobooilep1 ? '' : 'fackending'"
        />
        <input
          type="text"
          :placeholder="feek2"
          @blur="losetbuer2"
          v-model="mobilep"
          :class="mobooilep2 ? '' : 'fackending'"
        />
        <input
          type="text"
          :placeholder="feek3"
          v-model="websi"
          @blur="losetbuer3"
          :class="mobooilep3 ? '' : 'fackending'"
        />
        <input
          type="text"
          :placeholder="feek4"
          v-model="numemp"
          @blur="losetbuer4"
          :class="mobooilep4 ? '' : 'fackending'"
        />
      </div>
      <div class="secondinput wow animate fadeInUp delay-500ms">
        <textarea
          name=""
          id=""
          cols="30"
          rows="10"
          :placeholder="feek5"
          v-model="fault"
          :class="mobooilep5 ? '' : 'fackending'"
          @blur="losetbuer5"
        ></textarea>
      </div>
      <div
        class="tosub wow animate fadeInUp delay-700ms"
        @click.stop="suppbbten"
      >
        {{ $t("login.tofaku") }}
      </div>
    </div>
  </div>
</template>
<script>
import { WOW } from "wowjs";
import { joinus, advice } from "../request/api";
export default {
  data() {
    return {
      comname: "",
      timeinc: "",
      comadd: "",
      telfax: "",
      mobilep: "",
      websi: "",
      numemp: "",
      fault: "",
      mobooilep1: true,
      mobooilep2: true,
      mobooilep3: true,
      mobooilep4: true,
      mobooilep5: true,
    };
  },
  created() {
    this.$route.meta.title = this.$t("login.querythere9");
    this.$router.replace({
      query: {
        temp: Date.now(), //必有
      },
    });

    this.$bus.$on("add", (message, b, d) => {
      this.$route.meta.title = this.$t("login.querythere9");
      this.$router.replace({
        query: {
          temp: Date.now(), //必有
        },
      });
    });
  },
  mounted() {
    if (document.documentElement.scrollTop > 0) {
      document.documentElement.scrollTop = 0;
    }
    // document.documentElement.scrollTop = 0;
    // window.addEventListener("scroll", '0');
    this.$nextTick(() => {
      // 在dom渲染完后,再执行动画
      const wow = new WOW({
        offset: 0,
        // mobile: true,
        live: true,
      });
      wow.init();
    });
  },
  methods: {
    subbten() {
      console.log(1111);
      if (this.comname == "") {
        this.$message({
          message: "Please enter Company Name",
          type: "error",
          offset: 90,
        });
      } else if (this.timeinc == "") {
        this.$message({
          message: "Please enter Time of Incorporation",
          type: "error",
          offset: 90,
        });
      } else if (this.comadd == "") {
        this.$message({
          message: "Please enter Company address",
          type: "error",
          offset: 90,
        });
      } else if (this.telfax == "") {
        this.$message({
          message: "Please enter Telephone, fax",
          type: "error",
          offset: 90,
        });
      } else if (this.mobilep == "") {
        this.$message({
          message: "Please enter Mobile phone",
          type: "error",
          offset: 90,
        });
      } else if (this.websi == "") {
        this.$message({
          message: "Please enter Web site",
          type: "error",
          offset: 90,
        });
      } else if (this.numemp == "") {
        this.$message({
          message: "Please enter Number of employees",
          type: "error",
          offset: 90,
        });
      } else if (this.fault == "") {
        this.$message({
          message: "Please enter Fault description",
          type: "error",
          offset: 90,
        });
      } else {
        this.$message({
          message: "Submitted successfully",
          type: "success",
          offset: 90,
        });
        advice({
          lang: "en",
          country: "au",
          company: this.comname,
          founding_date: this.timeinc,
          address: this.comadd,
          fax: this.telfax,
          phone: this.mobilep,
          website: this.websi,
          staff_number: this.numemp,
          main_business: this.fault,
        }).then((res) => {
          console.log(res);
          this.comname = "";
          this.timeinc = "";
          this.comadd = "";
          this.telfax = "";
          this.mobilep = "";
          this.websi = "";
          this.numemp = "";
          this.fault = "";
        });
      }
    },
    losetbuer1() {
      this.mobooilep1 = true;
    },
    losetbuer2() {
      this.mobooilep2 = true;
    },
    losetbuer3() {
      this.mobooilep3 = true;
    },
    losetbuer4() {
      this.mobooilep4 = true;
    },
    losetbuer5() {
      this.mobooilep5 = true;
    },
    suppbbten() {
      if (this.telfax == "") {
        this.mobooilep1 = false;
        this.$message({
          message: this.$t("login.borderred"),
          type: "error",
          offset: 90,
        });
      } else if (this.mobilep == "") {
        this.mobooilep2 = false;
        this.$message({
          message: this.$t("login.borderred"),
          type: "error",
          offset: 90,
        });
      } else if (this.websi == "") {
        this.mobooilep3 = false;
        this.$message({
          message: this.$t("login.borderred"),
          type: "error",
          offset: 90,
        });
      } else if (this.numemp == "") {
        this.mobooilep4 = false;
        this.$message({
          message: this.$t("login.borderred"),
          type: "error",
          offset: 90,
        });
      } else if (this.fault == "") {
        this.mobooilep5 = false;
        this.$message({
          message: this.$t("login.borderred"),
          type: "error",
          offset: 90,
        });
      } else {
        if (sessionStorage.getItem("newlangusge") == null) {
          this.outgie("en");
        } else {
          this.outgie(sessionStorage.getItem("newlangusge"));
        }
      }
    },
    outgie(a) {
      advice({
        lang: a,
        country: "au",
        name: this.telfax,
        countries: this.mobilep,
        city: this.websi,
        phone: this.numemp,
        describe: this.fault,
      }).then((res) => {
        console.log(res);
        this.$message({
          message: this.$t("login.sucssfil"),
          type: "success",
          offset: 90,
        });
        this.telfax = "";
        this.mobilep = "";
        this.websi = "";
        this.numemp = "";
        this.fault = "";
      });
    },
  },
  computed: {
    feek1() {
      return this.$t("login.feedback1");
    },
    feek2() {
      return this.$t("login.feedback2");
    },
    feek3() {
      return this.$t("login.feedback3");
    },
    feek4() {
      return this.$t("login.feedback4");
    },
    feek5() {
      return this.$t("login.feedback5");
    },
  },
};
</script>
<style lang="less" scoped>
.joinbig {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .jonin {
    display: flex;
    width: 900px;
    margin: 0 auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 60px 0 120px 0;
    .wantsome {
      font-size: 36px;
      font-family: Arial Black;
      font-weight: 900;
      color: #000000;
      margin-top: 43px;
      text-transform: uppercase;
    }
    .maketoyou {
      margin-top: 14px;
      font-size: 14px;
      font-family: Arial;
      // font-weight: bold;
      color: #000000;
    }
    .manyinput {
      margin-top: 80px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      input {
        outline: none;
        border: none;
        width: 435px;
        height: 42px;
        font-size: 14px;
        background: #f7f7f7;
        border-radius: 5px;
        margin-bottom: 25px;
        text-indent: 15px;
      }
    }
    .secondinput {
      margin-top: 1px;
      textarea {
        outline: none;
        width: 900px;
        height: 140px;
        background: #f7f7f7;
        border-radius: 5px;
        border: none;
        resize: none;
        text-indent: 15px;
        padding-top: 18px;
      }
    }

    .tosub {
      font-size: 12px;
      // font-family: Arial;
      font-weight: bold;
      color: #ffffff;
      width: 180px;
      height: 48px;
      background: #000000;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      margin-top: 38px;
      margin-bottom: 60px;
      cursor: pointer;
    }
    .tosub:hover {
      // border: 1px solid #9e1d22;
      background-size: 14px 10px;
      background-color: #9e1d22;
    }
  }
}
input::placeholder,
textarea::placeholder {
  color: #ccc;
}
.fackending {
  border: 1px solid red !important;
}
</style>